import axios from 'axios'
import decode from 'jwt-decode'
import store from './../store/index.js'
const ID_TOKEN_KEY = 'id_token'
const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY'

export function requireAuth(to, from, next) {
  var getToken = window.location.search.substr(1)
  if (getToken) {
    localStorage.setItem('ACCESS_TOKEN_KEY', getToken.substring(6))
  }
  if (localStorage.getItem('ACCESS_TOKEN_KEY')) {
    if (!isTokenExpired(localStorage.getItem('ACCESS_TOKEN_KEY'))) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('ACCESS_TOKEN_KEY')
      let query = {
        query: `{
        logged_user{
          id
          email
          status
          is_admin
        }
      }` }
      axios.post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              console.log(response.data.errors[property].field + ': ' + response.data.errors[property].message)
              alert('Contact the administrator')
            }
          } else {
            store.dispatch('setUserEmail', response.data.data.logged_user.email)
            store.dispatch('setUserId', response.data.data.logged_user.id)
            store.dispatch('setUserIsAdmin', response.data.data.logged_user.is_admin)
            store.dispatch('setUserRole', response.data.data.logged_user.status)
            next()
          }
        })
        .catch(function (error) {
          console.log(error)
          next({ path: '/' })
          // self.$refs.toastr.e('Contact the administrator ' + error)
        })
    } else {
      localStorage.removeItem('ACCESS_TOKEN_KEY')
      console.log('token expired')
      store.dispatch('setUserEmail', '')
      store.dispatch('setUserId', '')
      store.dispatch('setUserIsAdmin', '')
      store.dispatch('setUserRole', '')
      // next({ path: '/login' })
      next({ path: '/' })
    }
  } else {
    console.log('no token')
    store.dispatch('setUserEmail', '')
    store.dispatch('setUserId', '')
    store.dispatch('setUserIsAdmin', '')
    store.dispatch('setUserRole', '')
    // next({ path: '/login' })
    next({ path: '/' })
  }
}
export function requireAuthAdmin(to, from, next) {
  var getToken = window.location.search.substr(1)
  if (getToken) {
    localStorage.setItem('ACCESS_TOKEN_KEY', getToken.substring(6))
  }
  if (localStorage.getItem('ACCESS_TOKEN_KEY')) {
    if (!isTokenExpired(localStorage.getItem('ACCESS_TOKEN_KEY'))) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('ACCESS_TOKEN_KEY')
      let query = {
        query: `{
        logged_user{
          id
          email
          status
          is_admin
        }
      }` }
      axios.post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              console.log(response.data.errors[property].field + ': ' + response.data.errors[property].message)
              alert('Contact the administrator')
            }
          } else {
            store.dispatch('setUserEmail', response.data.data.logged_user.email)
            store.dispatch('setUserId', response.data.data.logged_user.id)
            store.dispatch('setUserIsAdmin', response.data.data.logged_user.is_admin)
            store.dispatch('setUserRole', response.data.data.logged_user.status)
            if (response.data.data.logged_user.is_admin) {
              next()
            } else {

              next({ path: '/advertisements' })
            }
          }
        })
        .catch(function (error) {
          console.log(error)
          next({ path: '/' })
          // self.$refs.toastr.e('Contact the administrator ' + error)
        })
    } else {
      localStorage.removeItem('ACCESS_TOKEN_KEY')
      console.log('token expired')
      store.dispatch('setUserEmail', '')
      store.dispatch('setUserId', '')
      store.dispatch('setUserIsAdmin', '')
      store.dispatch('setUserRole', '')
      // next({ path: '/login' })
      next({ path: '/' })
    }
  } else {
    console.log('no token')
    store.dispatch('setUserEmail', '')
    store.dispatch('setUserId', '')
    store.dispatch('setUserIsAdmin', '')
    store.dispatch('setUserRole', '')
    // next({ path: '/login' })
    next({ path: '/' })
  }
}
export function getIdToken() {
  return localStorage.getItem(ID_TOKEN_KEY)
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

// Helper function that will allow us to extract the access_token and id_token
function getParameterByName(name) {
  let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

// Get and store access_token in local storage
export function setAccessToken() {
  let accessToken = getParameterByName('ACCESS_TOKEN_KEY')
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
}

// Get and store id_token in local storage
export function setIdToken() {
  let idToken = getParameterByName('id_token')
  localStorage.setItem(ID_TOKEN_KEY, idToken)
}

export function isLoggedIn() {
  const idToken = getIdToken()
  return !!idToken && !isTokenExpired(idToken)
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken)
  if (!token.exp) { return null }

  const date = new Date(0)
  date.setUTCSeconds(token.exp)

  return date
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token)
  return expirationDate < new Date()
}
