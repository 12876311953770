var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'mediaLibrary ' + (_vm.isFont ? 'mediaLibrary__font' : '')},[_c('div',[_c('div',{staticClass:"mediaLibraryHeader"},[_c('div',{staticClass:"drophere"},[_c('b-field',[_c('b-upload',{staticClass:"drag-files",attrs:{"multiple":"","drag-drop":"","accept":_vm.isFont ? '.ttf' : '.jpg, .jpeg, gif, .bmp, .png, .mp4 '},on:{"input":_vm.addImg},model:{value:(_vm.dropFiles),callback:function ($$v) {_vm.dropFiles=$$v},expression:"dropFiles"}},[_c('div',{staticClass:"has-text-centered"},[_c('p',[_vm._v("Drag files here or click to add")])])])],1),_c('div',{staticClass:"tags"},_vm._l((_vm.dropFiles),function(file,index){return _c('div',{key:index,staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(file.name)+" "),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-trash cp",on:{"click":function($event){return _vm.deleteDropFile(index)}}})])])}),0)],1),_c('button',{staticClass:"mediaButton",on:{"click":function($event){return _vm.closeUploader()}}},[_vm._v("x")])]),_c('div',{staticClass:"progress-container"},[(_vm.showProgress)?_c('b-progress',{attrs:{"value":_vm.progressValue}}):_vm._e()],1),_c('div',{class:' ' + (_vm.isFont ? 'fontsContainer' : 'imgsContainer')},_vm._l((_vm.files),function(file){return _c('div',{key:file.id},[_c('div',{class:' ' + (_vm.isFont ? '' : 'imgContainer')},[(_vm.checkFileType(file.filename) === 'thisIsImg')?_c('img',{attrs:{"src":_vm.backendUrl + file.path + '140_92/' + file.filename,"alt":file.name}}):_vm._e(),(_vm.checkFileType(file.filename) === 'thisIsVid')?_c('video',{attrs:{"id":file.id + 'vid'}},[_c('source',{attrs:{"src":_vm.backendUrl + file.path + file.filename,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]):_vm._e(),(_vm.isFont)?_c('p',{staticClass:"cp",style:('font-family:' + file.original_filename.replace('.ttf', '').replace('.TTF', '')),on:{"click":function($event){_vm.chooseImg(
                '/' + file.path + file.filename,
                _vm.checkFileType(file.filename),
                file.id,
                file.original_filename
              )}}},[(file.is_added_by_admin === _vm.is_admin || !file.is_added_by_admin)?_c('i',{staticClass:"fa fa-plus"}):_vm._e(),_vm._v(" "+_vm._s(file.original_filename)+" ")]):_vm._e(),(!_vm.isFont)?_c('div',{staticClass:"fa fa-plus addThisImage",on:{"click":function($event){_vm.chooseImg(
                '/' + file.path + file.filename,
                _vm.checkFileType(file.filename),
                file.id,
                file.original_filename
              )}}}):_vm._e(),_c('div',[(file.is_added_by_admin === _vm.is_admin || !file.is_added_by_admin)?_c('i',{staticClass:"fa fa-times",on:{"click":function($event){return _vm.deleteImage(file.id)}}}):_vm._e()])]),(!_vm.isFont)?_c('p',[_vm._v(_vm._s(file.original_filename))]):_vm._e(),(_vm.checkFileType(file.filename) === 'thisIsImg')?_c('p',[_vm._v(" "+_vm._s(file.width)+" x "+_vm._s(file.height)+" ")]):_vm._e()])}),0),(_vm.filesPagination.total > _vm.filesPagination.limit)?_c('b-pagination',{attrs:{"total":_vm.filesPagination.total,"current":_vm.filesPagination.page,"simple":false,"rounded":false,"icon-pack":"fa","per-page":_vm.filesPagination.limit},on:{"change":_vm.changeFilesPage,"update:current":function($event){return _vm.$set(_vm.filesPagination, "page", $event)}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }