import moment from "moment-timezone";

var move = {
  methods: {
    initmovement(e) {
      var target = this.$store.state.activeElement

      this.$store.commit('initmove', {
        startX: e.pageX,
        startY: e.pageY,
        originX: target.left,
        originY: target.top
      })

      document.addEventListener('mousemove', this.handlemousemove, true)
      document.addEventListener('mouseup', this.handlemouseup, true)
    },

    handlemousemove(e) {
      e.stopPropagation()
      e.preventDefault()

      this.$store.commit('move', {
        x: e.pageX,
        y: e.pageY
      })
    },

    handlemouseup() {
      document.removeEventListener('mousemove', this.handlemousemove, true)
      document.removeEventListener('mouseup', this.handlemouseup, true)
      this.$store.commit('stopmove')
    }
  }
}
var widgetsMethods = {
  methods: {
    hexToRgb(hex) {
      var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      });

      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
  }
}
var device = {
  methods: {
    isDeviceActive(dateTocheck) {
      if (!dateTocheck) {
        return false;
      }
      let currentDate = moment();
      let datei18n = moment.utc(dateTocheck, "DD-MM-YYYY HH:mm", "UTC");
      let lastHB = datei18n.tz("Europe/Warsaw");

      return lastHB.diff(currentDate, "minutes") >= -10 ? true : false;
    },
    updateTime(value, device, target) {
      let momentValue = value;
      if (!momentValue) {
        momentValue = moment("00:00:00", "HH:mm:ss");
      }
      device[target] = moment(momentValue).format("HH:mm:ss");
    },
  }
}
var users = {
  methods: {
    getUserStatusName(status) {
      return {
        NORMAL: 0,
        INACTIVE: 5,
        ADMIN: 10,
        SUPERUSER: 15,

      }[status] || console.error(`Unknown"${status}".`)
    },

  }
}
var dates = {
  methods: {
    geti18nDate(date) {
      if (date) {
        let datei18n = moment.utc(date, "DD-MM-YYYY HH:mm", "UTC");
        return datei18n.tz("Europe/Warsaw").format("DD-MM-YYYY HH:mm");
      } else {
        return "";
      }
    },
    i18ndate(date) {
      return date ? moment
        .utc(date, "DD.MM.YYYY HH:mm")
        .local()
        .format("DD.MM.YYYY HH:mm") : '-';
    },
    createTime(time) {
      return moment(time, "HH:mm:ss").toDate();
    },
    createDate(date) {
      if (date && date !== "00.00.0000") {
        return moment(date, "DD.MM.YYYY").toDate();
      } else {
        date = moment().toDate();
        return moment().toDate();
      }
    },
    cutYear(date) {
      return moment(date, "DD.MM.YYYY").format("DD.MM");
    },
    showSecond(slideDuration) {
      // console.log(slideDuration)
      return moment(slideDuration, "HH:mm:ss").diff(moment().startOf("day"), "seconds");
    },
  }
}
var helper = {
  methods: {
    trimThreeCharacters(toTrim) {
      return toTrim.substring(0, toTrim.length - 3);
    },

  }
}
var variables = {
  methods: {
    getTransitionPath(transition) {
      return {
        fade: '/static/img/transitions/1.png',
        slideleft: '/static/img/transitions/3.png',
        slideright: '/static/img/transitions/2.png',
        slideup: '/static/img/transitions/4.png',
        slidedown: '/static/img/transitions/5.png',
      }[transition] || console.error(`Unknown "${transition}".`)
  },
}}
var advertisements = {
  methods: {
    getSlideColor(dateFrom, dateTo, isDates) {
      const currentDate = moment();
      const dateFromMoment = moment(dateFrom, "DD.MM.YYYY");
      const dateToMoment = moment(dateTo, "DD.MM.YYYY");
      if (!isDates) {
        return "activeSlide";
      } else if (currentDate > dateToMoment) {
        return "beforeSlide";
      } else if (currentDate < dateFromMoment) {
        return "futureSlide";
      } else {
        return "activeSlide";
      }
    },
    getValidSubscription(subscriptions) {
      for (let i = 0; i < subscriptions.length; i++) {
        if (
          subscriptions[i].status === "FREE" ||
          (subscriptions[i].status === "OK" &&
            moment.utc() < moment.utc(subscriptions[i].valid_to, "DD-MM-YYYY HH:mm"))
        ) {
          return i;
        }
      }
      return false;
    },
    checkAdForLogo(slidersArray) {
      return slidersArray.findIndex((x) => x.is_logo);
    },
    checkAdForUnder(slidersArray) {
      return slidersArray.findIndex((x) => x.is_under);
    },
    updateDate(value, slide, target) {
      slide[target] = moment(value).format("DD.MM.YYYY");
    },

  }
}
export { move, device, widgetsMethods, dates, users, helper, advertisements, variables}
