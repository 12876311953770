var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.elm),expression:"elm"}]},[_c('div',{staticClass:"deleteThis",style:({
      top: _vm.elm.top + _vm.elm.height - 10 + 'px',
      left: _vm.elm.left + 80 + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'delete')}}},[_c('i',{staticClass:"fa fa-trash"})]),_c('div',{staticClass:"cloneThis",style:({
      top: _vm.elm.top + _vm.elm.height - 10 + 'px',
      left: _vm.elm.left + 45 + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'clone')}}},[_c('i',{staticClass:"fa fa-clone"})]),_c('div',{staticClass:"cloneThis",style:({
      top: _vm.elm.top + _vm.elm.height - 10 + 'px',
      left: _vm.elm.left + 10 + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'leveldown')}}},[_c('i',{staticClass:"fa fa-level-down"})]),_c('div',{staticClass:"cloneThis",style:({
      top: _vm.elm.top + _vm.elm.height - 10 + 'px',
      left: _vm.elm.left - 25 + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'levelup')}}},[_c('i',{staticClass:"fa fa-level-up"})]),_c('div',{staticClass:"verti",style:({
      height: _vm.elm.height + 'px',
      top: _vm.elm.top + 'px',
      left: _vm.elm.left + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'left', 'left', 'width')}}},[_c('div',{staticClass:"square first"})]),_c('div',{staticClass:"verti",style:({
      height: _vm.elm.height + 'px',
      top: _vm.elm.top + 'px',
      left: _vm.elm.left + _vm.elm.width + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'right', 'width')}}},[_c('div',{staticClass:"square second"})]),_c('div',{staticClass:"horiz",style:({
      width: _vm.elm.width + 'px',
      top: _vm.elm.top + 'px',
      left: _vm.elm.left + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'up', 'top', 'height')}}},[_c('div',{staticClass:"square"})]),_c('div',{staticClass:"horiz",style:({
      width: _vm.elm.width + 'px',
      top: _vm.elm.top + _vm.elm.height + 'px',
      left: _vm.elm.left + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'down', 'height')}}},[_c('div',{staticClass:"square"})]),_c('div',{staticClass:"verti cornerRight",style:({
      width: _vm.elm.width + 'px',
      top: _vm.elm.top + _vm.elm.height + 'px',
      left: _vm.elm.left + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'cornerBottom', 'width', 'height')}}},[_c('div',{staticClass:"square"})]),_c('div',{staticClass:"verti cornerLeft",style:({
      width: _vm.elm.width + 'px',
      top: _vm.elm.top + 'px',
      left: _vm.elm.left + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'cornerLeft', 'left', 'width', 'top', 'height')}}},[_c('div',{staticClass:"square"})]),_c('div',{staticClass:"verti cornerTop",style:({
      width: _vm.elm.width + 'px',
      top: _vm.elm.top + 'px',
      left: _vm.elm.left + 'px',
    }),on:{"mousedown":function($event){return _vm.handlemousedown($event, 'cornerTop', 'width', 'top', 'height')}}},[_c('div',{staticClass:"square"})])])}
var staticRenderFns = []

export { render, staticRenderFns }