<template>
  <section>
    <div class="table__payments">
      <b-table :data="devices">
        <template slot-scope="props">
          <b-table-column field="id" label="ID">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column field="status" label="Status">
            {{ props.row.status }}
          </b-table-column>
          <b-table-column field="created_at" label="Created at">
            {{ props.row.created_at ? i18ndate(props.row.created_at) : "-" }}
          </b-table-column>
          <b-table-column field="modified_at" label="Modified at">
            {{ props.row.modified_at ? i18ndate(props.row.modified_at) : "-" }}
          </b-table-column>
          <b-table-column field="user.email" label="User">{{
            props.row.user.email
          }}</b-table-column>
          <b-table-column field="amount" label="Amount [$]">{{ props.row.amount }}</b-table-column>
          <b-table-column field="months" label="Months">{{ props.row.months }}</b-table-column>
        </template>
      </b-table>

      <b-pagination
        v-if="commercialPagination.total > commercialPagination.perPage"
        @change="changeCommercialPage"
        :total="commercialPagination.total"
        :current.sync="commercialPagination.current"
        :order="commercialPagination.order"
        :size="commercialPagination.size"
        :simple="commercialPagination.isSimple"
        :per-page="commercialPagination.perPage"
      ></b-pagination>
      <br />
      <br />
    </div>
  </section>
</template>
<script>
import axios from "axios";
import Toastr from "vue-toastr";
import { dates } from "@/mixins";

export default {
  components: {
    "vue-toastr": Toastr
  },
  mixins: [dates],
  data() {
    return {
      showModal: false,
      userToEdit: null,
      newPass: null,
      devices: [],
      backendUrl: window.backendUrl,
      error: "",
      commercialPagination: {
        total: 0,
        current: 1,
        perPage: 20,
        order: "",
        size: "",
        isSimple: false
      },
      selectedDevices: new Map(),
      monthsLength: 1,
      setTracker: 0
    };
  },
  computed: {
    calcSum() {
      return this.setTracker && this.selectedDevices.size * 5 * this.monthsLength; //device monthly cost
    },
    devicesSum() {
      return this.setTracker && this.selectedDevices.size;
    }
  },
  mounted() {
    this.getdevices();
  },
  methods: {
    proceed() {
      if (this.devicesSum < 1) {
        this.$toastr.e("Select devices.");
      } else {
        const arr = [...this.selectedDevices].map(([name, value]) => ({ name, value }));
        this.$router.push({
          name: "paymentSummary",
          params: { months: this.monthsLength, devices: arr }
        });
      }
    },
    changeCommercialPage() {
      var self = this;
      self.$nextTick(() => self.getdevices());
    },
    getdevices() {
      var self = this;
      let thisOffset = Math.max(
        0,
        self.commercialPagination.perPage * (self.commercialPagination.current - 1)
      );
      let query = {
        query: `{
        payment_transactions(limit: ${self.commercialPagination.perPage}, offset: ${thisOffset}){
          items{
              id
              status
              amount
              months
              created_at
              modified_at
              user{email}
              #commercial{display_name}
              #subscriptions{
              #  status
              #  valid_to
              #}
              
           
          }
          paging{
            total
            limit
            has_next
            has_prev
          }
        }
      }`
      };

      axios
        .post(global.backendUrl, query)
        .then(function(response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else {
            self.devices = response.data.data.payment_transactions.items;
            self.commercialPagination.total = response.data.data.payment_transactions.paging.total;
          }
        })
        .catch(function(error) {
          self.$toastr.e("Contact the administrator " + error);
        });
    },
    selectDevice(device) {
      if (!this.selectedDevices.has(device.id)) {
        this.selectedDevices.set(device.id, device);
      } else {
        this.selectedDevices.delete(device.id);
      }
      this.setTracker += 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.mw400 {
  max-width: 400px;
}
.table__payments {
  // max-width: 600px;
  margin: 0 10px;
}
</style>
