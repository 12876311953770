<template>
  <div id="notFound">
    <div class="text-center col-sm-12">
      <h1>404</h1>
      <router-link to="/" class="vertical-5p lead">Main page</router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "NotFound"
};
</script>

<style>
#notFound {
  padding: 10em;
  color: #000;
}
</style>
