<template>
  <div class="holder" id="viewport">
    <div
      class="screen"
      id="screen"
      @dblclick="replaceImage"
      :style="{
        height: pageHeight + 'px',
        width: pageWidth + 'px',
        transform: 'scale(' + zoom / 100 + ', ' + zoom / 100 + ')',
      }"
    >
      <component
        :is="val.type"
        :data-title="val.type"
        class="layer"
        :class="{ 'g-active': id === val.uuid }"
        v-for="val in widgetStore"
        :key="val.uuid"
        :val="val"
        :h="height"
        :w="width"
        :data-type="val.type"
        :data-uuid="val.uuid"
        :playState="playState"
      >
        <component
          v-if="val.isContainer"
          :is="child.type"
          :data-title="child.type"
          class="layer layer-child"
          :class="{ 'g-active': id === child.uuid }"
          v-for="child in getChilds(val.name)"
          :key="child.uuid"
          :val="child"
          :h="height"
          :w="width"
          :data-type="child.type"
          :data-uuid="child.uuid"
          :playState="playState"
        />
      </component>
      <ref></ref>
      <control></control>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import widget from "./../../plugins/widget";
import ref from "./ref-lines.vue";
import control from "./size-control.vue";
import { move } from "../../mixins";

export default {
  name: "viewport",
  components: {
    ref: ref,
    control: control,
  },

  mixins: [move],

  props: ["zoom", "currentSliderIsLogo"],

  mounted() {
    var self = this;
    document.getElementById("viewport").addEventListener("mousedown", this.handleSelection, false);

    document.addEventListener(
      "keydown",
      (e) => {
        e.stopPropagation();
        var target = this.$store.state.activeElement;
        if (e.keyCode === 37) {
          e.stopPropagation();
          e.preventDefault();
          target.left -= 1;
          return;
        }
        if (e.keyCode === 38) {
          e.stopPropagation();
          e.preventDefault();
          target.top -= 1;
          return;
        }
        if (e.keyCode === 39) {
          e.stopPropagation();
          e.preventDefault();
          target.left += 1;
          return;
        }

        if (e.keyCode === 40) {
          e.stopPropagation();
          e.preventDefault();
          target.top += 1;
        }
      },
      true
    );
  },

  methods: {
    handleSelection(e) {
      var target = e.target;
      var type = target.getAttribute("data-type");
      if (type) {
        var uuid = target.getAttribute("data-uuid");

        this.$store.commit("select", {
          uuid: uuid || -1,
        });
        target = this.$store.state.activeElement;
        if (target.belong === "page" && target.dragable) {
          this.initmovement(e); //  mixins
        }
      } else {
        // // add background element
        if (!this.currentSliderIsLogo) {
          var item = this.widgets["admedi-bg"];
          this.$store.dispatch("addWidget", item);
        }
        // this.$store.commit("select", {
        //   uuid: -1
        // });
      }
    },

    replaceImage(e) {
      console.info("replaceImage");
      if (
        this.$store.state.activeElement.isUpload ||
        this.$store.state.activeElement.type === "admedi-bg"
      ) {
        this.$store.commit("doUploaderCategory", true);
        this.$store.commit("setUploaderType", ["Enum::IMAGE", "Enum::VIDEO"]);
        this.$store.commit("doUploader", true);
      }
    },

    getChilds(name) {
      return this.$store.state.widgets.filter((item) => item.belong === name);
    },
  },
  computed: {
    widgetStore() {
      return this.$store.state.widgets.filter((item) => item.belong === "page");
    },
    height() {
      return this.$store.state.page.height;
    },

    width() {
      return this.$store.state.page.width;
    },
    backgroundColor() {
      return this.$store.state.page.backgroundColor;
    },
    widgets() {
      return widget.getWidgetsAdmedi();
    },
    id() {
      // var type = this.$store.state.type
      // var index = this.$store.state.index
      // index = index >= 0 ? index : ''
      // return type + index
      return this.$store.state.uuid;
    },
    pageWidth() {
      return this.$store.state.pageWidth;
    },
    pageHeight() {
      return this.$store.state.pageHeight;
    },
    playState() {
      return this.$store.state.playState;
    },
  },
};
</script>

<style scoped>
.holder {
  display: flex;
  transform-origin: 0 0;
  justify-content: center;
  align-items: center;
  overflow: auto;
  font-size: 0;
  border-width: 0 1px;
  background: #222222;
}
.screen {
  background: #fff;
  background-image: linear-gradient(45deg, #f0f0f0 25%, transparent 0, transparent 75%, #f0f0f0 0),
    linear-gradient(45deg, #f0f0f0 25%, transparent 0, transparent 75%, #f0f0f0 0);
  background-position: 0 0, 13px 13px;
  background-size: 26px 26px;
  margin: 25px auto;
  transform-origin: center top;
}
</style>
