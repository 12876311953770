<template>
  <section>
    <div class="has-text-centered">
      <img src="/static/img/logo.png" alt="Image" class="pageLogo" />
      <div class>
        <div v-for="(singleAd, key) in ads" :key="key" class="m-b-10 p-b-10 m-t-10">
          <article>
            <div class>
              <div class="fz13">
                <div
                  :id="'ad_id_' + singleAd.id"
                  class="commercial"
                  v-bind:class="{ activeCommercial: singleAd.id === activeAdsSlidesId }"
                >
                  <div class="adsMaxWidth">
                    <div class="singleAdHeader">
                      <div
                        @click="activeAdsSlidesSet(singleAd.id, singleAd.display_name)"
                        v-if="singleAd.id !== activeAdsSlidesId"
                        class="icon is-medium pull-right icon__edit"
                      >
                        <i class="fa fa-lg fa-pencil" style="padding-right: 5px"></i> EDIT
                      </div>

                      <div
                        @click="closeSlidesSet(singleAd.id)"
                        v-if="singleAd.id === activeAdsSlidesId"
                        class="icon is-medium pull-right icon__long"
                      >
                        <i class="fa fa-lg fa-times"></i> &nbsp;CLOSE
                      </div>
                      <div
                        @click="saveSlidesSet(singleAd.id)"
                        v-if="singleAd.id === activeAdsSlidesId"
                        v-bind:class="{ 'is-primary': commercialSlidesEdited(singleAd) }"
                        class="icon is-medium pull-right icon__long"
                      >
                        <i class="fa fa-lg fa-save"></i> &nbsp;SAVE
                      </div>

                      <div
                        class="icon is-medium pull-right"
                        @click="deleteCommercial(singleAd.display_name, singleAd.id)"
                        v-if="!activeAdsSlidesId"
                      >
                        <i class="fa fa-lg fa fa-trash" title="Delete ad"></i>
                      </div>
                      <div
                        class="icon is-medium pull-right"
                        @click="duplicateAd(singleAd)"
                        v-if="!activeAdsSlidesId"
                      >
                        <i class="fa fa-lg fa fa-clone" title="Clone ad"></i>
                      </div>
                      <div class="icon is-medium pull-right" @click="doPreview(singleAd)">
                        <i
                          class="fa fa-lg fa fa-eye"
                          title="Preview the ad in its current state, without time conditions"
                        ></i>
                      </div>
                      <h2
                        @click="activeAdsSlidesSet(singleAd.id, singleAd.display_name)"
                        v-if="singleAd.id !== activeAdsSlidesId"
                        class="cp"
                      >
                        {{ singleAd.display_name }}
                      </h2>
                      <div class="display-name" v-if="singleAd.id === activeAdsSlidesId">
                        <input v-model="singleAd.display_name" class="display-name__input" />
                        <div
                          class="icon is-medium icon--no-border"
                          @click="saveAdDisplayName(singleAd.id, singleAd.display_name)"
                          v-if="currentAdName !== singleAd.display_name"
                        >
                          <i class="fa fa-lg fa fa-save" title="save display name"></i>
                        </div>
                      </div>

                      <div class="is-clearfix"></div>
                    </div>
                    <b-collapse :open="singleAd.id === activeAdsSlidesId">
                      <div class="ranges">
                        <div
                          v-for="(range, index) in searchForDatesRanges"
                          :key="index + 'range'"
                          @click="activeAdsRangeSet(range)"
                        >
                          <div
                            v-bind:class="{
                              activeRange:
                                activeAdsSlidesRange && range.text === activeAdsSlidesRange.text,
                            }"
                          >
                            {{ cutYear(range.date_from) }} - {{ cutYear(range.date_to) }}
                          </div>
                        </div>
                      </div>

                      <div v-if="singleAd.sliders">
                        <draggable v-model="singleAd.sliders" @change="changingOrder(key)">
                          <transition-group>
                            <div
                              v-if="
                                !slide.is_logo &&
                                !slide.is_under &&
                                currentRangeFilter(slide.date_from, slide.date_to, slide.is_dates)
                              "
                              class="singleSlide"
                              v-bind:class="
                                getSlideColor(slide.date_from, slide.date_to, slide.is_dates)
                              "
                              v-for="(slide, index) in singleAd.sliders"
                              :key="index + 's'"
                              :data-order-number="slide.order_number"
                              :data-slide-id="slide.id"
                            >
                              <div
                                @click="openDesignerComponent(singleAd, slide, index)"
                                v-if="slide.content_json !== 'youtube'"
                                class="cp h100p"
                              >
                                <img
                                  v-if="slide.filename && !slide.toDataURLImage"
                                  :src="backendUrl + 'files' + slide.path + slide.filename"
                                  class="thumbnailImg cp"
                                  :id="'sliderImg' + slide.id"
                                />
                                <img
                                  v-if="slide.toDataURLImage"
                                  :src="slide.toDataURLImage"
                                  class="thumbnailImg cp"
                                />
                                <div v-if="!slide.toDataURLImage && !slide.filename" class="h100p">
                                  <img
                                    :src="
                                      '/static/img/' + (singleAd.is_portrait ? 'v' : 'h') + '.png'
                                    "
                                    class="fa fa-image fz50 cp thumbnailImg"
                                    title="Save the slide in the editor to get a thumbnail"
                                    :id="'sliderImg' + slide.id"
                                  />
                                </div>
                              </div>
                              <div class="durationControl">
                                <i
                                  class="fa fa-angle-up cp arrows"
                                  @click="slideDuration(slide, 1)"
                                  @dblclick="slideDuration(slide, 10)"
                                ></i>
                                <p>{{ showSecond(slide.duration) }}s</p>
                                <i
                                  class="fa fa-angle-down cp arrows"
                                  @click="slideDuration(slide, -1)"
                                  @dblclick="slideDuration(slide, -10)"
                                ></i>
                              </div>
                              <input v-model="slide.duration" type="hidden" />
                              <b-dropdown
                                v-model="slide.transition"
                                aria-role="list"
                                @change="setSlideAsOpened(slide)"
                              >
                                <button type="button" class="singleSlide__button" slot="trigger">
                                  <template>
                                    <img :src="getTransitionPath(slide.transition)" />
                                  </template>
                                </button>

                                <b-dropdown-item :value="'fade'" aria-role="listitem">
                                  <div class="media">
                                    <img src="/static/img/transitions/1.png" /> fade
                                  </div>
                                </b-dropdown-item>

                                <b-dropdown-item :value="'slideright'" aria-role="listitem">
                                  <div class="media">
                                    <img src="/static/img/transitions/2.png" /> slide right
                                  </div>
                                </b-dropdown-item>

                                <b-dropdown-item :value="'slideleft'" aria-role="listitem">
                                  <div class="media">
                                    <img src="/static/img/transitions/3.png" /> slide left
                                  </div>
                                </b-dropdown-item>

                                <b-dropdown-item :value="'slideup'" aria-role="listitem">
                                  <div class="media">
                                    <img src="/static/img/transitions/4.png" /> slide up
                                  </div>
                                </b-dropdown-item>

                                <b-dropdown-item :value="'slidedown'" aria-role="listitem">
                                  <div class="media">
                                    <img src="/static/img/transitions/5.png" /> slide down
                                  </div>
                                </b-dropdown-item>
                              </b-dropdown>
                              <div class="datesDiv">
                                <div v-if="slide.is_dates">{{ slide.date_from }}</div>
                              </div>
                              <div class="datesDiv">
                                <div v-if="slide.is_dates">{{ slide.date_to }}</div>
                              </div>

                              <i
                                class="fa fa-calendar cp"
                                v-bind:class="{ active: slide.is_dates }"
                                @click="addCalendarModalOpen(slide)"
                              ></i>
                              <i
                                class="fa fa-refresh cp"
                                v-bind:class="{ active: slide.is_period }"
                                @click="addScheduleModalOpen(slide)"
                              ></i>
                              <i
                                class="fa fa-clone cp"
                                @click="
                                  newSlide(false, singleAd.id, singleAd.is_portrait, false, slide)
                                "
                              ></i>

                              <i
                                class="fa fa-trash cp"
                                @click="deleteSlide(singleAd.id, slide.id)"
                              ></i>
                            </div>
                          </transition-group>
                        </draggable>

                        <div class="buttonsContainerSlide">
                          <div
                            class="newSlide"
                            @click="openAddSlide(singleAd.id, singleAd.is_portrait)"
                          >
                            + NEW SLIDE
                          </div>
                        </div>
                        <div class="commercial__buttons">
                          <div
                            v-if="checkAdForLogo(singleAd.sliders) !== -1"
                            class="foreground"
                            @click="
                              openDesignerComponent(
                                singleAd,
                                singleAd.sliders[checkAdForLogo(singleAd.sliders)],
                                checkAdForLogo(singleAd.sliders)
                              )
                            "
                          >
                            Foreground (Logo)
                          </div>
                          <div
                            v-else
                            class="foreground"
                            @click="newSlide(true, singleAd.id, singleAd.is_portrait)"
                          >
                            Foreground (Logo)
                          </div>
                          <div
                            v-if="checkAdForUnder(singleAd.sliders) !== -1"
                            class="foreground"
                            @click="
                              openDesignerComponent(
                                singleAd,
                                singleAd.sliders[checkAdForUnder(singleAd.sliders)],
                                checkAdForLogo(singleAd.sliders)
                              )
                            "
                          >
                            Background
                          </div>
                          <div
                            v-else
                            class="foreground"
                            @click="newSlide(false, singleAd.id, singleAd.is_portrait, true)"
                          >
                            Background
                          </div>
                          <div class="foreground" @click="openAddMusic(singleAd.id)">
                            <i class="fa fa-music"></i> &nbsp;Music
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
                <div v-if="singleAd.devices.items" class="adsMaxWidth">
                  <div
                    v-for="device in singleAd.devices.items"
                    class="singleAdContainer"
                    :key="device.id"
                  >
                    <div class="deviceContainer">
                      <div
                        class="is--on"
                        title="The last heartbeat from the device was in the last 10 minutes"
                        v-if="isDeviceActive(device.last_heartbeat_at)"
                      ></div>
                      <div
                        class="is--off"
                        v-else
                        title="There has been no heartbeat from the device for the last 10 minutes"
                      ></div>
                      <span
                        class="icon is-small"
                        v-if="
                          !(
                            parseInt(singleAd.id) ===
                              parseInt(device.last_heartbeat_commercial_id) &&
                            parseInt(singleAd.version_nr) ===
                              parseInt(device.last_heartbeat_version_nr)
                          )
                        "
                      >
                        <i
                          class="fa fa-exclamation-circle color-red"
                          title="The feedback from the device about the advertisement does not coincide with the current advertisement."
                        ></i>
                      </span>
                      <span
                        class="icon is-small"
                        v-if="
                          parseInt(singleAd.id) === parseInt(device.last_heartbeat_commercial_id) &&
                          parseInt(singleAd.version_nr) ===
                            parseInt(device.last_heartbeat_version_nr)
                        "
                      >
                        <i
                          class="fa fa-check"
                          title="
The ad and its current version on the device."
                        ></i>
                      </span>
                      <span
                        @click="
                          currentDeviceToEdit = device;
                          openEditDeviceModal();
                        "
                        class="cp deviceDisplayName"
                        >{{ device.display_name }}</span
                      >
                    </div>
                    <div>
                      <div class="dib" v-if="device.is_standby">
                        <span class="grey fz13">Mon.-Fri.</span>
                        {{ trimThreeCharacters(device.weekday_live_from) }} -
                        {{ trimThreeCharacters(device.weekday_live_to) }}
                        <span class="grey fz13 ml5">Sat.</span>
                        {{ trimThreeCharacters(device.saturday_live_from) }} -
                        {{ trimThreeCharacters(device.saturday_live_to) }}
                        <span class="grey fz13 ml5">Sun.</span>
                        {{ trimThreeCharacters(device.sunday_live_from) }} -
                        {{ trimThreeCharacters(device.sunday_live_to) }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="singleAd.devices.items.length === 0"
                    class="singleAdContainer color-black font-weight-200"
                  >
                    NO PLAYER ASSIGNED
                  </div>

                  <div
                    class="button is-outlined is-transparent pull-right is-small m-t-1 m-l-1"
                    v-if="activeAdsSlidesId === null"
                    @click="openAddDeviceModal(singleAd.id)"
                  >
                    + Add player
                  </div>
                  <div
                    @click="deviceRequestUpdates(singleAd.id)"
                    class="button is-outlined is-transparent pull-right is-small m-t-1"
                    v-if="
                      activeAdsSlidesId === null &&
                      singleAd.checkUpdateDevice &&
                      !updateDeviceIntervals[singleAd.id]
                    "
                  >
                    <i class="fa fa-download p-r-1"></i> Update All
                  </div>
                  <div
                    class="button is-outlined is-transparent is-disabled pull-right is-small m-t-1"
                    v-else
                  >
                    <i class="fa fa-download p-r-1"></i> Update All
                  </div>
                  <div class="is-clearfix"></div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div v-if="adsLengthEqual0">
          <p class="no-ad-text">Create new add.</p>
        </div>
        <button
          class="button is-primary is-primary__new-add"
          @click="openAddCommercial()"
          v-if="activeAdsSlidesId === null"
        >
          + new ad
        </button>
      </div>

      <b-pagination
        v-if="commercialPagination.total > commercialPagination.perPage"
        @change="changeCommercialPage"
        :total="commercialPagination.total"
        :current.sync="commercialPagination.current"
        :order="commercialPagination.order"
        :size="commercialPagination.size"
        :simple="commercialPagination.isSimple"
        :per-page="commercialPagination.perPage"
      ></b-pagination>
    </div>

    <b-modal :active.sync="addScheduleModal" :width="900" scroll="keep">
      <div class="card tac">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-size-4">FREQUENCE</h1>

            <table class="admediSchedule">
              <tr>
                <th></th>
                <th>Mo.</th>
                <th>Tu.</th>
                <th>We.</th>
                <th>Th.</th>
                <th>Fr.</th>
                <th>Sa.</th>
                <th>Su.</th>
              </tr>
              <tr v-for="hour in 24" :key="hour">
                <td>{{ hour - 1 }}:00</td>
                <td
                  v-for="day in 7"
                  :key="day"
                  @click="scheduleThis(day, hour - 1)"
                  v-bind:class="scheduleThisChecker(day, hour - 1)"
                ></td>
              </tr>
            </table>

            <div class="modal-content__buttons">
              <button
                class="button is-primary is-medium m0a db"
                @click="
                  saveSchedule();
                  setSlideAsOpened(currentSlide);
                  currentSlide.is_period = true;
                "
              >
                save
              </button>
              <button
                class="button is-outline is-medium m0a db"
                @click="
                  saveSchedule();
                  setSlideAsOpened(currentSlide);
                  currentSlide.is_period = false;
                "
              >
                Turn off frequency and save
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="admediCalendarModal" :width="900" scroll="keep">
      <div class="card tac">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-size-4 m-b-10">SCHEDULE</h1>

            <p
              class="has-text-weight-normal"
              v-if="searchForDatesRanges[0] && searchForDatesRanges[0].text === ''"
            >
              Set new date range
            </p>

            <div class="ranges ranges__modal ranges__modal--schedule">
              <div
                v-for="(range, index) in searchForDatesRanges"
                :key="index + 'range'"
                @click="setRangeSet(range)"
                v-if="range.text"
              >
                {{ range.text !== "null" ? range.text : "-" }}
              </div>
            </div>
            <div class="datesRange">
              <input v-model="currentSlide.date_from" type="hidden" />
              <b-datepicker
                inline
                @input="updateDate($event, currentSlide, 'date_from')"
                :value="createDate(currentSlide.date_from)"
                :min-date="new Date(new Date().setDate(new Date().getDate() - 1))"
              ></b-datepicker>
              <input v-model="currentSlide.date_to" type="hidden" />
              <b-datepicker
                inline
                @input="updateDate($event, currentSlide, 'date_to')"
                :value="createDate(currentSlide.date_to)"
                :min-date="createDate(currentSlide.date_from)"
              ></b-datepicker>
            </div>

            <div class="modal-content__buttons">
              <button
                class="button is-primary is-medium m0a db"
                @click="
                  currentSlide.is_dates = true;
                  saveCalendar();
                  setSlideAsOpened(currentSlide);
                "
              >
                save
              </button>
              <button
                class="button is-outline is-medium m0a db"
                @click="
                  currentSlide.is_dates = false;
                  saveCalendar();
                  setSlideAsOpened(currentSlide);
                "
              >
                Turn off schedule and save
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal :active.sync="updateDevicesModal" :width="600" scroll="keep">
      <div class="card tac">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-size-4">Update ad on assigned devices?</h1>
            <div class="modal-content__buttons">
              <button
                class="button is-primary is-medium m0a db"
                @click="
                  deviceRequestUpdates(updateDevicesModalCommercialId);
                  updateDevicesModal = false;
                "
              >
                Yes
              </button>
              <button class="button is-info is-medium m0a db" @click="updateDevicesModal = false">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <addCommercial ref="addCommercialModal" @addAd="addAd" />
    <addSlide
      ref="addSlideModal"
      :ads="ads"
      :slidersTemplatesInfo="slidersTemplatesInfo"
      @newSlide="newSlideFromAddSlide"
    />
    <addDevice
      ref="addDeviceModal"
      :commercialId="commercialId"
      :admediAdsOptions="admediAdsOptions"
      :userId="userId"
      @closeAddDevice="closeAddDevice"
      @showAvailablePlayers="openAvailablePlayers()"
    />
    <availablePlayers
      ref="availablePlayersModal"
      :commercialId="commercialId"
      @getAds="
        getAds();
        closeAvailablePlayers();
        closeAddDeviceModal();
      "
    />
    <editDevice
      ref="editDeviceModal"
      :deviceX="currentDeviceToEdit"
      :userId="userId"
      :userRole="userRole"
      :ads="ads"
      :admediAdsOptions="admediAdsOptions"
      :updateDeviceIntervals="updateDeviceIntervals"
      @deviceRequestUpdate="deviceRequestUpdate"
      @deviceRequestUpdateInfo="deviceRequestUpdateInfo"
      @getAds="getAds()"
    />

    <addMusic ref="addMusicModal" @markChanged="closeAddMusic" :commercialId="commercialId" />
    <div :key="updateDeviceIntervalsKey"></div>
    <transition name="slide-fade">
      <designer
        @closeDesigner="closeDesigner"
        @closeDesignerCancel="closeDesignerCancel"
        :commercial="openDesigner.commercial"
        :slider="openDesigner.slider"
        :sliderKey="openDesigner.sliderKey"
        v-if="openDesigner.openIt"
      ></designer>
    </transition>
  </section>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import swal from "sweetalert2";
import designer from "./designer.vue";
import { mapState } from "vuex";
import obj2arg from "graphql-obj2arg";
import draggable from "vuedraggable";
import moment from "moment-timezone";
import * as blobUtil from "blob-util";
import availablePlayers from "@/components/admAds/availablePlayers";
import addDevice from "@/components/admAds/addDevice";
import editDevice from "@/components/admAds/editDevice";
import addCommercial from "@/components/admAds/addCommercial";
import addSlide from "@/components/admAds/addSlide";
import addMusic from "@/components/admAds/addMusic";
import { device, dates, helper, advertisements, variables } from "@/mixins";

export default {
  name: "Home",
  mixins: [device, dates, helper, advertisements, variables],
  components: {
    draggable,
    designer,
    availablePlayers,
    addDevice,
    editDevice,
    addCommercial,
    addSlide,
    addMusic,
  },
  data() {
    return {
      adsLengthEqual0: false,
      getCommercialHelperValue: 9999,
      openDesigner: {
        openIt: false,
        slider: null,
        commercial: null,
      },
      admediSchedule: [],
      admediScheduleSwitch: false,
      addScheduleModal: false,
      admediCalendarSwitch: false,
      admediCalendarModal: false,
      currentSlide: {},
      backendUrl: global.pageUrl,
      currentLogoCommercialId: "",
      currentPartCommercialId: "",

      globalUrl: global.pageUrl,
      currentDeviceToEdit: {
        is_standby: false,
      },
      commercialId: null,
      addDeviceStepModal2: false,
      addCommercialModal: false,
      addSlideModal: false,
      slidersTemplatesInfo: {},
      commercialPagination: {
        total: 5,
        current: 1,
        perPage: 50,
        order: "",
        size: "",
        isSimple: false,
      },
      admediSets: global.admediSets,
      admediAdsOptions: [],
      ads: [],
      updateDevicesModalCommercialId: false,
      updateDevicesModal: false,
      slidesToDelete: [],
      currentAdName: false,
      updateDeviceIntervals: {},
      updateDeviceIntervalsKey: 0,
    };
  },
  computed: {
    ...mapState(["userId", "userRole", "activeAdsSlidesId", "activeAdsSlidesRange"]),
    searchForDatesRanges: function () {
      let theCommercial = this.ads.filter((obj) => {
        return parseInt(obj.id) === parseInt(this.activeAdsSlidesId);
      })[0];
      let rangeArray = [];
      if (theCommercial) {
        for (var property in theCommercial.sliders) {
          if (theCommercial.sliders[property].is_dates) {
            const dateFromMoment = moment(theCommercial.sliders[property].date_from, "DD.MM.YYYY");
            const dateToMoment = moment(theCommercial.sliders[property].date_to, "DD.MM.YYYY");
            let rangeText =
              (theCommercial.sliders[property].date_from !== null
                ? theCommercial.sliders[property].date_from + " - "
                : "") +
              (theCommercial.sliders[property].date_to !== null
                ? theCommercial.sliders[property].date_to
                : "");
            let rangeObject = {
              timestamp_sum: parseInt(
                String(dateFromMoment.format("X")) + String(dateToMoment.format("X"))
              ),
              timestamp_from: dateFromMoment.format("X"),
              timestamp_to: dateToMoment.format("X"),
              date_from: theCommercial.sliders[property].date_from,
              date_to: theCommercial.sliders[property].date_to,
              text: rangeText,
            };
            //push only unique ranges
            const rangeExist = rangeArray.findIndex((f) => f.text === rangeText);

            if (rangeExist === -1) {
              rangeArray.push(rangeObject);
            }
          }
        }
      }
      function compare(a, b) {
        if (a.timestamp_sum < b.timestamp_sum) return 1;
        if (a.timestamp_sum > b.timestamp_sum) return -1;
        return 0;
      }

      if (rangeArray.length > 0) {
        return rangeArray.sort(compare);
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.getAds();
    this.checkHB();
    this.getAdsForSelect();
  },
  beforeDestroy() {
    window.clearInterval(this.intervalid1);
  },
  methods: {
    changingOrder(singleAdKey) {
      //mark every slide in singleAdKey as moved to save
      for (var i = 0; i < this.ads[singleAdKey].sliders.length; i++) {
        this.ads[singleAdKey].sliders[i].order_number = i;
        Vue.set(this.ads[singleAdKey].sliders[i], "was_opened", true);
      }
    },
    setSlideAsOpened(slide) {
      Vue.set(slide, "was_opened", true);
    },

    checkHB() {
      var self = this;
      this.intervalid1 = setInterval(
        function () {
          if (self.activeAdsSlidesId === null) {
            self.getAds();
            self.$toastr.s("Checking devices");
          }
        }.bind(this),
        60000
      );
    },
    deleteCommercial(entityName, entityId) {
      var self = this;
      swal
        .fire({
          title: "Delete " + entityName + " commercial?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            let commercial = self.ads.find((obj) => {
              return parseInt(obj.id) === parseInt(entityId);
            });
            if (commercial.devices && commercial.devices.items.length > 0) {
              self.$toastr.e("Move devices first. Can't delete.");
              return;
            }

            //no devices delete instantly
            self.$toastr.s("Deleting...");
            const query = {
              mutation: `  {
                  remove_commercial(id: ${entityId}){id} }
                `,
            };
            axios
              .post(global.backendUrl, query)
              .then(function (response) {
                if (response.data.data.remove_commercial) {
                  self.getAds();
                  self.$toastr.s("Deleted");
                }
              })
              .catch(function (error) {
                self.$toastr.e("Contact the administrator " + error.message);
              });
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },

    getAds() {
      var self = this;
      let thisOffset = Math.max(
        0,
        self.commercialPagination.perPage * (self.commercialPagination.current - 1)
      );
      const query = {
        query: `  {
        commercials(limit: ${
          self.commercialPagination.perPage
        }, offset: ${thisOffset}, user_id: ${parseInt(self.userId)}){
        items{
          id
          commercial_uuid
          display_name
          url
          is_portrait
          content_json
          version_nr
          status
          sliders{
            id
            name
            date_from
            date_to
            content_html
            content_json
            duration
            is_period
            is_template
            is_logo
            is_under
            is_dates
            period_json
            filename
            path
            transition
            order_number
          }
          devices(limit: 100, offset: 0){
            paging{
              total
              limit
              has_next
              has_prev
            }
            items{
              id
              device_id
              rotation
              display_name
              status
              is_standby
              weekday_live_from
              sunday_live_from
              saturday_live_from
              weekday_live_to
              sunday_live_to
              saturday_live_to
              last_heartbeat_at
              last_heartbeat_commercial_id
              last_heartbeat_version_nr
              properties{
                uuid
              }
              subscriptions{
                status
                valid_to
              }
            }
          }
        }
        paging{
          total
          limit
          has_next
          has_prev
        }
        }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else {
            let ads = response.data.data.commercials.items;
            if (ads.length === 0) {
              self.adsLengthEqual0 = true;
            } else {
              self.adsLengthEqual0 = false;
            }
            for (var ad of ads) {
              ad.checkUpdateDevice = self.checkUpdateDevice(ad);
              if (ad.content_json) {
                ad.content_json = JSON.parse(ad.content_json);
              } else {
                ad.content_json = {};
              }
            }
            self.ads = ads;
            self.commercialPagination.total = response.data.data.commercials.paging.total;
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    getAdsForSelect() {
      var self = this;
      const query = {
        query: `  {
        commercials(limit: 200, offset: 0){
        items{
          id
          display_name
          url
        }
        paging{
          total
          limit
          has_next
          has_prev
        }
        }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else {
            self.admediAdsOptions = [];
            for (let property in response.data.data.commercials.items) {
              self.admediAdsOptions.push({
                id: response.data.data.commercials.items[property].id,
                label: response.data.data.commercials.items[property].display_name,
                url: response.data.data.commercials.items[property].url,
              });
            }
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    changeCommercialPage() {
      var self = this;
      self.$nextTick(() => self.getAds());
    },

    duplicateAd(duplicateCommercial) {
      var self = this;

      self.addAd(duplicateCommercial, true, duplicateCommercial.sliders);
    },
    async addAd(newCommercial, duplicate, duplicateSliders) {
      var self = this;
      let versionJson = {
        version: 1,
      };
      let contentJsonString = JSON.stringify(versionJson);
      let adToSave = {};
      adToSave.display_name = newCommercial.display_name;
      adToSave.is_portrait = newCommercial.is_portrait ? true : false;
      adToSave.content_json = contentJsonString;
      adToSave.url = self.globalUrl;

      if (duplicate) {
        adToSave.display_name = adToSave.display_name + " duplicate";
      }
      const query = {
        mutation: `  {
        commercial( ${obj2arg(adToSave, { noOuterBraces: true })}   ){
          id
        } 
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(async function (response) {
          if (response.data.data.commercial) {
            self.commercialPagination.current = Math.ceil(
              (self.commercialPagination.total + 1) / self.commercialPagination.perPage
            );
            self.$toastr.s("Added.");
            self.closeAddCommercial();
            // self.addAdUrl(response.data.data.commercial.id);

            self.openAddDeviceModal(response.data.data.commercial.id);
            if (!duplicate) {
              self.$nextTick(() => self.getAds());
              self.$nextTick(() => self.getAdsForSelect());
            }
            const commercialId = response.data.data.commercial.id;
            if (duplicate) {
              const promisesArray = [];
              // duplicateSliders.forEach((item, index) => {
              for (const item of duplicateSliders) {
                // clone item and remove id to make new slide
                const copied = Object.assign({}, item);
                //cloning slide with image:
                if (document.getElementById("sliderImg" + copied.id)) {
                  if (process.env.NODE_ENV === "production") {
                    let getData = await self.makeBlobFromImg("sliderImg" + copied.id);

                    let imgBlob = getData[0];
                    copied.blob = imgBlob;
                  }
                }
                delete copied.id;
                promisesArray.push(self.saveSlidePromise(copied, commercialId));
              }
              Promise.all(promisesArray).then(() => {
                self.$nextTick(() => self.getAds());
              });
            }
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    // addAdUrl(commercialId) {
    //   var self = this;
    //   const query = {
    //     mutation: `  {
    //     commercial(id: "${commercialId}" , url: "${self.globalUrl}reklama/${commercialId}"){id}
    //   }
    //   `,
    //   };
    //   axios
    //     .post(global.backendUrl, query)
    //     .then(function (response) {
    //       if (response.data.errors) {
    //         for (var property in response.data.errors) {
    //           self.$toastr.e(response.data.errors[property].message);
    //         }
    //       } else {
    //         self.openAddDeviceModal(commercialId);
    //         self.$toastr.s("Ad address saved.");
    //       }
    //     })
    //     .catch(function (error) {
    //       self.$toastr.e("Contact the administrator " + error.message);
    //     });
    // },
    saveAdDisplayName(commercialId, display_name) {
      var self = this;
      const query = {
        mutation: `  {
        commercial(id: "${commercialId}" , display_name: "${display_name}"){id}
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.data.commercial) {
            self.$toastr.s("Ad name saved.");
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    editAd(commercialEdit) {
      var self = this;
      let is_portrait = commercialEdit.is_portrait ? true : false;
      let contentJsonString = "";
      if (commercialEdit.content_json) {
        if (typeof commercialEdit.content_json === "object") {
          contentJsonString = JSON.stringify(commercialEdit.content_json);
        } else {
          contentJsonString = commercialEdit.content_json;
        }
      }

      let adToSave = {};
      adToSave.id = commercialEdit.id;
      adToSave.display_name = commercialEdit.display_name;
      adToSave.is_portrait = is_portrait;
      adToSave.content_json = contentJsonString;
      const query = {
        mutation: `  {
        commercial( ${obj2arg(adToSave, { noOuterBraces: true })}   ){id}
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.data.commercial) {
            self.$toastr.s("Saved.");
            self.$nextTick(() => self.getAds());
            self.$nextTick(() => self.getAdsForSelect());
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },

    sendPush(pushArgs, getAds, dontShowUuidError) {
      var self = this;
      var uuid = pushArgs.uuid;
      delete pushArgs.uuid;
      if (uuid) {
        self.$toastr.s("Sending data.");
      } else {
        if (!dontShowUuidError) {
          self.$toastr.e(
            "Device not synchronized - synchronize and save again to upload the settings to the device. You have launched launch adview on your device?"
          );
        }
        if (getAds) {
          self.getAds();
        }
        return;
      }
      // var fcmKey =
      //   "";
      // var fcmURL = "https://fcm.googleapis.com/fcm/send";
      // let request = {
      //   url: fcmURL,
      //   method: "post",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `key=${fcmKey}`,
      //   },
      //   data: {
      //     data: pushArgs,
      //     to: uuid,
      //     priority: "high",
      //   },
      // };
      // axios(request)
      //   .then((r) => {
      //     self.$toastr.s("Sending information to FCM - success");
      //     if (r.data.success === 1) {
      //       self.$toastr.s("Sending information to FCM - push has reached the device");
      //     } else if (r.data.failure === 1) {
      //       self.$toastr.e(
      //         "Sending information to FCM - push did not reach the device - you launched launch adview on the device?"
      //       );
      //     }
      //     if (getAds) {
      //       self.getAds();
      //       self.$toastr.s("Checking devices");
      //     }
      //   })
      //   .catch((error) => {
      //     self.$toastr.e("Sending information to FCM - error " + error);
      //     if (getAds) {
      //       self.getAds();
      //       self.$toastr.s("Checking devices");
      //     }
      //   });
    },
    newSlideFromAddSlide(slide) {
      var self = this;
      const commercialIndex = self.ads.findIndex(
        (f) => f.id === self.slidersTemplatesInfo.commercialId
      );
      self.ads[commercialIndex].sliders.push(slide);
    },
    async newSlide(isLogo, commercialId, isPortrait, isUnder, slide) {
      var self = this;
      if (commercialId) {
        self.slidersTemplatesInfo.commercialId = commercialId;
      }
      if (isPortrait) {
        self.slidersTemplatesInfo.isPortrait = isPortrait;
      }
      let commercial = self.ads.find((obj) => {
        return parseInt(obj.id) === parseInt(self.slidersTemplatesInfo.commercialId);
      });
      const slidersLength = commercial.sliders.length;
      let slideToSave = {};
      if (slide) {
        //clone
        slideToSave = Object.assign({}, slide);
        //cloning slide with image:
        if (document.getElementById("sliderImg" + slideToSave.id)) {
          if (process.env.NODE_ENV === "production") {
            let getData = await this.makeBlobFromImg("sliderImg" + slideToSave.id);

            let imgBlob = getData[0];
            var dataURL = getData[1];
            slideToSave.blob = imgBlob;
            slideToSave.toDataURLImage = dataURL;
          }
        }
        delete slideToSave.id;
      } else {
        // new
        slideToSave = {};

        let page = Object.assign({}, self.$store.state.defaultPage);
        page.width = self.slidersTemplatesInfo.isPortrait ? 1080 : 1920;
        page.height = self.slidersTemplatesInfo.isPortrait ? 1920 : 1080;
        page.version = 1;
        let contentJson = {
          page: page,
          widgets: [],
        };

        slideToSave.content_json = JSON.stringify(contentJson);
      }
      slideToSave.date_from = slideToSave.date_from ? slideToSave.date_from : "00.00.0000";
      slideToSave.date_to = slideToSave.date_to ? slideToSave.date_to : "00.00.0000";
      slideToSave.is_dates = slideToSave.is_dates ? slideToSave.is_dates : false;
      slideToSave.was_opened = true;
      slideToSave.is_logo = isLogo;
      slideToSave.is_under = isUnder;

      slideToSave.name = slideToSave.name
        ? slideToSave.name
        : Math.round(+new Date() / 1000) + "slide";
      slideToSave.commercial_id = self.slidersTemplatesInfo.commercialId;

      slideToSave.transition = slideToSave.transition ? slideToSave.transition : "fade";
      slideToSave.order_number = 999 + slidersLength;

      slideToSave.duration = slideToSave.duration ? slideToSave.duration : "00:00:05";
      slideToSave.content_html = slideToSave.content_html ? slideToSave.content_html : "&nbsp;";

      commercial.sliders.push(slideToSave);
      this.openDesignerComponent(commercial, slideToSave, slidersLength);
      //open added
    },

    makeBlobFromImg(elementId) {
      return new Promise((resolve) => {
        var img = document.getElementById(elementId);

        blobUtil.imgSrcToBlob(img.src).then(function (blob) {
          blobUtil.blobToDataURL(blob).then(function (dataurl) {
            resolve([blob, dataurl]);
          });
        });
      });
    },
    deleteSlide(commercialId, entityId) {
      var self = this;
      swal
        .fire({
          title: "Delete slide?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            const commercialIndex = self.ads.findIndex((f) => f.id === commercialId);
            const slideIndex = self.ads[commercialIndex].sliders.findIndex(
              (f) => f.id === entityId
            );
            self.ads[commercialIndex].sliders.splice(slideIndex, 1);
            self.slidesToDelete.push(entityId);
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },
    deviceRequestUpdates(commercialId) {
      var self = this;

      let commercial = self.ads.filter((obj) => {
        return parseInt(obj.id) === parseInt(commercialId);
      });
      let devices = commercial[0].devices.items;
      for (var device of devices) {
        self.deviceRequestUpdate(device.device_id, device);
      }
      self.deviceRequestUpdateInfo();
    },
    deviceRequestUpdateInfo() {
      swal
        .fire({
          title: "Your content will be displayed in a few minutes.",
          type: "success",
          showCancelButton: false,
          confirmButtonText: "OK",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {});
    },
    deviceRequestUpdate(device_id, currentDeviceToEdit) {
      var self = this;
      if (this.userRole === "NORMAL") {
        if (
          !(
            currentDeviceToEdit.subscriptions &&
            this.getValidSubscription(currentDeviceToEdit.subscriptions) !== false
          )
        ) {
          //console.log(currentDeviceToEdit);
          self.$toastr.e(
            "Can't update device " + currentDeviceToEdit.display_name + ". No valid subscription. "
          );
          return;
        }
      }
      const query = {
        mutation: `  {
        device_request_update(device_id: "${device_id}")
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.data.device_request_update) {
            self.$toastr.s(
              "Device push request saved - when zip will be ready, the device will be updated. "
            );
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    getCommercialHelper() {
      var self = this;
      self.getCommercialHelperValue = self.getCommercialHelperValue - 1;
      if (self.getCommercialHelperValue === 0) {
        self.$nextTick(() => self.getAds());
        self.getCommercialHelperValue = 9999;
      }
    },
    currentRangeFilter(dateFrom, dateTo, isDates) {
      if (
        !this.activeAdsSlidesRange ||
        !isDates ||
        dateFrom + " - " + dateTo === this.activeAdsSlidesRange.text
      ) {
        return true;
      }
      return false;
    },
    activeAdsSlidesSet(slideId, display_name) {
      this.currentAdName = display_name;
      this.$store.commit("activeAdsRangeSet", null);
      if (slideId === this.activeAdsSlidesId) {
        this.$store.commit("activeAdsSlidesSet", null);
      } else {
        this.$store.commit("activeAdsSlidesSet", slideId);
      }
    },
    activeAdsRangeSet(slideRange) {
      if (slideRange === this.activeAdsSlidesRange) {
        this.$store.commit("activeAdsRangeSet", null);
      } else {
        this.$store.commit("activeAdsRangeSet", slideRange);
      }
    },
    setRangeSet(slideRange) {
      this.currentSlide.date_to = slideRange.date_to;
      this.currentSlide.date_from = slideRange.date_from;
    },

    slideDuration(slide, direction) {
      this.setSlideAsOpened(slide);
      slide.duration = moment(slide.duration, "HH:mm:ss")
        .add(direction, "seconds")
        .format("HH:mm:ss");
    },
    scheduleThis(day, hour) {
      var self = this;
      if (!self.admediSchedule[day]) {
        Vue.set(self.admediSchedule, day, []);
      }
      var i = self.admediSchedule[day].indexOf(hour);
      if (i === -1) {
        let index = self.admediSchedule[day].length;
        Vue.set(self.admediSchedule[day], index, hour);
      } else {
        Vue.delete(self.admediSchedule[day], i);
      }
    },
    scheduleThisChecker(day, hour) {
      var self = this;
      if (self.admediSchedule[day]) {
        var i = self.admediSchedule[day].indexOf(hour);
        if (i !== -1) {
          return "isActive";
        }
      }
    },
    addScheduleModalOpen(slide) {
      this.addScheduleModal = true;
      this.currentSlide = slide;
      this.admediSchedule = [];
      if (slide.period_json) {
        this.admediSchedule = JSON.parse(slide.period_json);
      }
    },
    addCalendarModalOpen(slide) {
      this.admediCalendarModal = true;
      this.currentSlide = slide;
    },
    saveSchedule() {
      this.currentSlide.was_opened = true;
      this.currentSlide.period_json = JSON.stringify(this.admediSchedule);
      this.addScheduleModal = false;
    },
    saveCalendar() {
      if (
        (!this.currentSlide.date_from || !this.currentSlide.date_to) &&
        this.currentSlide.is_dates
      ) {
        this.$toastr.e("Choose dates.");
      } else {
        this.currentSlide.was_opened = true;
        this.admediCalendarModal = false;
      }
    },
    openDesignerComponent(commercial, slider, sliderKey) {
      this.openDesigner.commercial = commercial;
      this.openDesigner.slider = slider;
      this.openDesigner.sliderKey = sliderKey;
      this.openDesigner.openIt = true;
    },
    deleteSlidePromise(slideToDeleteId) {
      var self = this;
      return new Promise((resolve, reject) => {
        const query = {
          mutation: `  {
          remove_slider(id: ${slideToDeleteId}) }
        `,
        };
        axios
          .post(global.backendUrl, query)
          .then(function (response) {
            if (response.data.data.remove_slider) {
              self.$toastr.s("Slide deleted.");
              resolve("true");
            }
          })
          .catch(function (error) {
            self.$toastr.e("Contact the administrator " + error.message);
          });
      });
    },
    saveSlidePromise(slide, commercialId) {
      var self = this;
      return new Promise((resolve, reject) => {
        let slideToSave = Object.assign({}, slide);

        delete slideToSave.was_opened;
        delete slideToSave.filename;
        delete slideToSave.path;
        delete slideToSave.toDataURLImage;
        if (commercialId) {
          slideToSave.commercial_id = commercialId;
        }
        slideToSave.date_from =
          slideToSave.date_from !== null && slideToSave.date_from !== "Invalid date"
            ? slideToSave.date_from
            : "00.00.0000";
        slideToSave.date_to =
          slideToSave.date_to !== null && slideToSave.date_to !== "Invalid date"
            ? slideToSave.date_to
            : "00.00.0000";
        let a = new FormData();
        if (slideToSave.blob) {
          var fileToSave = new File([slideToSave.blob], "name.png");
          delete slideToSave.blob;
          a.append("file", fileToSave);
          a.append(
            "mutation",
            `{
            slider(${obj2arg(slideToSave, { noOuterBraces: true })} , file: "file"){
                id
              }
            }`
          );
        } else {
          a.append(
            "mutation",
            `{
          slider(${obj2arg(slideToSave, { noOuterBraces: true })} ){
              id
            }
          }`
          );
        }

        axios
          .post(global.backendUrl, a)
          .then(function (response) {
            if (response.data.data.slider) {
              self.$toastr.s("Slive saved");

              resolve("true");
            }
          })
          .catch(function (error) {
            self.$toastr.e("Contact the administrator " + error.message);
          });
      });
    },
    saveSlidesSet(commercialId) {
      var self = this;
      const commercialIndex = self.ads.findIndex((f) => f.id === commercialId);
      let slides = self.ads[commercialIndex].sliders.filter((obj) => {
        return obj.was_opened === true;
      });

      if (slides.length === 0 && self.slidesToDelete.length === 0) {
        self.$toastr.s("No slides to save.");
        self.$store.commit("activeAdsSlidesSet", null);
      } else {
        const promisesArray = [];
        this.slidesToDelete.forEach((item) => {
          promisesArray.push(this.deleteSlidePromise(item));
        });
        slides.forEach((item, index) => {
          //console.log(item);
          promisesArray.push(this.saveSlidePromise(item));
        });
        Promise.all(promisesArray).then(() => {
          self.slidesToDelete = [];
          if (self.ads[commercialIndex].devices.items.length > 0) {
            self.updateDevicesModal = true;
            self.updateDevicesModalCommercialId = commercialId;
          }
          self.$store.commit("activeAdsSlidesSet", null);
          self.editAd(self.ads[commercialIndex]);
        });
      }
    },
    commercialSlidesEdited(commercial) {
      let slides = commercial.sliders.filter((obj) => {
        return obj.was_opened === true;
      });
      return slides.length > 0 || this.slidesToDelete.length > 0 ? true : false;
    },
    closeSlidesSet(commercialId) {
      var self = this;
      const commercialIndex = this.ads.findIndex((f) => f.id === commercialId);
      let slides = this.ads[commercialIndex].sliders.filter((obj) => {
        return obj.was_opened === true;
      });
      if (slides.length > 0 || self.slidesToDelete.length > 0) {
        swal
          .fire({
            text: "Your ad has not been saved.",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Save and close",
            cancelButtonText: "Close, without saving",
            showCloseButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: "button is-primary",
              cancelButton: "button is-outlined m-l-2",
            },
          })
          .then((result) => {
            if (result.value) {
              this.saveSlidesSet(commercialId);
            } else {
              self.$store.commit("activeAdsSlidesSet", null);
              self.slidesToDelete = [];
              self.getAds();
            }
          });
      } else {
        self.$store.commit("activeAdsSlidesSet", null);
        self.slidesToDelete = [];
      }
    },
    doPreview(payload) {
      var self = this;
      function postForm(path, params, method) {
        method = method || "post";
        var form = document.createElement("form");
        form.setAttribute("method", method);
        form.setAttribute("action", path);
        form.setAttribute("target", "_blank");
        form.setAttribute("id", "previewForm");
        for (var key in params) {
          if (params.hasOwnProperty(key)) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);
            form.appendChild(hiddenField);
          }
        }

        document.body.appendChild(form);
        form.submit();
        document.getElementById("previewForm").remove();
      }
      postForm(self.backendUrl + "reklama/" + payload.commercial_uuid, {
        theCommercial: JSON.stringify(payload),
      });
    },
    closeDesigner(newSlide, sliderKey) {
      this.openDesigner.openIt = false;
      const commercialIndex = this.ads.findIndex((f) => f.id === newSlide.commercialId);
      Vue.set(this.ads[commercialIndex].sliders, sliderKey, newSlide.slide);
      document.querySelector("#ad_id_" + this.ads[commercialIndex].id).scrollIntoView({
        behavior: "smooth",
      });
    },
    closeDesignerCancel(commercialId) {
      this.openDesigner.openIt = false;
      document.querySelector("#ad_id_" + commercialId).scrollIntoView({
        behavior: "smooth",
      });
    },
    checkUpdateDevice(singleAd) {
      let makeUpdate = false;
      singleAd.devices.items.forEach(function (entry) {
        if (
          singleAd.id != entry.last_heartbeat_commercial_id ||
          singleAd.version_nr != entry.last_heartbeat_version_nr
        ) {
          makeUpdate = true;
        }
      });
      return makeUpdate;
    },
    openAddDeviceModal(commercialId) {
      var self = this;
      self.commercialId = commercialId;
      self.$refs.addDeviceModal.show();
    },
    closeAddDeviceModal() {
      this.$refs.addDeviceModal.hide();
    },
    openAvailablePlayers() {
      this.$refs.availablePlayersModal.show();
    },
    closeAvailablePlayers() {
      this.$refs.availablePlayersModal.hide();
    },
    openEditDeviceModal() {
      this.$refs.editDeviceModal.show();
    },
    closeAddDevice(newDevice) {
      // this.sendPush(pushArgs, false, false);
      this.currentDeviceToEdit = newDevice;
      this.$refs.editDeviceModal.show();
    },
    openAddCommercial() {
      this.$refs.addCommercialModal.show();
    },
    closeAddCommercial() {
      this.$refs.addCommercialModal.hide();
    },
    openAddSlide(commercialId, isPortrait) {
      this.slidersTemplatesInfo = {};
      this.slidersTemplatesInfo.commercialId = commercialId;
      this.slidersTemplatesInfo.isPortrait = isPortrait; // not used at the moment
      this.$refs.addSlideModal.show();
    },
    closeAddSlide() {
      this.$refs.addSlideModal.hide();
    },
    openAddMusic(commercialId) {
      this.commercialId = commercialId;
      this.$refs.addMusicModal.show();
    },
    closeAddMusic() {
      this.updateDevicesModalCommercialId = this.commercialId;
      this.updateDevicesModal = true;
      this.$refs.addMusicModal.hide();
    },
  },
};
</script>