<template>
  <div :class="'mediaLibrary ' + (isFont ? 'mediaLibrary__font' : '')">
    <div>
      <div class="mediaLibraryHeader">
        <div class="drophere">
          <b-field>
            <b-upload
              class="drag-files"
              @input="addImg"
              v-model="dropFiles"
              multiple
              drag-drop
              :accept="isFont ? '.ttf' : '.jpg, .jpeg, gif, .bmp, .png, .mp4 '"
            >
              <div class="has-text-centered">
                <p>Drag files here or click to add</p>
              </div>
            </b-upload>
          </b-field>
          <div class="tags">
            <div v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
              {{ file.name }}
              <div class="icon"><i class="fa fa-trash cp" @click="deleteDropFile(index)"></i></div>
            </div>
          </div>
        </div>
        <!-- <button class="mediaButton" @click="addImg()">Add</button> -->
        <button class="mediaButton" @click="closeUploader()">x</button>
      </div>
      <div class="progress-container">
        <b-progress :value="progressValue" v-if="showProgress"></b-progress>
      </div>
      <div :class="' ' + (isFont ? 'fontsContainer' : 'imgsContainer')">
        <div v-for="file in files" :key="file.id">
          <div :class="' ' + (isFont ? '' : 'imgContainer')">
            <img
              :src="backendUrl + file.path + '140_92/' + file.filename"
              :alt="file.name"
              v-if="checkFileType(file.filename) === 'thisIsImg'"
            />
            <video v-if="checkFileType(file.filename) === 'thisIsVid'" :id="file.id + 'vid'">
              <source :src="backendUrl + file.path + file.filename" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p
              class="cp"
              v-if="isFont"
              :style="
                'font-family:' + file.original_filename.replace('.ttf', '').replace('.TTF', '')
              "
              @click="
                chooseImg(
                  '/' + file.path + file.filename,
                  checkFileType(file.filename),
                  file.id,
                  file.original_filename
                )
              "
            >
              <i
                class="fa fa-plus"
                v-if="file.is_added_by_admin === is_admin || !file.is_added_by_admin"
              ></i>
              {{ file.original_filename }}
            </p>
            <div
              v-if="!isFont"
              class="fa fa-plus addThisImage"
              @click="
                chooseImg(
                  '/' + file.path + file.filename,
                  checkFileType(file.filename),
                  file.id,
                  file.original_filename
                )
              "
            ></div>
            <div>
              <i
                class="fa fa-times"
                @click="deleteImage(file.id)"
                v-if="file.is_added_by_admin === is_admin || !file.is_added_by_admin"
              ></i>
            </div>
          </div>
          <p v-if="!isFont">{{ file.original_filename }}</p>
          <p v-if="checkFileType(file.filename) === 'thisIsImg'">
            {{ file.width }} x {{ file.height }}
          </p>
        </div>
      </div>
      <b-pagination
        v-if="filesPagination.total > filesPagination.limit"
        @change="changeFilesPage"
        :total="filesPagination.total"
        :current.sync="filesPagination.page"
        :simple="false"
        :rounded="false"
        icon-pack="fa"
        :per-page="filesPagination.limit"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import obj2arg from "graphql-obj2arg";
export default {
  name: "admedi-uploader",
  props: ["imageCategories", "currentImageCategory", "thisIsBg", "slideDuration"],
  data() {
    return {
      showProgress: false,
      progressValue: 0,
      hidePanel: false,
      history: [],
      currentIndex: -1,
      disableHistory: false,
      backendUrl: global.pageUrl,
      currentItem: {},
      commercial: {},
      files: [],
      fileName: "",
      filesPagination: {
        total: 0,
        limit: 50,
        page: 1,
      },
      currentSliderIsTemplate: false,
      dropFiles: [],
    };
  },
  computed: {
    is_admin() {
      return this.$store.state.userIsAdmin;
    },
    storeImgCategory() {
      return this.$store.state.currentImageCategory;
    },
    changeSlideDurationLocal: function () {
      return this.changeSlideDuration;
    },
    uploadItem: function () {
      return this.$store.state.uploadItem;
    },
    currentUploaderType() {
      return this.$store.state.currentUploaderType;
    },
    isFont() {
      return this.$store.state.currentUploaderType[0] === "Enum::FONT" ? true : false;
    },
  },
  mounted() {
    this.startUploader();
  },
  methods: {
    checkFileType(filename) {
      var parts = filename.split(".");
      var ext = parts[parts.length - 1];
      switch (ext.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "gif":
        case "bmp":
        case "png":
          return "thisIsImg";
        case "m4v":
        case "avi":
        case "mpg":
        case "mp4":
          return "thisIsVid";
        case "ttf":
          return "thisIsFont";
      }
    },
    mountFont(font) {
      const fontName = font.original_filename.replace(".ttf", "").replace(".TTF", "");
      if (!document.getElementById(fontName)) {
        //append font only once
        var head = document.getElementsByTagName("head")[0];
        var style = document.createElement("style");
        style.id = fontName;
        var fontDeclaration =
          "@font-face {font-family: '" +
          fontName +
          "'; src: url('/" +
          font.path +
          font.filename +
          "');}";
        head.appendChild(style);
        style.type = "text/css";
        if (style.styleSheet) {
          // This is required for IE8 and below.
          style.styleSheet.cssText = fontDeclaration;
        } else {
          style.appendChild(document.createTextNode(fontDeclaration));
        }
      }
    },
    chooseImg(path, filetype, fileId, original_filename) {
      if (filetype === "thisIsFont") {
        let payload = {
          path: path,
          fileId: fileId,
          original_filename: original_filename,
        };
        this.$store.commit("setFont", payload);
      }
      //addImage
      else if (this.uploadItem && this.uploadItem.name != "admedi-bg") {
        console.log("addingImg");
        // addWidget(e, currentItem, backendUrl + file.path + file.filename, checkFileType(file.filename), file.id)
        let item = this.uploadItem;
        this.showWidgets = false;
        // this.isComponentModalActive = false;
        if (path) {
          item.imgUrl = path;
        }
        if (filetype) {
          item.mediaType = filetype;
        }
        if (fileId) {
          item.mediaId = fileId;
        }

        if (item.mediaType === "thisIsVid" && item.name === "admedi-pic") {
          console.log("video!");
          var vid = document.getElementById(fileId + "vid");
          item.height = vid.videoHeight; // returns the intrinsic height of the video
          item.width = vid.videoWidth; // returns the intrinsic width of the video
        }
        console.log(item);
        this.$store.dispatch("addWidget", item);
        console.log("dispatch");
        this.$store.commit("uploadItem", null);
        // this.$store.commit("replaceImage", payload);
      } else {
        //background
        console.log("addingBackground");

        console.log("choose image " + fileId);
        let payload = {
          path: path,
          mediaType: filetype,
          fileId: fileId,
        };
        this.$store.commit("replaceImage", payload);
      }

      if (filetype === "thisIsVid") {
        this.$toastr.s("Dopasowuje czas trwania slide'u do czasu trwania video");
        if (document.querySelector("source[src='" + path + "']")) {
          this.$emit(
            "changeSlideDuration",
            document.querySelector("source[src='" + path + "']").parentNode.duration
          );
        }
      }

      this.closeUploader();
    },
    startUploader() {
      var self = this;
      self.filesPagination.page = 1;
      self.filesPagination.total = 0;
      self.getImages();
    },
    changeFilesPage: function (page) {
      var self = this;
      self.filesPagination.page = page;
      self.$nextTick(() => this.getImages());
    },
    getImages() {
      var self = this;
      let queryArgs = {
        limit: self.filesPagination.limit,
        offset: (self.filesPagination.page - 1) * self.filesPagination.limit,
        uploaded_types: self.currentUploaderType,
      };
      const query = {
        query: `  {
          logged_user{
            is_admin
            uploaded_files( ${obj2arg(queryArgs, { noOuterBraces: true })} ){
              paging{
                total
                limit
                has_next
                has_prev
              }
              items{
                id
                filename
                name
                original_filename
                width
                height
                path
                created_at
                is_added_by_admin
              }
            }
          }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.data.logged_user.uploaded_files) {
            self.files = response.data.data.logged_user.uploaded_files.items;
            if (self.currentUploaderType[0] === "Enum::FONT") {
              console.log("mountfonts");
              for (var i = 0; i < self.files.length; i++) {
                self.mountFont(self.files[i]);
              }
            }

            self.filesPagination.total = response.data.data.logged_user.uploaded_files.paging.total;
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    addImg() {
      var self = this;
      self.showProgress = true;
      self.progressValue = 0;
      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          self.progressValue = percentCompleted;
        },
      };
      if (!self.dropFiles[0]) {
        self.$toastr.e("Uzupełnij.");
        return;
      }

      // let iCategory = self.currentImageCategory;
      // if (typeof iCategory === "undefined" || iCategory === null) {
      //   iCategory = self.storeImgCategory;
      // }
      let iterator = 0;

      for (var i = 0; i < self.dropFiles.length; i++) {
        var FileSize = self.dropFiles[i].size / 1024 / 1024; // in MiB

        if (FileSize > 256) {
          console.log("skipping big file");
          self.$toastr.e(
            "File " +
              self.dropFiles[i].name +
              " is to big " +
              Math.round(FileSize * 100) / 100 +
              "MB. Max is 256MB. File can't be added."
          );
          continue;
        }

        let uploaded_type = "IMAGE";

        if (self.checkFileType(self.dropFiles[i].name) === "thisIsFont") {
          uploaded_type = "FONT";
        } else if (self.checkFileType(self.dropFiles[i].name) === "thisIsVid") {
          uploaded_type = "VIDEO";
        }
        let queryArgs = {
          name: self.dropFiles[i].name,
          file: "file",
          categories_ids: [],
          uploaded_type: "Enum::" + uploaded_type,
        };
        let a = new FormData();
        a.append(
          "mutation",
          `{ upload_file(
            ${obj2arg(queryArgs, { noOuterBraces: true })}
           ) { id }}`
        );
        a.append("file", self.dropFiles[i]);
        axios
          .post(global.backendUrl, a, config)
          .then(function (response) {
            if (response.data.data.upload_file) {
              self.getImages();
              self.$toastr.s("File has been added.");
              iterator++;
              if (iterator == self.dropFiles.length) {
                self.dropFiles = [];
              }
            }
            //self.dropFiles = [];

            self.showProgress = false;
          })
          .catch(function (error) {
            self.$toastr.e("Contact the administrator " + error.message);
            self.dropFiles = [];
            self.showProgress = false;
          });
      }
    },
    closeUploader() {
      this.$store.commit("doUploader", false);
    },
    deleteImage(imageId) {
      var self = this;
      swal
        .fire({
          title:
            "Are you sure you want to delete this file? Note, if the file is used on another slide, the file will not be available.",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            const query = {
              mutation: `  {
            remove_uploaded_file(id: "${imageId}" ){
              id
            }
            }
            `,
            };
            axios
              .post(global.backendUrl, query)
              .then(function (response) {
                if (response.data.data.remove_uploaded_file) {
                  self.getImages(self.currentImageCategory);
                  self.$toastr.s("File deleted.");
                }
              })
              .catch(function (error) {
                self.$toastr.e("Contact the administrator " + error.message);
              });
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
