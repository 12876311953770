import Vue from 'vue'
import Router from 'vue-router'
import admLogin from '../views/users/admLogin'
import adAdminRegister from '../views/admin/registerUser'
import admUsers from '../views/admin/users'
import payments from '../views/admin/payments'
import admRegister from '../views/users/admRegister'
import admActivate from '../views/users/admActivate'
import admDevices from '../views/devices/index'
import admResetPassword from '../views/users/admResetPassword'
import admMyAccount from '../views/users/admMyAccount'
import admAdsList from '../views/admAdsList'
import designer from '../views/designer'
import paymentSummary from '../views/payments/paymentSummary'
import paymentSuccesfull from '../views/payments/paymentSuccesfull'
import paymentCancelled from '../views/payments/paymentCancelled'
import NotFoundView from '../views/404'
import { requireAuth, requireAuthAdmin } from './auth.js'
Vue.use(Router)
export default new Router({
  mode: 'history',
  base: '/app/',
  linkActiveClass: 'is-active', // for router-link
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      beforeEnter: requireAuth,
      path: '/advertisements',
      name: 'admAdsList',
      component: admAdsList
    },
    {
      beforeEnter: requireAuth,
      path: '/devices',
      name: 'admDevices',
      component: admDevices
    },
    {
      path: '/',
      name: 'admLogin',
      component: admLogin
    },
    {
      beforeEnter: requireAuthAdmin,
      path: '/users',
      name: 'admUsers',
      component: admUsers
    },
    {
      beforeEnter: requireAuthAdmin,
      path: '/regiser-user',
      name: 'adAdminRegister',
      component: adAdminRegister
    },
    {
      beforeEnter: requireAuthAdmin,
      path: '/payments',
      name: 'payments',
      component: payments
    },
    {
      beforeEnter: requireAuth,
      path: '/my-account',
      name: 'admMyAccount',
      component: admMyAccount
    },
    {

      path: '/register',
      name: 'admRegister',
      component: admRegister
    },
    {

      path: '/activate/:code',
      props: true,
      name: 'admActivate',
      component: admActivate
    },
    {

      path: '/reset-password/:code',
      props: true,
      name: 'admResetPassword',
      component: admResetPassword
    },
    {
      beforeEnter: requireAuth,
      props: true,
      path: '/reklama/:entityId/:entitySlideId',
      name: 'designer',
      component: designer
    },
    {
      path: '/404',
      component: NotFoundView
    },
    {
      beforeEnter: requireAuth,
      props: true,
      path: '/payment-summary',
      name: 'paymentSummary',
      component: paymentSummary
    },
    {
      beforeEnter: requireAuth,
      props: true,
      path: '/payment-succesfull',
      name: 'paymentSuccesfull',
      component: paymentSuccesfull
    },
    {
      beforeEnter: requireAuth,
      props: true,
      path: '/payment-cancelled',
      name: 'paymentCancelled',
      component: paymentCancelled
    },
    {
      path: '*',
      component: NotFoundView
    }
  ]
})
