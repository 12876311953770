<template>
  <section>
    <div class="table__devices">
      <h2 class="devices__list">Devices list</h2>
      <b-table :data="devices" class="table__device">
        <template slot-scope="props">
          <b-table-column field="id" label="">
            <b-checkbox
              v-if="
                !props.row.subscriptions[0] ||
                  (props.row.subscriptions[0] && props.row.subscriptions[0].status !== 'FREE')
              "
              :value="selectedDevices.has(props.row.id)"
              @input="selectDevice(props.row)"
            ></b-checkbox>
          </b-table-column>
          <b-table-column field="display_name" label="">{{
            props.row.display_name +
              (props.row.commercial && props.row.commercial.display_name
                ? " assigned to " + props.row.commercial.display_name
                : "")
          }}</b-table-column>
          <b-table-column field="subscriptions" label="">
            <div :key="index" v-for="(subscription, index) in props.row.subscriptions">
              {{ subscription.status }} valid to
              {{ subscription.valid_to ? i18ndate(subscription.valid_to) : "-" }}
            </div>
            <div v-if="props.row.subscriptions.length === 0">no subscription</div>
          </b-table-column>
        </template>
      </b-table>
      <div class="summary">
        <div>
          I extend subscription for {{ devicesSum }} devices
          <b-input v-model="monthsLength" type="number" min="1" class="months-length"> </b-input>
          for months
        </div>
        <div class="summary__sum">SUM: {{ calcSum }}$</div>
      </div>
      <b-pagination
        v-if="commercialPagination.total > commercialPagination.perPage"
        @change="changeCommercialPage"
        :total="commercialPagination.total"
        :current.sync="commercialPagination.current"
        :order="commercialPagination.order"
        :size="commercialPagination.size"
        :simple="commercialPagination.isSimple"
        :per-page="commercialPagination.perPage"
      ></b-pagination>
      <br />
      <br />

      <b-button type="is-primary" class="proceed" @click="proceed()">Proceed to summary</b-button>
      <br />
      <br />
    </div>
  </section>
</template>
<script>
import axios from "axios";
import Toastr from "vue-toastr";
import moment from "moment-timezone";
import { dates } from "@/mixins";

export default {
  components: {
    "vue-toastr": Toastr
  },
  mixins: [dates],
  data() {
    return {
      showModal: false,
      userToEdit: null,
      newPass: null,
      devices: [],
      backendUrl: window.backendUrl,
      error: "",
      commercialPagination: {
        total: 0,
        current: 1,
        perPage: 20,
        order: "",
        size: "",
        isSimple: false
      },
      selectedDevices: new Map(),
      monthsLength: 1,
      setTracker: 0
    };
  },
  computed: {
    calcSum() {
      return this.setTracker && this.selectedDevices.size * 5 * this.monthsLength; //device monthly cost
    },
    devicesSum() {
      return this.setTracker && this.selectedDevices.size;
    }
  },
  mounted() {
    this.getdevices();
  },
  methods: {
    proceed() {
      if (this.devicesSum < 1) {
        this.$toastr.e("Select devices.");
      } else {
        const arr = [...this.selectedDevices].map(([name, value]) => ({ name, value }));
        this.$router.push({
          name: "paymentSummary",
          params: { months: this.monthsLength, devices: arr }
        });
      }
    },
    changeCommercialPage() {
      var self = this;
      self.$nextTick(() => self.getdevices());
    },
    getdevices() {
      var self = this;
      let thisOffset = Math.max(
        0,
        self.commercialPagination.perPage * (self.commercialPagination.current - 1)
      );
      let query = {
        query: `{
        devices(limit: ${self.commercialPagination.perPage}, offset: ${thisOffset}){
            items{
                id
                display_name
                commercial{display_name}
                subscriptions{
                  #id
                  status
                  valid_to
                }
                #email
                
             
            }
                    paging{
          total
          limit
          has_next
          has_prev
        }
        }
      }`
      };

      axios
        .post(global.backendUrl, query)
        .then(function(response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else {
            self.devices = response.data.data.devices.items;
            self.commercialPagination.total = response.data.data.devices.paging.total;
          }
        })
        .catch(function(error) {
          self.$toastr.e("Contact the administrator " + error);
        });
    },
    selectDevice(device) {
      if (!this.selectedDevices.has(device.id)) {
        this.selectedDevices.set(device.id, device);
      } else {
        this.selectedDevices.delete(device.id);
      }
      this.setTracker += 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.mw400 {
  max-width: 400px;
}
.table__devices {
  max-width: 600px;
  margin: 0 auto;
}

.table__devices .b-table .table th .th-wrap {
  display: none;
}
.table__devices .table {
  border-color: #333333;
}
.months-length {
  width: 60px;
  display: inline-block;
  margin: 10px;
}
.proceed {
  display: block;
  margin: 0 auto;
}

.summary {
  display: flex;

  justify-content: space-between;
}
.summary > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.summary__sum {
  font-size: 24px;
}
.devices__list {
  margin-top: 45px;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 10px;
}
</style>
