<template>
  <div>
    <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        animation
        <select
          v-model="activeElement.widgetAnimationClass"
          class="panel__select"
          @change="changeAnimation"
        >
          <option value>none</option>
          <option v-for="(val, index) in animationNames" :key="index" :value="val">
            {{ val }}
          </option>
        </select>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="activeElement.widgetAnimationClass">
        <div class="panel-row" flex>
          <div class="field">
            <b-checkbox v-model="activeElement.widgetAnimationIterationCount">Loop</b-checkbox>
          </div>
        </div>
        <div class="panel-row" flex v-if="!activeElement.widgetAnimationIterationCount">
          <div class="panel-label panel-label--input">
            <span>delay</span>
            <div><input class="panel-input" v-model="activeElement.widgetAnimationDelay" /> s</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="0.1" :max="10" :min="0" v-model="activeElement.widgetAnimationDelay" />
          </div>
        </div>
        <div class="panel-row" flex v-if="!activeElement.widgetAnimationIterationCount">
          <div class="panel-label panel-label--input">
            <span>duration</span>
            <div>
              <input class="panel-input" v-model="activeElement.widgetAnimationDuration" /> s
            </div>
          </div>

          <div class="panel-slider-wrap">
            <slider
              :step="0.1"
              :max="10"
              :min="0"
              v-model="activeElement.widgetAnimationDuration"
            />
          </div>
        </div>
      </div>
    </transition>

    <div class="panel-row" flex title="background color">
      <div class="panel-value">
        <div class="panel__color">
          <input type="color" v-model="activeElement.bgColor" />
        </div>
        <input class="panel-input" v-model="activeElement.bgColor" />
      </div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-label panel-label--input">
        <span>round corner</span>
        <div>
          <input class="panel-input" v-model="activeElement.radius" />
        </div>
      </div>

      <div class="panel-slider-wrap">
        <slider :step="1" :max="10000" :min="0" v-model="activeElement.radius" />
      </div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        border:
        <select v-model="activeElement.borderType" class="panel__select">
          <option value="dotted">dotted</option>
          <option value="dashed">dashed</option>
          <option value="solid">solid</option>
          <option value="double">double</option>
          <option value="groove">groove</option>
          <option value="ridge">ridge</option>
          <option value="inset">inset</option>
          <option value="outset">outset</option>
          <option value="none">none</option>
          <option value="hidden">hidden</option>
        </select>
      </div>
    </div>

    <div class="panel-row" flex title="border color">
      <div class="panel-value">
        <div class="panel__color">
          <input type="color" v-model="activeElement.borderColor" />
        </div>

        <input class="panel-input" v-model="activeElement.borderColor" />
      </div>
    </div>

    <div class="panel-row" flex title="border width">
      <div class="panel-label panel-label--input">
        <span>size</span>
        <div>
          <input class="panel-input" v-model="activeElement.borderWidth" />
        </div>
      </div>

      <div class="panel-slider-wrap">
        <slider :step="1" :max="100" :min="0" v-model="activeElement.borderWidth" />
      </div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-label">transparency</div>
      <div class="panel-value">{{ Math.round(activeElement.opacity * 100) }} %</div>
      <div class="panel-slider-wrap">
        <slider :step="0.01" :max="1" :min="0" v-model="activeElement.opacity" />
      </div>
    </div>
    <!-- <div class="panel-row" flex>
      <div class="panel-label panel-label--input">
        <span>rotation</span>
        <div>
          <input class="panel-input" v-model="activeElement.rotate" />
        </div>
      </div>

      <div class="panel-slider-wrap">
        <slider :step="1" :max="360" :min="0" v-model="activeElement.rotate" />
      </div>
    </div>-->

    <br />

    <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        drop shadow:
        <select v-model="activeElement.enableShadow" class="panel__select">
          <option value>none</option>
          <option value="true">enable</option>
        </select>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="activeElement.enableShadow">
        <div class="panel-row" flex title="background color">
          <div class="panel-value">
            <div class="panel__color">
              <input type="color" v-model="activeElement.widgetShadowColor" rgba />
            </div>
            <input class="panel-input" v-model="activeElement.widgetShadowColor" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>blur</span>
            <div><input class="panel-input" v-model="activeElement.widgetShadowBlur" /> px</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetShadowBlur" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>x</span>
            <div><input class="panel-input" v-model="activeElement.widgetShadowX" /> px</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetShadowX" />
          </div>
        </div>

        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>y</span>
            <div><input class="panel-input" v-model="activeElement.widgetShadowY" /> px</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetShadowY" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label">opacity</div>
          <div class="panel-value">{{ Math.round(activeElement.widgetShadowOpacity * 100) }} %</div>
          <div class="panel-slider-wrap">
            <slider :step="0.01" :max="1" :min="0" v-model="activeElement.widgetShadowOpacity" />
          </div>
        </div>
      </div>
    </transition>

    <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        gradient:
        <select v-model="activeElement.enableGradient" class="panel__select">
          <option value>none</option>
          <option value="true">enable</option>
        </select>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="activeElement.enableGradient">
        <div class="panel-row" flex title="background color">
          <div class="panel-value">
            <div class="panel__color">
              <input type="color" v-model="activeElement.widgetGradientColor" />
            </div>
            <input class="panel-input" v-model="activeElement.widgetGradientColor" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label">opacity</div>
          <div class="panel-value">
            {{ Math.round(activeElement.widgetGradientOpacity * 100) }} %
          </div>
          <div class="panel-slider-wrap">
            <slider :step="0.01" :max="1" :min="0" v-model="activeElement.widgetGradientOpacity" />
          </div>
        </div>
        <div class="panel-row" flex title="background color">
          <div class="panel-value">
            <div class="panel__color">
              <input type="color" v-model="activeElement.widgetGradientColor2" />
            </div>
            <input class="panel-input" v-model="activeElement.widgetGradientColor2" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label">opacity</div>
          <div class="panel-value">
            {{ Math.round(activeElement.widgetGradientOpacity2 * 100) }} %
          </div>
          <div class="panel-slider-wrap">
            <slider :step="0.01" :max="1" :min="0" v-model="activeElement.widgetGradientOpacity2" />
          </div>
        </div>
      </div>
    </transition>
    <div class="panel-row" flex>
      <div class="panel-label panel__menu__buttons">
        <div class="panel__menu__button" @click="levelup()">
          <i class="fa fa-level-up"></i>
        </div>
        <div class="panel__menu__button" @click="leveldown()">
          <i class="fa fa-level-down"></i>
        </div>
        <div class="panel__menu__button" @click="clone()">
          <i class="fa fa-clone"></i>
        </div>
        <div class="panel__menu__button" @click="deleteThis()">
          <b-icon pack=" fa fa-trash" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
export default {
  name: "admedi-figure-style",
  props: ["activeElement"],
  computed: {
    animationNames() {
      var arr = [];
      this.$store.state.animation.map((val) => arr.push(val.name));

      return arr;
    },
  },
  methods: {
    changeAnimation() {
      if (this.activeElement.widgetAnimationClass.indexOf("fadeIn") !== -1) {
        this.activeElement.opacity = 0;
      } else {
        this.activeElement.opacity = 1;
      }
    },
    deleteThis() {
      var self = this;
      swal
        .fire({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            self.$store.commit("delete");
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },
    clone() {
      this.$store.commit("copy");
    },
    levelup() {
      this.$store.commit("levelup");
    },
    leveldown() {
      this.$store.commit("leveldown");
    },
  },
};
</script>
