var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lz-image lz-figure",class:[_vm.playState ? 'anm-' + _vm.val.animationName : '', _vm.val.widgetAnimationClass],style:({
    position: _vm.val.belong === 'page' ? 'absolute' : 'relative',
    width: (_vm.val.width / _vm.w) * 100 + '%',
    height: (_vm.val.height / _vm.h) * 100 + '%',
    left: _vm.val.belong === 'page' ? (_vm.val.left / _vm.w) * 100 + '%' : '0',
    top: _vm.val.belong === 'page' ? (_vm.val.top / _vm.h) * 100 + '%' : '0',
    zIndex: _vm.val.z,
    opacity: _vm.val.opacity,
    fill: _vm.val.bgColor,
    stroke: _vm.val.bgColor,
    borderRadius: _vm.val.radius + 'px',
    animationDelay: _vm.val.widgetAnimationDelay + 's',
    animationDuration: _vm.val.widgetAnimationDuration + 's',
    animationIterationCount: _vm.val.widgetAnimationIterationCount ? 'infinite' : 'initial',
    transform: 'rotate(' + _vm.val.rotate + 'deg)',
  }),attrs:{"data-animation":_vm.val.widgetAnimationClass},on:{"click":_vm.clickFigure}},[_c('div',{class:'figure__' + _vm.val.theFigure,style:({
      borderStyle: _vm.val.borderType,
      borderColor: _vm.val.borderColor,
      borderWidth: _vm.val.borderWidth + 'px',
      boxShadow: _vm.val.enableShadow
        ? _vm.val.widgetShadowX +
          'px ' +
          _vm.val.widgetShadowY +
          'px ' +
          _vm.val.widgetShadowBlur +
          'px ' +
          'rgba(' +
          _vm.hexToRgb(_vm.val.widgetShadowColor).r +
          ',' +
          _vm.hexToRgb(_vm.val.widgetShadowColor).g +
          ',' +
          _vm.hexToRgb(_vm.val.widgetShadowColor).b +
          ',' +
          _vm.val.widgetShadowOpacity +
          ')'
        : 'none',
      background: _vm.val.enableGradient
        ? 'linear-gradient(rgba(' +
          _vm.hexToRgb(_vm.val.widgetGradientColor).r +
          ',' +
          _vm.hexToRgb(_vm.val.widgetGradientColor).g +
          ',' +
          _vm.hexToRgb(_vm.val.widgetGradientColor).b +
          ',' +
          _vm.val.widgetGradientOpacity +
          '), rgba(' +
          _vm.hexToRgb(_vm.val.widgetGradientColor2).r +
          ',' +
          _vm.hexToRgb(_vm.val.widgetGradientColor2).g +
          ',' +
          _vm.hexToRgb(_vm.val.widgetGradientColor2).b +
          ',' +
          _vm.val.widgetGradientOpacity2 +
          ') )'
        : _vm.val.bgColor,
    })})])}
var staticRenderFns = []

export { render, staticRenderFns }