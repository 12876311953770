<template>
  <section>
    <div class="table__users">
      <b-table :data="users">
        <template slot-scope="props">
          <b-table-column field="id" label="ID">{{ props.row.id }}</b-table-column>
          <b-table-column field="display_name" label="Name">{{
            props.row.display_name
          }}</b-table-column>
          <b-table-column field="Email" label="email">{{ props.row.email }}</b-table-column>
          <b-table-column field="Email" label="status">{{ props.row.status }}</b-table-column>
          <b-table-column label="Actions" width="140">
            <template slot="header" slot-scope="{ column }">
              <div>Actions</div>
            </template>
            <b-tooltip label="Edit pass">
              <div class="dib cp" @click="editPassModal(props.row.id)">
                <b-icon pack=" fa fa-key" />
              </div>
            </b-tooltip>
            <b-tooltip label="Edit status">
              <div
                class="dib cp"
                @click="
                  (showStatusModal = true), (editUser = props.row), (newStatus = props.row.status)
                "
              >
                <b-icon pack=" fa fa-user" />
              </div>
            </b-tooltip>
          </b-table-column>
        </template>
      </b-table>

      <b-pagination
        v-if="commercialPagination.total > commercialPagination.perPage"
        @change="changeCommercialPage"
        :total="commercialPagination.total"
        :current.sync="commercialPagination.current"
        :order="commercialPagination.order"
        :size="commercialPagination.size"
        :simple="commercialPagination.isSimple"
        :per-page="commercialPagination.perPage"
      ></b-pagination>
    </div>
    <b-modal :active.sync="showModal" scroll="keep">
      <div class="card tac">
        <div class="card-content">
          <div class="content">
            <h1>New Pass</h1>
            <b-field>
              <b-input v-model="newPass" placeholder="Name" type="text"></b-input>
            </b-field>
            <button class="button is-primary is-medium m0a db" @click="editPass()">Save</button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal :active.sync="showStatusModal" scroll="keep" :width="600">
      <div class="card tac">
        <div class="card-content">
          <div class="content " v-if="editUser">
            <h1>Edit status for {{ editUser.email }}</h1>
            {{ editUser }}
            <b-field label="" class="">
              <b-select placeholder="-" v-model="newStatus">
                <option value="ADMIN">ADMIN</option>
                <option value="INACTIVE">INACTIVE</option>
                <option value="NORMAL">NORMAL</option>
                <option value="SUPERUSER">SUPERUSER</option>
              </b-select>
            </b-field>
            <button class="button is-primary is-medium m0a db" @click="editStatus()">Save</button>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import Toastr from "vue-toastr";
import { users } from "@/mixins";

export default {
  beforeCreate() {
    if (this.$store.state.userIsAdmin !== true) {
      this.$router.push("/app/advertisements");
    }
  },
  components: {
    "vue-toastr": Toastr
  },
  mixins: [users],
  data() {
    return {
      showModal: false,
      showStatusModal: false,
      newStatus: null,
      editUser: null,
      userToEdit: null,
      newPass: null,
      users: [],
      backendUrl: window.backendUrl,
      error: "",
      commercialPagination: {
        total: 5,
        current: 1,
        perPage: 20,
        order: "",
        size: "",
        isSimple: false
      }
    };
  },
  mounted() {
    this.getusers();
  },
  methods: {
    changeCommercialPage() {
      var self = this;
      self.$nextTick(() => self.getusers());
    },
    getusers() {
      var self = this;
      let thisOffset = Math.max(
        0,
        self.commercialPagination.perPage * (self.commercialPagination.current - 1)
      );
      let query = {
        query: `{
        users(limit: ${self.commercialPagination.perPage}, offset: ${thisOffset}){
            items{
                id
                display_name
                email
                status
                
             
            }
                    paging{
          total
          limit
          has_next
          has_prev
        }
        }
      }`
      };

      axios
        .post(global.backendUrl, query)
        .then(function(response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else {
            self.users = response.data.data.users.items;
            self.commercialPagination.total = response.data.data.users.paging.total;
          }
        })
        .catch(function(error) {
          self.$toastr.e("Contact the administrator " + error);
        });
    },
    editPassModal(userId) {
      this.userToEdit = userId;
      this.showModal = true;
    },
    editPass() {
      var self = this;
      const query = {
        query: `
                mutation {user_change_password (id: ${this.userToEdit}, password: "${
          this.newStatus
        }" ){id}}
      `
      };
      axios
        .post(global.backendUrl, query)
        .then(function(response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              console.log(response.data.errors[property].message);
            }
          } else {
            self.$toastr.s("Changed.");
            self.newStatus = null;
            self.showStatusModal = false;
            self.editUser = null;
          }
        })
        .catch(function(error) {});
    },
    editStatus() {
      var self = this;

      const query = {
        query: `
                mutation {user_change_status (id: ${
                  this.editUser.id
                }, status: "${self.getUserStatusName(this.newStatus)}" ){id}}
      `
      };
      axios
        .post(global.backendUrl, query)
        .then(function(response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              console.log(response.data.errors[property].message);
            }
          } else {
            console.log("here");
            self.$toastr.s("Changed.");
            self.userToEdit = null;
            self.showStatusModal = false;
            self.newPass = null;
            self.getusers();
          }
        })
        .catch(function(error) {});
    }
  }
};
</script>

<style lang="scss" scoped>
.mw400 {
  max-width: 400px;
}
.table__users {
  margin: 0 15px;
}
</style>
