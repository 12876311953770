<template>
  <div
    class="txt"
    v-html="val.text"
    :id="'text-' + val.uuid"
    :class="[playState ? 'anm-' + val.animationName : '', val.widgetAnimationClass]"
    :data-animation="val.widgetAnimationClass"
    :contenteditable="editableTxt"
    @blur="(e) => updateText(e, val.uuid)"
    @input="(e) => updateHeight(e)"
    :style="{
      position: val.belong === 'page' ? 'absolute' : 'relative',
      left: val.belong === 'page' ? (val.left / w) * 100 + '%' : '0',
      top: val.belong === 'page' ? (val.top / h) * 100 + '%' : '0',
      width: (val.width / w) * 100 + '%',
      height: (val.height / h) * 100 + '%',
      zIndex: val.z,
      lineHeight: val.lineHeight,
      fontSize: val.fontSize + 'px',
      color: val.color,
      textAlign: val.textAlign,
      fontWeight: val.fontWeight ? 'bold' : 'normal',
      fontFamily: val.fontFamily,
      opacity: val.opacity,
      backgroundColor: val.bgColor,
      borderRadius: val.radius + 'px',
      animationDelay: val.widgetAnimationDelay + 's',
      animationDuration: val.widgetAnimationDuration + 's',
      animationIterationCount: val.widgetAnimationIterationCount ? 'infinite' : 'initial',
      transform: 'rotate(' + val.rotate + 'deg)',
      overflow: 'hidden',
      letterSpacing: val.letterSpacing + 'px',
      textTransform: val.textTransformUppercase ? 'uppercase' : 'none',
      fontStyle: val.fontStyleItalic ? 'italic' : 'normal',
      textDecoration: val.textDecorationUnderline ? 'underline' : 'initial',
      textShadow: val.enableShadow
        ? val.widgetShadowX +
          'px ' +
          val.widgetShadowY +
          'px ' +
          val.widgetShadowBlur +
          'px ' +
          'rgba(' +
          hexToRgb(val.widgetShadowColor).r +
          ',' +
          hexToRgb(val.widgetShadowColor).g +
          ',' +
          hexToRgb(val.widgetShadowColor).b +
          ',' +
          val.widgetShadowOpacity +
          ')'
        : 'none',
    }"
  ></div>
</template>

<script>
import admediTxtStyle from "./style.vue";
const WIDGET_NAME = "admedi-txt";
import { widgetsMethods } from "@/mixins";

export default {
  name: WIDGET_NAME,
  mixins: [widgetsMethods],
  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-type"><polyline points="4 7 4 4 20 4 20 7"></polyline><line x1="9" y1="20" x2="15" y2="20"></line><line x1="12" y1="4" x2="12" y2="20"></line></svg>',
  title: "TEXT",
  panel: admediTxtStyle,
  setting: {
    type: WIDGET_NAME,
    isContainer: false,
    isUpload: false,
    hasGuide: true,
    isChild: true,
    dragable: true,
    resizable: true,
    width: 650,
    height: 100,
    left: 50,
    top: 1,
    z: 0,
    lineHeight: 1,
    fontSize: 72,
    fontWeight: false,
    fontFamily: "Arial",
    fontFamilyPath: null,
    mediaId: null,
    color: "#000000",
    textAlign: "left",
    text: "text",
    href: "",
    belong: "page",
    animationName: "",
    widgetAnimationClass: "",
    widgetAnimationDelay: "0",
    widgetAnimationDuration: "1",
    widgetAnimationIterationCount: false,
    opacity: 1,
    backgroundColor: "",
    radius: 0,
    textDecorationUnderline: false,
    rotate: 0,
    letterSpacing: 1,
    textTransformUppercase: false,
    fontStyleItalic: false,
    bgColor: "transparent",
    widgetShadowColor: "#000000",
    widgetShadowOpacity: 1,
    widgetShadowBlur: 5,
    widgetShadowX: 10,
    widgetShadowY: 5,
  },
  props: ["val", "h", "w", "playState"],
  data() {
    return {
      editableTxt: true,
    };
  },
  methods: {
    updateText(e, uuid) {
      let text = e.target.innerHTML;
      var editor = document.getElementById("text-" + uuid);
      this.$store.commit("updateData", {
        uuid: uuid,
        key: "text",
        value: text,
      });
    },
    updateHeight(e) {
      let p = document.getElementById("text-" + this.val.uuid);
      p.style.height = "auto";
      this.val.height = p.clientHeight;
      // const p = document.getElementById("text-" + this.val.uuid);
      // let p_prime = p.cloneNode(true);
      // console.log(p_prime.clientHeight);
      // p_prime.id = "textcopy-" + this.val.uuid;
      // p_prime.style.height = "auto";
      // let playground = document.getElementById("playground");
      // playground.appendChild(p_prime);
      // console.log(p_prime.clientHeight);
      // this.val.height = p_prime.clientHeight;
    },
  },

  mounted() {
    var self = this;
    var editor = document.getElementById("text-" + self.val.uuid);
    editor.focus();
    editor.addEventListener("paste", function (e) {
      // cancel paste
      e.preventDefault();
      // get text representation of clipboard
      var text = (e.originalEvent || e).clipboardData.getData("text/plain");
      // insert text manually
      document.execCommand("insertHTML", false, text);
    });
    document.getElementById("text-" + self.val.uuid).addEventListener("keypress", function (e) {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        document.execCommand("insertLineBreak");
      }
    });
  },
};
</script>

<style scoped>
.txt {
  outline: none;
  overflow: hidden;
  font-size: 28px;

  /* padding: 2.5% 2.5% 4% 2.5%; */
  box-sizing: content-box;
}
</style>
