<template>
  <header class="navbar is-bold" v-if="$store.state.userEmail">
    <div class="container">
      <div v-if="$store.state.userIsAdmin" class="linksNav">
        <router-link class="navbar-item" :to="'/regiser-user'">register user</router-link>
        <router-link class="navbar-item" :to="'/users'">users</router-link>
        <router-link class="navbar-item" :to="'/advertisements'">ads</router-link>
        <router-link class="navbar-item" :to="'/devices'">devices</router-link>
        <router-link class="navbar-item" :to="{ name: 'payments' }">payments</router-link>
      </div>
      <div v-else class="linksNav">
        <router-link class="navbar-item" :to="'/advertisements'">advertisements</router-link>

        <router-link class="navbar-item" :to="'/devices'">devices</router-link>
      </div>
      <div id="navbarDropdown" class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item" v-if="$store.state.userEmail">
            HELLO {{ $store.state.userEmail }}
            <router-link class="navbar-item" :to="{ name: 'admMyAccount' }">My account</router-link>
            <div class="logout cp p-l-5" @click="logout">LOGOUT</div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";
import Toastr from "vue-toastr";
export default {
  components: {
    "vue-toastr": Toastr
  },
  methods: {
    goIndex() {
      this.$router.push("/");
    },
    logout() {
      var self = this;
      self.$store.dispatch("setUserEmail", "");
      self.$store.dispatch("setUserId", "");
      self.$store.dispatch("setUserIsAdmin", "");
      localStorage.removeItem("ACCESS_TOKEN_KEY");
      axios.defaults.headers.common["Authorization"] = "";
      self.$router.push("/");
    }
  }
};
</script>
