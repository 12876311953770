<template>
  <b-modal :active.sync="showModal" :width="640" scroll="keep">
    <button
      type="button"
      class="modal-close is-large modal-content__close"
      @click="showModal = false"
    ></button>
    <div class="card tac">
      <div class="card-content">
        <div class="content">
          <h1 class="title is-size-4 m-b-8">MUSIC</h1>
          <div class="drophere">
            <b-field>
              <b-upload
                class="drag-files"
                @input="addImg"
                v-model="dropFiles"
                multiple
                drag-drop
                :accept="'.mp3'"
              >
                <div class="has-text-centered">
                  <p>Drag files here or click to add</p>
                </div>
              </b-upload>
            </b-field>
            <div class="tags">
              <div v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
                {{ file.name }}
                <div class="icon">
                  <i class="fa fa-trash cp" @click="deleteDropFile(index)"></i>
                </div>
              </div>
            </div>
          </div>
          <b-table
            id="music-table"
            class="m-t-5"
            :data="files"
            :checked-rows.sync="checkedRows"
            :custom-is-checked="(a, b) => a.id === b.id"
            checkable
            :checkbox-position="checkboxPosition"
          >
            <template slot-scope="props">
              <b-table-column field="id" label="c">
                <audio controls :id="'music' + props.row.filename">
                  <source
                    :id="'music-source-' + props.row.filename"
                    :src="backendUrl + props.row.path + props.row.filename"
                    type="audio/mpeg"
                  />
                </audio>

                <!-- <div @click="playMusic(props.row.path + props.row.name)" class="cp">
                  <i class="fa fa-play"></i>
                </div> -->
              </b-table-column>
              <b-table-column field="name" label="a">
                {{ props.row.name }}
              </b-table-column>

              <b-table-column field="path" label="time">
                <div @click="deleteImage(props.row.id)"><i class="fa fa-times"></i></div
              ></b-table-column>
            </template>
          </b-table>

          <b-pagination
            v-if="filesPagination.total > filesPagination.limit"
            @change="changeFilesPage"
            :total="filesPagination.total"
            :current.sync="filesPagination.page"
            :simple="false"
            :rounded="false"
            icon-pack="fa"
            :per-page="filesPagination.limit"
          ></b-pagination>
          <div class="modal-content__buttons">
            <button class="button is-outlined is-medium" @click="checkRows()">Select all</button>
            <button class="button is-outlined is-medium" @click="checkedRows = []">
              Unselect all
            </button>
            <button class="button is-primary is-medium" @click="saveAd()">SAVE</button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import swal from "sweetalert2";
import obj2arg from "graphql-obj2arg";

export default {
  name: "addMusicModal",
  props: ["commercialId"],
  data() {
    return {
      showModal: false,

      files: [],
      filesPagination: {
        total: 0,
        limit: 50,
        page: 1,
      },
      showProgress: false,
      progressValue: 0,
      dropFiles: [],
      contentJson: null,
      musicList: [],
      selectedArray: [],
      checkboxPosition: "left",
      checkedRows: [],

      audio: null,
      backendUrl: global.pageUrl,
    };
  },
  mounted() {},
  methods: {
    changeFilesPage: function (page) {
      var self = this;
      self.filesPagination.page = page;
      self.$nextTick(() => this.getImages());
    },
    show() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = true;
        self.checkedRows = [];
        self.files = [];
        self.getImages(true);
      });
    },
    hide() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = false;
      });
    },
    showPlayers() {
      this.$emit("showAvailablePlayers");
    },
    addImg() {
      var self = this;
      self.showProgress = true;
      self.progressValue = 0;
      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          self.progressValue = percentCompleted;
        },
      };
      if (!self.dropFiles[0]) {
        self.$toastr.e("Uzupełnij.");
        return;
      }

      let iterator = 0;

      for (var i = 0; i < self.dropFiles.length; i++) {
        var FileSize = self.dropFiles[i].size / 1024 / 1024; // in MiB

        if (FileSize > 256) {
          console.log("skipping big file");
          self.$toastr.e(
            "File " +
              self.dropFiles[i].name +
              " is to big " +
              Math.round(FileSize * 100) / 100 +
              "MB. Max is 256MB. File can't be added."
          );
          continue;
        }

        let queryArgs = {
          name: self.dropFiles[i].name,
          file: "file",
          categories_ids: [],
          uploaded_type: "Enum::MUSIC",
        };
        let a = new FormData();
        a.append(
          "mutation",
          `{ upload_file(
            ${obj2arg(queryArgs, { noOuterBraces: true })}
           ) { id }}`
        );
        a.append("file", self.dropFiles[i]);
        axios
          .post(global.backendUrl, a, config)
          .then(function (response) {
            if (response.data.data.upload_file) {
              self.getImages();
              self.$toastr.s("File has been added.");
              iterator++;
              if (iterator == self.dropFiles.length) {
                self.dropFiles = [];
              }
            }
            self.showProgress = false;
          })
          .catch(function (error) {
            self.$toastr.e("Contact the administrator " + error.message);
            self.dropFiles = [];
            self.showProgress = false;
          });
      }
    },
    deleteImage(imageId) {
      var self = this;
      swal
        .fire({
          title:
            "Are you sure you want to delete this file? Note, if the file is used on another commercial, the file will not be available.",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            const query = {
              mutation: `  {
            remove_uploaded_file(id: "${imageId}" ){
              id
            }
            }
            `,
            };
            axios
              .post(global.backendUrl, query)
              .then(function (response) {
                if (response.data.data.remove_uploaded_file) {
                  self.getImages();
                  self.$toastr.s("File deleted.");
                }
              })
              .catch(function (error) {
                self.$toastr.e("Contact the administrator " + error.message);
              });
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    getImages(getAds) {
      var self = this;
      self.files = [];
      let queryArgs = {
        limit: self.filesPagination.limit,
        offset: (self.filesPagination.page - 1) * self.filesPagination.limit,
        uploaded_types: ["Enum::MUSIC"],
      };
      const query = {
        query: `  {
          logged_user{
            is_admin
            uploaded_files( ${obj2arg(queryArgs, { noOuterBraces: true })} ){
              paging{
                total
                limit
                has_next
                has_prev
              }
              items{
                id
                filename
                name
                original_filename
                width
                height
                path
                duration
                created_at
                is_added_by_admin
              }
            }
          }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.data.logged_user.uploaded_files) {
            Vue.nextTick(function () {
              self.files = response.data.data.logged_user.uploaded_files.items;

              self.filesPagination.total =
                response.data.data.logged_user.uploaded_files.paging.total;
            });
            if (getAds) {
              self.getAd();
            }
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    getAd() {
      var self = this;
      const query = {
        query: `  {
        commercial( id: ${this.commercialId} ){
          id
          content_json
        }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.data.commercial) {
            self.contentJson = JSON.parse(response.data.data.commercial.content_json);

            self.$nextTick(() => {
              self.checkedRows = self.contentJson.music;
            });
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    saveAd() {
      var self = this;
      self.contentJson.music = self.checkedRows;
      let contentJsonString = JSON.stringify(self.contentJson);

      let adToSave = {};
      adToSave.id = this.commercialId;
      adToSave.content_json = contentJsonString;

      const query = {
        mutation: `  {
        commercial( ${obj2arg(adToSave, { noOuterBraces: true })}   ){
          id
        }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.data.commercial) {
            self.$emit("markChanged");
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    checkRows() {
      document.getElementById("music-table").getElementsByClassName("b-checkbox")[0].click();
    },
    // playMusic(path) {
    //   this.audio = new Audio(backendUrl + path);
    //   this.audio.play();
    // },
  },
};
</script>