<template>
  <section class="hero">
    <vue-toastr ref="toastr"></vue-toastr>
    <div class="hero-body">
      <div class="container has-text-centered max-width-400">
        <img src="/static/img/logo.png" alt="Image" class="inCointainerLogo" />

        <h2 class="title">Reset password</h2>
        <div class="field">
          <p class="control has-icons-left">
            <input class="input" type="password" placeholder="Password" v-model="password" />
            <span class="icon is-small is-left">
              <i class="fa fa-lock"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input
              class="input"
              type="password"
              placeholder="Repeat password"
              v-model="password2"
            />
            <span class="icon is-small is-left">
              <i class="fa fa-lock"></i>
            </span>
          </p>
        </div>

        <div class="field">
          <p class="control">
            <button class="button is-success" @click="submit()">Reset</button>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import Toastr from "vue-toastr";

export default {
  props: ["code"],
  components: {
    "vue-toastr": Toastr
  },
  data() {
    return {
      backendUrl: window.backendUrl,
      backendLoginUrl: window.backendLoginUrl,

      password: "",

      password2: "",
      error: ""
    };
  },
  methods: {
    submit: function() {
      var self = this;

      if (self.password === "") {
        self.$toastr.e("Fill password.");
        return;
      }
      if (self.password.length < 6) {
        self.$toastr.e("Password should be at least 6 characters.");
        return;
      }
      if (self.password !== self.password2) {
        self.$toastr.e("Passwords does not match.");
        return;
      }

      const query = {
        query: `
            mutation {user_password_reset ( new_password: "${self.password}" code: "${
          self.code
        }")} `
      };
      axios
        .post(global.backendLoginUrl, query)
        .then(function(response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else if (
            response.data &&
            response.data.data &&
            response.data.data.user_password_reset
          ) {
            self.$toastr.s("Password changed.");
            self.$router.push({ name: "admLogin" });
          }
        })
        .catch(function(error) {
          self.$toastr.e("Contact the administrator " + error);
        });
    }
  }
};
</script>
