import Vue from 'vue'
import Store from '../plugins/store'

import state from './state'
import actions from './actions'
import mutations from './mutation'

Vue.use(Store)
const getters = {
  axiosLoading: state => state.axiosLoading,
  errorsCatch: state => state.errorsCatch,
  successCatch: state => state.successCatch
}
export default new Store({
  state,
  actions,
  getters,
  mutations
})
