var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.val.url,staticClass:"admedi-bg",class:['apply-font'],style:({
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    zIndex: '-99',
    backgroundColor: _vm.val.backgroundColor,
  }),attrs:{"data-animation":_vm.val.widgetAnimationClass,"id":'bg-' + _vm.val.uuid}},[(_vm.val.mediaType === 'thisIsImg')?_c('div',{class:[_vm.playState ? 'anm-' + _vm.val.animationName : '', _vm.val.widgetAnimationClass],style:({
      animationDuration: _vm.val.widgetAnimationDuration + 's',
      widgetAnimationDelay: _vm.val.widgetAnimationDuration + 's',
      animationIterationCount: _vm.val.widgetAnimationIterationCount ? 'infinite' : 'initial',
      opacity: _vm.val.opacity,
      backgroundColor: _vm.val.backgroundColor,
      backgroundSize: _vm.val.widgetBgSize,
      backgroundPosition: _vm.val.widgetBgPositionX + '% ' + _vm.val.widgetBgPositionY + '%',
      backgroundRepeat: _vm.val.widgetBgSize === 'contain' ? 'no-repeat' : 'initial',
      backgroundImage: 'url(' + _vm.returnUrl + ')',
    })}):_vm._e(),(_vm.val.mediaType === 'thisIsVid')?_c('video',{class:[_vm.playState ? 'anm-' + _vm.val.animationName : '', _vm.val.widgetAnimationClass, 'backVideo'],style:({
      backgroundImage: 'url(' + _vm.videoImage + ')',
      backgroundSize: 'cover',
      animationDuration: _vm.val.widgetAnimationDuration + 's',
      widgetAnimationDelay: _vm.val.widgetAnimationDuration + 's',
      animationIterationCount: _vm.val.widgetAnimationIterationCount ? 'infinite' : 'initial',
      width: '100%',
      height: '100%',
      objectFit: _vm.val.widgetBgSize,
      objectPosition: _vm.val.widgetBgPositionX + '% ' + _vm.val.widgetBgPositionY + '%',
    }),on:{"click":_vm.clickVideo}},[_c('source',{attrs:{"src":_vm.returnUrl,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }