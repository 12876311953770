<template>
  <div
    class="admedi-bg"
    :key="val.url"
    :class="['apply-font']"
    :data-animation="val.widgetAnimationClass"
    :id="'bg-' + val.uuid"
    :style="{
      width: '100%',
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
      zIndex: '-99',
      backgroundColor: val.backgroundColor,
    }"
  >
    <div
      v-if="val.mediaType === 'thisIsImg'"
      :class="[playState ? 'anm-' + val.animationName : '', val.widgetAnimationClass]"
      :style="{
        animationDuration: val.widgetAnimationDuration + 's',
        widgetAnimationDelay: val.widgetAnimationDuration + 's',
        animationIterationCount: val.widgetAnimationIterationCount ? 'infinite' : 'initial',
        opacity: val.opacity,
        backgroundColor: val.backgroundColor,
        backgroundSize: val.widgetBgSize,
        backgroundPosition: val.widgetBgPositionX + '% ' + val.widgetBgPositionY + '%',
        backgroundRepeat: val.widgetBgSize === 'contain' ? 'no-repeat' : 'initial',
        backgroundImage: 'url(' + returnUrl + ')',
      }"
    ></div>

    <video
      v-if="val.mediaType === 'thisIsVid'"
      v-on:click="clickVideo"
      :class="[playState ? 'anm-' + val.animationName : '', val.widgetAnimationClass, 'backVideo']"
      :style="{
        backgroundImage: 'url(' + videoImage + ')',
        backgroundSize: 'cover',
        animationDuration: val.widgetAnimationDuration + 's',
        widgetAnimationDelay: val.widgetAnimationDuration + 's',
        animationIterationCount: val.widgetAnimationIterationCount ? 'infinite' : 'initial',
        width: '100%',
        height: '100%',
        objectFit: val.widgetBgSize,
        objectPosition: val.widgetBgPositionX + '% ' + val.widgetBgPositionY + '%',
      }"
    >
      <source :src="returnUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
//to było w video
// objectFit: val.objectFit,
// objectPosition: val.objectPosition}"
import admediBgStyle from "./style.vue";
const WIDGET_NAME = "admedi-bg";
export default {
  name: WIDGET_NAME,
  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smartphone"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12" y2="18"></line></svg>',
  title: "BACKGROUND",
  panel: admediBgStyle,
  setting: {
    type: WIDGET_NAME,
    isBackground: true,
    isContainer: false,
    hasGuide: false,
    isUpload: false,
    isChild: false,
    dragable: false,
    resizable: false,
    url: null,
    mediaId: null,
    href: "",
    belong: "page",
    opacity: 1,
    widgetAnimationClass: "",
    widgetAnimationDelay: "0",
    widgetAnimationDuration: "1",
    widgetAnimationIterationCount: false,
    widgetBgPositionX: "50",
    widgetBgPositionY: "50",
    widgetBgSize: "cover",
    objectFit: "none",
    objectPosition: "initial",
    backgroundColor: "transparent",
  },
  computed: {
    videoImage() {
      let imageUrl = "";
      if (this.val && this.val.url) {
        let path = this.val.url.substring(0, this.val.url.lastIndexOf("/")) + "/videos";
        let filename = this.val.url.substring(
          this.val.url.lastIndexOf("/"),
          this.val.url.length - 3
        );
        imageUrl = path + filename + "jpg";
      }
      return imageUrl;
    },
    returnUrl: function () {
      return process.env.NODE_ENV === "development" ? "http://admedi" + this.val.url : this.val.url;
    },
  },
  props: [
    "playState",
    "val", // Zdjęcie,
  ],
  methods: {
    clickVideo(event) {
      this.$store.commit("select", {
        uuid: this.val.uuid || -1,
      });
    },
  },
};
</script>
