export default {
  setUserEmail: ({ state }, name) => {
    state.userEmail = name
  },
  setUserId: ({ state }, name) => {
    state.userId = name
  },
  setUserIsAdmin: ({ state }, name) => {
    state.userIsAdmin = name
  },
  setUserRole: ({ state }, name) => {
    state.userRole = name
  },
  addFigure({ state, commit, store }, figure) {
    let payload = []
    payload[0] = {
      height: img.height,
      mediaType: item.mediaType,
      figure: figure,
      url: item.imgUrl,
      width: img.width
    }
    commit('addWidget', { data: payload, item })
  },
  addWidget({ state, commit, store }, item) {
    if (item.name === 'admedi-bg') {
      for (var i = 0; i < state.widgets.length; i++) {
        if (state.widgets[i].type === 'admedi-bg') {
          commit('select', {
            uuid: state.widgets[i].uuid
          })
          return
        }
      }
    }

    if (item.setting.isUpload) {
      if (item.mediaType === 'thisIsImg') {
        let img = new Image();

        img.src = item.imgUrl
        if (process.env.NODE_ENV === 'development') {
          img.src = img.src.replace(":8080", "");
        }
        img.onload = function () {
          let payload = []
          payload[0] = {
            mediaType: item.mediaType,
            url: item.imgUrl,
            width: img.width,
            height: img.height
          }
          commit('addWidget', { data: payload, item })
        }
      } else if (item.mediaType === 'thisIsVid') {
        //TEMP
        let payload = []
        payload[0] = {
          mediaType: item.mediaType,
          url: item.imgUrl,
          width: item.width,
          height: item.height,
        }
        commit('addWidget', { data: payload, item })
      }

    } else if (item.setting.isFigure) {
      let payload = []
      payload[0] = {
        height: 120,
        width: 120
      }
      commit('addWidget', { data: payload, item })
    } else {
      // text 
      commit('addWidget', { item })
      commit('select', {
        uuid: state.widgets[state.widgets.length - 1].uuid
      })
    }
  },
  save({ state, store }) {
    store.$emit('save', state)
  },
  doUndoRedoWidgets({ state, commit }, historyWidgets) {
    commit('undoRedoWidgets', { historyWidgets })
  }
}
