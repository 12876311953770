<template>
  <div id="app">
    <div v-if="axiosLoading" id="axiosLoading">
      <div class="lds-ripple">
        <div />
        <div />
      </div>
    </div>
    <vue-toastr ref="toastr" />
    <nav-bar />
    <main-content />
  </div>
</template>

<script>
import { NavBar, MainContent } from "./components/layout/index";
export default {
  components: {
    "nav-bar": NavBar,
    "main-content": MainContent
  },
  computed: {
    axiosLoading() {
      return this.$store.state.axiosLoading;
    },
    errorsCatch() {
      return this.$store.state.errorsCatch;
    },
    successCatch() {
      return this.$store.state.successCatch;
    }
  },
  watch: {
    sessionExpired(newStatus) {
      if (newStatus === "true") {
        this.$store.dispatch("UsersModule/LOGOUT_USER");
      }
    },
    errorsCatch(newError) {
      newError.slice(0, -5);
      this.$toastr.e(newError.slice(0, -13));
    },
    successCatch(newSuccess) {
      this.$toastr.s(newSuccess.slice(0, -13));
    }
  }
};
</script>