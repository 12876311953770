<template>
  <b-modal :active.sync="showModal" :width="640" scroll="keep">
    <button
      type="button"
      class="modal-close is-large modal-content__close"
      @click="showModal = false"
    ></button>
    <div class="card tac">
      <div class="card-content">
        <div class="content">
          <h1 class="title is-size-4">ADD NEW AD</h1>

          <b-field>
            <b-input
              v-model="newCommercial.display_name"
              placeholder="name your new ad"
              class="deviceID-input deviceID-input--long deviceID-input--centered"
            ></b-input>
          </b-field>

          <div class="set-orientation">
            <b-tooltip label="1080x1920" position="is-top">
              <div
                class="set-orientation__link"
                @click="(newCommercial.is_portrait = true), (addCommercialOrientation = true)"
                v-bind:class="[
                  newCommercial.is_portrait && addCommercialOrientation ? 'active' : '',
                ]"
              >
                <div class="set-orientation__link--portrait"></div>
                portrait
              </div>
            </b-tooltip>

            <p>
              choose
              <br />orientation
            </p>
            <b-tooltip label="1920x1080" position="is-top">
              <div
                class="set-orientation__link"
                @click="(newCommercial.is_portrait = false), (addCommercialOrientation = true)"
                v-bind:class="[
                  !newCommercial.is_portrait && addCommercialOrientation ? 'active' : '',
                ]"
              >
                <div class="set-orientation__link--horizontal"></div>
                horizontal
              </div>
            </b-tooltip>
          </div>

          <button class="button is-primary is-medium m0a db" @click="addAd()">CREATE</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Vue from "vue";

export default {
  name: "addSlide",

  data() {
    return {
      showModal: false,
      newCommercial: {
        name: "",
        is_portrait: true,
      },
      addCommercialOrientation: false,
    };
  },
  mounted() {},
  methods: {
    show() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = true;
        self.newCommercial = {
          name: "",
          is_portrait: true,
        };
        self.addCommercialOrientation = false;
      });
    },
    hide() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = false;
      });
    },
    addAd() {
      var self = this;
      if (!self.newCommercial.display_name) {
        self.$toastr.e("Fill ad name.");
        return;
      } else if (!self.addCommercialOrientation) {
        self.$toastr.e("Choose oriantation.");
        return;
      }
      this.$emit("addAd", self.newCommercial, false, false);
    },
  },
};
</script>