import Vue from 'vue'
// import router from './../router'
import axios from "axios";
const generate = require('nanoid/generate')
import widget from "@/plugins/widget";
import obj2arg from "graphql-obj2arg";

export default {
  currentSlider(state, currentSlider) {
    state.currentSlider = currentSlider
  },
  activeAdsSlidesSet(state, activeAdsSlidesId) {
    state.activeAdsSlidesId = activeAdsSlidesId
  },
  activeAdsRangeSet(state, activeAdsSlidesRange) {
    state.activeAdsSlidesRange = activeAdsSlidesRange
  },
  setCurrentSliderState(state, slider) {
    state.page = slider.page
    state.widgets = slider.widgets
  },
  axiosLoading(state, axiosStatus) {
    state.axiosLoading = axiosStatus
  },
  errorsCatch(state, newMessage) {
    let timestamp = new Date().getTime()
    state.errorsCatch = newMessage + timestamp
  },
  successCatch(state, newMessage) {
    state.successCatch = newMessage + timestamp
  },
  select(state, payload) {
    state.uuid = payload.uuid
    if (payload.uuid === -1) {
      state.activeElement = state.page
      state.type = 'page'
    } else {
      let widget = state.widgets.find(w => w.uuid === payload.uuid)
      state.activeElement = widget
      state.type = widget.type
    }
  },

  initmove(state, payload) {
    state.startX = payload.startX
    state.startY = payload.startY
    state.originX = payload.originX
    state.originY = payload.originY
    state.originAdditional = payload.originAdditional
    state.originAdditional2 = payload.originAdditional2
    state.moving = true
  },

  stopmove(state) {
    state.moving = false
  },

  move(state, payload) {
    var target = state.activeElement
    var dx = payload.x - state.startX
    var dy = payload.y - state.startY
    var left = state.originX + Math.floor(dx * 100 / state.zoom)
    var top = state.originY + Math.floor(dy * 100 / state.zoom)

    //target.left = left > 0 ? left : 0
    //target.top = top > 0 ? top : 0
    target.left = left
    target.top = top
  },

  resize(state, payload) {
    var hwratio = state.activeElement.height / state.activeElement.width
    var whratio = state.activeElement.width / state.activeElement.height
    var dx = payload.x - state.startX
    var dy = payload.y - state.startY
    var value
    if (payload.type === 'cornerBottom') {
      value = state.originX + Math.floor(dx * 100 / state.zoom)
      state.activeElement.width = value > 10 ? value : 10

      state.activeElement.height = hwratio * state.activeElement.width
      return
    }

    if (payload.type === 'cornerLeft') {

      var left = state.originX + Math.floor(dx * 100 / state.zoom)
      var width = state.originY - Math.floor(dx * 100 / state.zoom)
      state.activeElement.left = left > 0 ? left : 0
      state.activeElement.width = width > 10 ? width : 10


      var top = state.originAdditional + Math.floor(dy * 100 / state.zoom)
      var height = state.originAdditional2 - Math.floor(dy * 100 / state.zoom)
      state.activeElement.top = top > 0 ? top : 0
      state.activeElement.height = height > 10 ? height : 10
      return
    }
    if (payload.type === 'cornerTop') {

      var top = state.originY + Math.floor(dy * 100 / state.zoom)
      var height = state.originAdditional - Math.floor(dy * 100 / state.zoom)
      state.activeElement.top = top > 0 ? top : 0
      state.activeElement.height = height > 10 ? height : 10


      // var width = state.originX + Math.floor(dx * 100 / state.zoom)
      state.activeElement.width = whratio * state.activeElement.height

      return
    }

    if (payload.type === 'right') {
      value = state.originX + Math.floor(dx * 100 / state.zoom)
      state.activeElement.width = value > 10 ? value : 10
      return
    }

    if (payload.type === 'down') {
      value = state.originX + Math.floor(dy * 100 / state.zoom)
      state.activeElement.height = value > 10 ? value : 10
      return
    }

    if (payload.type === 'left') {
      var left = state.originX + Math.floor(dx * 100 / state.zoom)
      var width = state.originY - Math.floor(dx * 100 / state.zoom)
      state.activeElement.left = left > 0 ? left : 0
      state.activeElement.width = width > 10 ? width : 10
      return
    }

    if (payload.type === 'up') {
      var top = state.originX + Math.floor(dy * 100 / state.zoom)
      var height = state.originY - Math.floor(dy * 100 / state.zoom)
      state.activeElement.top = top > 0 ? top : 0
      state.activeElement.height = height > 10 ? height : 10
      return
    }
  },
  ctrlC(state){
    if (state.type !== 'page') {
      state.ctrlCopy = Object.assign({}, state.activeElement)
    }
  },
  ctrlV(state){
    if(state.ctrlCopy){
    const newItem = Object.assign({}, state.ctrlCopy, { top: state.ctrlCopy.top + 15, left: state.ctrlCopy.left + 15, uuid: generate('1234567890abcdef', 10) })
    state.widgets.push(newItem)
    }
  },
  copy(state, payload) {
    if (state.type !== 'page') {
      var copy = Object.assign({}, state.activeElement, { top: state.activeElement.top + 15, left: state.activeElement.left + 15, uuid: generate('1234567890abcdef', 10) })

      if (state.activeElement.isContainer) {
        var name = state.activeElement.name
        if (name) {
          var copyName = name.split('-')[0] + '-' + state.counter
          copy.name = copyName

          for (var i = 0, len = state.widgets.length; i < len; i++) {
            if (state.widgets[i].belong === name) {
              state.widgets.push(
                Object.assign({}, state.widgets[i], { belong: copyName })
              )
            }
          }

          state.counter += 1
        }
      }

      state.widgets.push(copy)
    }
  },
  levelup(state) {
    state.activeElement.z++
  },
  leveldown(state) {
    state.activeElement.z--
  },
  copyContainer(state, payload) {
    const newUuid = generate('1234567890abcdef', 10)
    var copy = Object.assign({}, payload, { top: state.top, uuid: newUuid })

    copy.additionalCSS = copy.additionalCSS.replace(new RegExp(payload.uuid, 'g'), newUuid)
    copy.additionalHTML = copy.additionalHTML.replace(new RegExp(payload.uuid, 'g'), newUuid)
    copy.additionalJS = copy.additionalJS.replace(new RegExp(payload.uuid, 'g'), newUuid)
    delete copy.children
    delete copy.blob
    var name = payload.name
    if (name) {
      var copyName = name.split('-')[0] + '-' + state.counter
      copy.name = copyName
      for (var i = 0, len = payload.children.length; i < len; i++) {
        state.widgets.push(
          Object.assign({}, payload.children[i], { belong: copyName })
        )
      }
      state.counter += 1
    }
    state.widgets.push(copy)
  },
  saveAsFigure(state, blob) {
    if (state.type !== 'page') {
      var copy = Object.assign({}, state.activeElement, { top: state.top })

      if (state.activeElement.isContainer) {
        var name = state.activeElement.name
        if (name) {
          var copyName = name.split('-')[0] + '-' + state.counter
          copy.name = copyName
          copy.children = []
          copy.blob = blob

          for (var i = 0, len = state.widgets.length; i < len; i++) {
            if (state.widgets[i].belong === name) {
              copy.children.push(
                Object.assign({}, state.widgets[i], { belong: copyName })
              )
            }
          }

          state.counter += 1
        }
      }

      const query = {
        query: `  {
        logged_user{
            id
            content_json
          }
      }
      `
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              console.log(response.data.errors[property].message);
            }
          } else {
            let content_json = response.data.data.logged_user.content_json
            if (content_json) {
              // content_json = decodeURIComponent(content_json)
              content_json = JSON.parse(content_json)
            } else {
              content_json = {}
              content_json.widgets = []
            }
            content_json.widgets.push(copy)
            let contentJsonString = JSON.stringify(content_json);
            // contentJsonString = encodeURIComponent(contentJsonString);
            let userToSave = {
              id: response.data.data.logged_user.id,
              content_json: contentJsonString
            }

            const query = {
              query: `
                mutation {user (id: ${response.data.data.logged_user.id}, content_json: "${contentJsonString}" ){id}}`
            }
            axios.post(global.backendUrl, query)
              .then(function (response) {
                if (response.data.errors) {
                  for (var property in response.data.errors) {
                    console.log(response.data.errors[property].message)
                  }
                } else {
                  console.log('saved-user')
                }
              })
              .catch(function (error) {
                console.log(error)
              })
          }
        })
        .catch(function (error) {
          console.log("Contact the administrator " + error.message);
        });
    }
  },

  updateSrollTop(state, top) {
    state.top = top
  },

  zoom(state, val) {
    state.zoom = val
  },

  initActive(state) {
    var thereIsBackground = false
    for (var i = 0; i < state.widgets.length; i++) {
      if (state.widgets[i].type === 'admedi-bg') {
        state.activeElement = state.widgets[i]
        thereIsBackground = true
      }
    }
    if (!thereIsBackground) {
      var item = widget.getWidgetsAdmedi()['admedi-bg']
      let def = { top: state.top, uuid: generate('1234567890abcdef', 10) }
      let setting = JSON.parse(JSON.stringify(item.setting))
      state.widgets.push(Object.assign(setting, def))
      for (var i = 0; i < state.widgets.length; i++) {
        if (state.widgets[i].type === 'admedi-bg') {
          state.activeElement = state.widgets[i]
        }
      }
    }
    // state.activeElement = state.page
  },

  initActivePage(state) {
    state.activeElement = state.page

  },

  delete(state) {
    var type = state.type
    if (type === 'page') return

    if (state.activeElement.isContainer) {
      var name = state.activeElement.name

      for (var i = 0; i < state.widgets.length; i++) {
        if (state.widgets[i].belong === name) {
          state.widgets.splice(i, 1)
        }
      }
    }

    var indexToDelete = state.widgets.findIndex(function (o) {
      return o.uuid === state.activeElement.uuid;
    })
    if (indexToDelete !== -1) state.widgets.splice(indexToDelete, 1);

    state.activeElement = state.page
    state.uuid = -1
  },

  addWidget(state, { data: data = null, item }) {
    // let def = { top: state.top, uuid: generate('1234567890abcdef', 10) }
    let def = { uuid: generate('1234567890abcdef', 10) }
    if(item.name !== 'admedi-txt'){
      def.top = state.top
    }
    let setting = JSON.parse(JSON.stringify(item.setting))
    if (setting.isContainer) {
      setting.name = def.uuid
    }
    if (setting.isUpload) {
      setting.mediaId = item.mediaId
    }

    if (data) {
      data.forEach(function (val) {
        state.widgets.push(Object.assign(setting, val, def))
      })
    } else {
      state.widgets.push(Object.assign(setting, def))
    }
    //focus recently added widget

    let widget = state.widgets.find(w => w.uuid === def.uuid)
    state.activeElement = widget
    state.type = widget.type
  },

  setFont(state, payload) {
    console.log(payload)
    let fontName = payload.original_filename.replace('.ttf', '').replace('.TTF', '')
    state.activeElement.fontFamily = payload.original_filename.replace('.ttf', '').replace('.TTF', '')
    state.activeElement.fontFamilyPath = payload.path
    state.activeElement.mediaId = payload.fileId
    if (!document.getElementById(fontName)) { //append font only once
      var head = document.getElementsByTagName('head')[0];
      var style = document.createElement('style');
      style.id = state.activeElement.fontFamily;
      var fontDeclaration = "@font-face {font-family: '" + fontName + "'; src: url('" + state.activeElement.fontFamilyPath + "');}"
      head.appendChild(style);
      style.type = 'text/css';
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = fontDeclaration;
      } else {
        style.appendChild(document.createTextNode(fontDeclaration));
      }
    }

  },
  replaceImage(state, payload) {
    if (payload.mediaType === 'thisIsImg') {
      let img = new Image();
      img.src = payload.path

      if (process.env.NODE_ENV !== 'production') {
        img.src = img.src.replace('http://admedi:8080', 'http://admedi')
      }

      img.onload = function () {
        setTimeout(() => {
          const newh = (parseInt(state.activeElement.width) * parseInt(img.height)) / (parseInt(img.width))


          state.activeElement.height = newh
          state.activeElement.mediaType = payload.mediaType
          state.activeElement.url = img.src
          state.activeElement.mediaId = payload.fileId

          state.openUploader = false
          // state.activeElement = state.page
        }, 0)
      }
    } else if (payload.mediaType === 'thisIsVid') {
      console.log('thisIsVid')
      var vid = document.getElementById(payload.fileId + "vid");
      state.activeElement.height = vid.videoHeight;
      state.activeElement.width = vid.videoWidth;
      state.activeElement.mediaType = payload.mediaType
      state.activeElement.url = payload.path
      state.activeElement.mediaId = payload.fileId
      state.openUploader = false
      // state.activeElement = state.page
    }
  },
  doUploader(state, OpenTriggerer) {
    state.openUploader = OpenTriggerer
  },
  setUploaderType(state, item) {
    state.currentUploaderType = item
  },
  uploadItem(state, item) {
    state.uploadItem = item
  },
  setImageCategory(state, item) {
    state.currentImageCategory = item
  },

  addContainerBackPic(state, payload) {
    state.activeElement.backPic = payload[0].url
    state.activeElement.backPicUrl = payload[0].src
    state.activeElement.width = payload[0].width
    state.activeElement.height = payload[0].height
  },

  addBackPic(state, payload) {
    state.activeElement.backPic = payload[0].url
    state.activeElement.backPicUrl = payload[0].src
  },

  addAnimation(state) {
    state.animation.push({
      name: '',
      duration: 3,
      delay: 0,
      iteration: 1,
      timing: 'linear',
      direction: 'normal',
      fill: 'none',
      keyframes: [
        {
          stop: 0,
          css: ''
        }
      ]
    })
  },

  addkeyframe(state, name) {
    state.animation.map(val => {
      if (val.name === name) {
        val.keyframes.push({
          stop: 0,
          css: ''
        })
      }
    })
  },

  setAnimation(state, status) {
    state.playState = status
  },

  setWidgetClass(state, status) {
    state.widgetClass = status
  },
  setPageWidth(state, status) {
    state.pageWidth = status
  },
  setPageHeight(state, status) {
    state.pageHeight = status
  },
  updateData(state, { uuid, key, value }) {
    let widget = state.widgets.find(w => w.uuid === uuid)
    if (widget) {
      widget[key] = value
    }
  },

  undoRedoWidgets(state, { historyWidgets }) {
    state.activeElement = state.page
    let copy = Object.assign([], historyWidgets.newState);
    Vue.set(state, 'widgets', []);
    Vue.set(state, 'widgets', copy);
  }
}
