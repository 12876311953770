<template>
  <div
    class="lz-container"
    :id="'container' + val.uuid"
    :class="[playState ? 'anm-' + val.animationName : '', val.widgetAnimationClass]"
    :data-animation="val.widgetAnimationClass"
    :style="{
      display: val.display,
      position: 'absolute',
      width: val.width / w * 100 + '%',
      height: val.height / h * 100 + '%',
      left: val.left / w * 100 + '%',
      top: val.top / h * 100 + '%',
      zIndex: val.z,
      backgroundColor: val.bgColor,
      backgroundImage: 'url(' + val.backPic + ')',
      borderStyle: val.borderType,
      borderRadius: val.radius + 'px',
      borderColor: val.borderColor,
      borderWidth: val.borderWidth + 'px',
      flexDirection: val.dir,
      justifyContent: val.justify,
      alignItems: val.align,
      opacity: val.opacity,
      animationDelay: val.widgetAnimationDelay + 's',
      animationDuration: val.widgetAnimationDuration + 's',
      animationIterationCount: val.widgetAnimationIterationCount ? 'infinite' : 'initial',
      transform: 'rotate('+ val.rotate +'deg)',
      boxShadow: val.enableShadow ? (val.widgetShadowX + 'px ' + val.widgetShadowY+ 'px ' + val.widgetShadowBlur + 'px ' + 'rgba('+hexToRgb(val.widgetShadowColor).r+','+hexToRgb(val.widgetShadowColor).g+','+hexToRgb(val.widgetShadowColor).b + ','+(val.widgetShadowOpacity)+')') : 'none',
      background: val.enableGradient ? ('linear-gradient(rgba('+hexToRgb(val.widgetGradientColor).r+','+hexToRgb(val.widgetGradientColor).g+','+hexToRgb(val.widgetGradientColor).b + ','+(val.widgetGradientOpacity)+'), rgba('+hexToRgb(val.widgetGradientColor2).r+','+hexToRgb(val.widgetGradientColor2).g+','+hexToRgb(val.widgetGradientColor2).b + ','+(val.widgetGradientOpacity2)+') )') : '',
    }"
  >
    <div v-html="val.additionalHTML" class="additionalHTML"></div>
    <!-- 子组件 -->
    <slot></slot>
  </div>
</template>

<script>
import stylec from "./style.vue";
const WIDGET_NAME = "admedi-container";
import { widgetsMethods } from "@/mixins";

export default {
  name: WIDGET_NAME,
  mixins: [widgetsMethods],
  icon:
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line></svg>',
  title: "Container",
  panel: stylec,
  setting: {
    type: WIDGET_NAME,
    isContainer: true,
    hasGuide: true,
    isUpload: false,
    isChild: false,
    dragable: true,
    resizable: true,
    name: "",
    width: 650,
    height: 300,
    left: 50,
    top: 0,
    z: 0,
    display: "flex",
    bgColor: "transparent",
    backPic: "",
    backPicUrl: "",
    radius: 0,
    borderColor: "#ffffff",
    borderWidth: 0,
    dir: "row",
    justify: "flex-start",
    align: "flex-start",
    belong: "page",
    animationName: "",
    widgetAnimationDelay: "0",
    widgetAnimationDuration: "1",
    widgetAnimationIterationCount: false,
    widgetClass: "",
    opacity: 1,
    additionalHTML: "",
    additionalCSS: "",
    additionalJS: "",
    rotate: 0,
    enableShadow: false,
    widgetShadowColor: "#cccccc",
    widgetShadowOpacity: 1,
    widgetShadowBlur: 5,
    widgetShadowX: 5,
    widgetShadowY: 5,
    borderType: "solid",
    enableGradient: false,
    widgetGradientColor: "#cccccc",
    widgetGradientOpacity: 1,
    widgetGradientColor2: "#000000",
    widgetGradientOpacity2: 1
  },
  props: ["h", "w", "val", "playState"],
  mounted() {
    var style = document.createElement("style");
    style.setAttribute("id", "style" + this.val.uuid);
    style.innerHTML = this.val.additionalCSS;
    document.body.appendChild(style);

    var scriptIt = document.createElement("script");
    scriptIt.setAttribute("id", "script" + this.val.uuid);
    scriptIt.innerHTML = this.val.additionalJS;
    document.body.appendChild(scriptIt);
  },
  watch: {
    "val.additionalCSS": function(newVal) {
      var styleContainer = document.getElementById("style" + this.val.uuid);
      styleContainer.innerHTML = newVal;
    },
    "val.additionalJS": function(newVal) {
      var scriptContainer = document.getElementById("script" + this.val.uuid);
      scriptContainer.innerHTML = newVal;
    }
  }
};
</script>

<style scoped>
.lz-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.additionalHTML {
  font-size: 12px;
}
</style>
