<template>
  <b-modal :active.sync="showModal" :width="600" scroll="keep">
    <div class="card tac">
      <div class="card-content">
        <div class="content">
          <h1 class="title is-size-4">Availabe players?</h1>
          <p class="has-text-weight-normal">Choose from available players</p>

          <table>
            <tr v-for="entity in entities" :key="entity.Id">
              <td>
                <b-checkbox size="is-small" v-model="selected[entity.id]" />
              </td>
              <td>
                <div class="deviceProp">
                  <div v-if="isDeviceActive(entity.last_heartbeat_at)">
                    <div
                      class="isOn icon is-small"
                      title="The last heartbeat from the device was in the last 10 minutes"
                    >
                      <i></i>
                    </div>
                  </div>
                  <div
                    v-else
                    class="isOff"
                    title="There has been no heartbeat from the device for the last 10 minutes
"
                  >
                    <span class="icon is-small">
                      <i class="fa fa-exclamation-circle color-red"></i>
                    </span>
                  </div>
                </div>
              </td>
              <td>{{ entity.display_name }}</td>
              <td>
                <p v-if="entity.commercial">{{ entity.commercial.display_name }}</p>
                <p v-else>UNASSIGNED</p>
              </td>
            </tr>
          </table>

          <b-pagination
            v-if="pagination.total > pagination.perPage"
            @change="getEntities"
            :total="pagination.total"
            :current.sync="pagination.current"
            :order="pagination.order"
            :size="pagination.size"
            :simple="pagination.isSimple"
            :per-page="pagination.perPage"
          ></b-pagination>
          <div class="modal-content__buttons">
            <button class="button is-primary is-medium m0a db" @click="add">Add</button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Vue from "vue";
import { device } from "@/mixins";
import axios from "axios";
import obj2arg from "graphql-obj2arg";
export default {
  name: "availablePlayers",
  props: ["commercialId"],
  mixins: [device],
  data() {
    return {
      showModal: false,
      selected: [],
      entities: [],
      pagination: {
        total: 5,
        current: 1,
        perPage: 5,
        order: "",
        size: "",
        isSimple: false,
      },
    };
  },
  mounted() {
    this.selected = [];
    this.getEntities(1);
  },
  methods: {
    show() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = true;
      });
    },
    hide() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = false;
      });
    },
    getEntities(page) {
      var self = this;
      let thisOffset = Math.max(0, self.pagination.perPage * (page - 1));
      const query = {
        query: `  {
        devices(limit: ${self.pagination.perPage}, offset: ${thisOffset}){
            items{
                id
                display_name
                last_heartbeat_at
                commercial {              
                    display_name
                }
            }
            paging {
                total
                limit
                has_next
                has_prev
            }
        }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.data.devices) {
            self.entities = response.data.data.devices.items;
            self.pagination.total = response.data.data.devices.paging.total;
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    add() {
      var self = this;
      if (this.selected.length > 0) {
        const promisesArray = [];

        this.selected.forEach((item, index) => {
          if (item) {
            promisesArray.push(self.savePromise(index, self.commercialId));
          }
        });
        Promise.all(promisesArray).then(() => {
          self.$nextTick(() => self.$emit("getAds"));
        });
      }
    },
    savePromise(deviceId, commercialId) {
      var self = this;
      let device = {
        id: deviceId,
        commercial_id: commercialId,
      };
      return new Promise((resolve, reject) => {
        const query = {
          mutation: `  {
        device( ${obj2arg(device, { noOuterBraces: true })} ){
          id
        }
      }
      `,
        };

        axios
          .post(global.backendUrl, query)
          .then(function (response) {
            if (response.data.data.device) {
              self.$toastr.s("Device saved");
              resolve("true");
            }
          })
          .catch(function (error) {
            self.$toastr.e("Contact the administrator " + error.message);
          });
      });
    },
  },
};
</script>