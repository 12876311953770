<template>
  <b-modal :active.sync="showModal" :width="900" scroll="keep">
    <button
      type="button"
      class="modal-close is-large modal-content__close"
      @click="showModal = false"
    ></button>

    <div class="card tac p20">
      <h1 class="title is-size-4">CHOOSE TEMPLATE</h1>
      <div class="ranges" v-if="activeAdsSlidesRange">
        <div
          v-for="(range, index) in searchForDatesRanges"
          :key="index + 'range'"
          @click="activeAdsRangeSet(range)"
          v-if="activeAdsSlidesRange && range.text === activeAdsSlidesRange.text"
        >
          <div
            v-bind:class="{
              activeRange: activeAdsSlidesRange && range.text === activeAdsSlidesRange.text,
            }"
          >
            {{ cutYear(range.date_from) }} - {{ cutYear(range.date_to) }}
          </div>
        </div>
      </div>
      <div
        class="cp thisIsSlide"
        v-bind:class="{ isPortrait: slidersTemplatesPagination.isPortrait ? true : false }"
      >
        <div class="addthisIsSlide" @click="newSlide(false, false, false)">
          <i class="fa fa-plus"></i>
        </div>
        BLANK
      </div>
      <div
        v-for="slide in slidersTemplates"
        :key="slide.id"
        class="cp thisIsSlide slide__template noBorder"
        v-bind:class="{ isPortrait: slidersTemplatesPagination.isPortrait ? true : false }"
      >
        <div class="deleteThisTemplate">
          <i class="fa fa-times" @click="deleteTemplate(slide.id)"></i>
        </div>
        <div class="addthisIsSlide" @click="newSlide(slide)">
          <i class="fa fa-plus"></i>
        </div>
        <img
          :src="backendUrl + 'files' + slide.path + slide.filename"
          class="thumbnailImg cp"
          v-if="slide.filename"
          crossorigin="anonymous"
          :id="'templateImg' + slide.id"
        />
        <div v-else>
          <i class="fa fa-image fz50 cp" @click="newSlide(slide)"></i>
        </div>
      </div>
      <div class="cf"></div>
      <b-pagination
        v-if="slidersTemplatesPagination.total > slidersTemplatesPagination.limit"
        @change="getSlideTemplates"
        :total="slidersTemplatesPagination.total"
        :current.sync="slidersTemplatesPagination.page"
        :simple="false"
        :rounded="false"
        icon-pack="fa"
        :per-page="slidersTemplatesPagination.limit"
      ></b-pagination>
    </div>
  </b-modal>
</template>

<script>
import Vue from "vue";
import axios from "axios";

import { mapState } from "vuex";
import { dates } from "@/mixins";
import swal from "sweetalert2";
import * as blobUtil from "blob-util";

export default {
  name: "addSlide",

  props: ["ads", "slidersTemplatesInfo"],
  mixins: [dates],

  data() {
    return {
      showModal: false,

      slidersTemplates: [],

      slidersTemplatesPagination: {
        total: 0,
        limit: 10,
        page: 1,
        isPortrait: 1,
      },
      backendUrl: global.pageUrl,
    };
  },

  computed: {
    ...mapState(["activeAdsSlidesRange"]),
    searchForDatesRanges: function () {
      let theCommercial = this.ads.filter((obj) => {
        return parseInt(obj.id) === parseInt(this.activeAdsSlidesId);
      })[0];
      let rangeArray = [];
      if (theCommercial) {
        for (var property in theCommercial.sliders) {
          if (theCommercial.sliders[property].is_dates) {
            const dateFromMoment = moment(theCommercial.sliders[property].date_from, "DD.MM.YYYY");
            const dateToMoment = moment(theCommercial.sliders[property].date_to, "DD.MM.YYYY");
            let rangeText =
              (theCommercial.sliders[property].date_from !== null
                ? theCommercial.sliders[property].date_from + " - "
                : "") +
              (theCommercial.sliders[property].date_to !== null
                ? theCommercial.sliders[property].date_to
                : "");
            let rangeObject = {
              timestamp_sum: parseInt(
                String(dateFromMoment.format("X")) + String(dateToMoment.format("X"))
              ),
              timestamp_from: dateFromMoment.format("X"),
              timestamp_to: dateToMoment.format("X"),
              date_from: theCommercial.sliders[property].date_from,
              date_to: theCommercial.sliders[property].date_to,
              text: rangeText,
            };
            //push only unique ranges
            const rangeExist = rangeArray.findIndex((f) => f.text === rangeText);

            if (rangeExist === -1) {
              rangeArray.push(rangeObject);
            }
          }
        }
      }
      function compare(a, b) {
        if (a.timestamp_sum < b.timestamp_sum) return 1;
        if (a.timestamp_sum > b.timestamp_sum) return -1;
        return 0;
      }

      if (rangeArray.length > 0) {
        return rangeArray.sort(compare);
      } else {
        return 0;
      }
    },
  },

  methods: {
    show() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick

        self.showModal = true;
        self.slidersTemplatesPagination.isPortrait = self.slidersTemplatesInfo.isPortrait;
        self.getSlideTemplates(1);
      });
    },
    hide() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = false;
      });
    },

    makeBlobFromImg(elementId) {
      return new Promise((resolve) => {
        var img = document.getElementById(elementId);
        blobUtil.imgSrcToBlob(img.src).then(function (blob) {
          blobUtil.blobToDataURL(blob).then(function (dataurl) {
            resolve([blob, dataurl]);
          });
        });
      });
    },
    async newSlide(slide) {
      var self = this;

      const commercialIndex = self.ads.findIndex(
        (f) => f.id === self.slidersTemplatesInfo.commercialId
      );
      const slidersLength = self.ads[commercialIndex].sliders.length;

      let slideToSave = Object.assign({}, slide);
      slideToSave.date_from = "00.00.0000";
      slideToSave.date_to = "00.00.0000";
      slideToSave.is_dates = false;
      slideToSave.was_opened = true;
      if (this.activeAdsSlidesRange) {
        slideToSave.is_dates = true;
        slideToSave.date_from = this.activeAdsSlidesRange.date_from;
        slideToSave.date_to = this.activeAdsSlidesRange.date_to;
      }

      slideToSave.name = Math.round(+new Date() / 1000) + "slide";
      slideToSave.commercial_id = self.slidersTemplatesInfo.commercialId;
      slideToSave.transition = slideToSave.transition ? slideToSave.transition : "fade";
      slideToSave.order_number = 999 + slidersLength;

      //new slides set stuff
      if (typeof slideToSave.id === "undefined") {
        slideToSave.duration = "00:00:05";
        slideToSave.is_logo = false;
        slideToSave.content_html = "&nbsp;";
        slideToSave.is_under = false;
        let page = Object.assign({}, self.$store.state.defaultPage);
        page.width = self.slidersTemplatesInfo.isPortrait ? 1080 : 1920;
        page.height = self.slidersTemplatesInfo.isPortrait ? 1920 : 1080;
        page.version = 1;
        let contentJson = {
          page: page,
          widgets: [],
        };

        slideToSave.content_json = JSON.stringify(contentJson);
      }
      if (document.getElementById("templateImg" + slideToSave.id)) {
        if (process.env.NODE_ENV === "production") {
          let getData = await self.makeBlobFromImg("templateImg" + slideToSave.id);

          let imgBlob = getData[0];
          var dataURL = getData[1];
          slideToSave.blob = imgBlob;
          slideToSave.toDataURLImage = dataURL;
        }
      }
      delete slideToSave.id;
      delete slideToSave.toDataURLImage;

      this.$emit("newSlide", slideToSave);
      self.showModal = false;
    },
    getSlideTemplates(page) {
      var self = this;
      self.slidersTemplatesPagination.page = page;
      const query = {
        query: `  {
        sliders_templates(limit: 200, offset: ${
          (self.slidersTemplatesPagination.page - 1) * self.slidersTemplatesPagination.limit
        } is_portrait:${self.slidersTemplatesPagination.isPortrait ? true : false} ){
          paging{
            total
          }
          items{
            id
            name
            date_from
            date_to
            content_html
            content_json
            duration
            period_json
            is_period
            filename
            path 
          }
        }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.data.sliders_templates) {
            Vue.nextTick(function () {
              let totalx = response.data.data.sliders_templates.paging.total;
              self.slidersTemplatesPagination.total = totalx;

              self.slidersTemplates = response.data.data.sliders_templates.items;
              self.addSlideModal = true;
            });
          } else {
            self.$toastr.e("No templates");
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    deleteTemplate(slideId) {
      var self = this;
      swal
        .fire({
          title: "Are you sure you want to delete the template?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            self.$toastr.s("Deleting...");
            const query = {
              mutation: `  {
                remove_slider(id: ${slideId})}
              `,
            };
            axios
              .post(global.backendUrl, query)
              .then(function (response) {
                if (response.data.errors) {
                  for (var property in response.data.errors) {
                    self.$toastr.e(response.data.errors[property].message);
                  }
                } else {
                  const slideTEmplateToDelete = self.slidersTemplates.findIndex(
                    (f) => f.id === slideId
                  );

                  self.slidersTemplates.splice(slideTEmplateToDelete, 1);
                  self.$toastr.s("Deleted");
                }
              })
              .catch(function (error) {
                self.$toastr.e("Contact the administrator " + error.message);
              });
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },
  },
};
</script>