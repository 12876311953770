<template>
  <section class="hero">
    <br />
    <div class="hero-body">
      <div class="container max-width-400">
        <form @submit.prevent="submit()">
          <img src="/static/img/logo.png" alt="Image" class="inCointainerLogo" />
          <p class="title">LOGIN</p>
          <div class="field">
            <p class="control has-icons-left has-icons-right">
              <input
                class="input"
                type="email"
                name="email"
                placeholder="Email"
                v-model="credentials.email"
                autocomplete="on"
              />
              <span class="icon is-small is-left">
                <i class="fa fa-envelope"></i>
              </span>
            </p>
          </div>
          <div class="field">
            <p class="control has-icons-left">
              <input
                autocomplete="on"
                name="password"
                class="input"
                type="password"
                placeholder="Password"
                v-model="credentials.password"
              />
              <span class="icon is-small is-left">
                <i class="fa fa-lock"></i>
              </span>
            </p>
          </div>

          <div class="field m-t-10">
            <p class="control d-flex justify-space-between">
              <button class="button is-primary">Login</button>
              <router-link class="button is-secondary" :to="{ name: 'admRegister' }"
                >Register</router-link
              >
            </p>
          </div>
          <div class="field m-t-10">
            <div class="control d-flex justify-space-between">
              <p class="cp" @click="resetModal = true">Reset password</p>
            </div>
          </div>
        </form>
      </div>
    </div>
    <b-modal :active.sync="resetModal" :width="400" scroll="keep">
      <div class="card tac">
        <div class="card-content">
          <div class="content">
            <h1>Reset password</h1>
            <b-field>
              <b-input v-model="emailToReset" placeholder="Email" type="text"></b-input>
            </b-field>

            <br />
            <button class="button is-primary is-medium m0a db" @click="resetPassword()">
              send email
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
export default {
  beforeCreate() {
    if (this.$store.state.user) {
      this.$router.push("/app/advertisements");
    }
  },
  data() {
    return {
      backendUrl: window.backendUrl,
      backendLoginUrl: window.backendLoginUrl,
      credentials: {
        email: "",
        password: "",
      },
      error: "",
      resetModal: false,
      emailToReset: "",
    };
  },
  methods: {
    submit() {
      var self = this;
      if (self.credentials.email === "") {
        self.$toastr.e("Fill email");
        return;
      }
      if (self.credentials.password === "") {
        self.$toastr.e("Fill password");
        return;
      }
      axios
        .post(self.backendLoginUrl + "users/token", {
          email: self.credentials.email,
          password: self.credentials.password,
        })
        .then(function (response) {
          if (response.data) {
            self.$toastr.s("Successfully logged");
            localStorage.setItem("ACCESS_TOKEN_KEY", response.data.data.token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.data.token;
            self.$router.push("/advertisements");
          }
        })
        .catch(function (error) {
          console.log(error);
          if (!error.response) {
            delete axios.defaults.headers.common["Authorization"];
            self.$router.push("/");
          }
          if (error.response.status === 401) {
            self.$toastr.e("Wrong password or login");
          } else {
            self.$toastr.e("Contact the administrator");
          }
        });
    },
    resetPassword() {
      var self = this;
      if (self.emailToReset === "") {
        self.$toastr.e("Fill email");
        return;
      }
      var regex = /.+@.+\..+/;
      if (!regex.test(self.emailToReset)) {
        self.$toastr.e("Enter valid email");
        return;
      }

      const query = {
        query: `
            mutation {user_send_password_reset (email: "${self.emailToReset}")} `,
      };
      axios
        .post(global.backendLoginUrl, query)

        .then(function (response) {
          if (response.data && response.data.data && response.data.data.user_send_password_reset) {
            self.$toastr.s("Email sent.");
            self.emailToReset = "";
            self.resetModal = false;
          }
        })
        .catch(function (error) {});
    },
  },
};
</script>