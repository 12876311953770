<template>
  <b-modal :active.sync="showModal" :width="640" scroll="keep">
    <div class="card tac" v-if="currentDeviceToEdit">
      <div class="card-content">
        <div class="content" v-if="currentDeviceToEdit.id">
          <h1 class="title is-size-4" v-if="is_new">SET UP NEW PLAYER</h1>
          <h1 class="title is-size-4" v-else>PLAYER SETTINGS</h1>
          <p v-if="is_new">New player has been added succesfully.</p>
          <b-field>
            <b-input
              class="deviceID-input deviceID-inpy tut--long has-text-left"
              v-model="currentDeviceToEdit.display_name"
              placeholder="name player"
            ></b-input>
          </b-field>
          <div class="deviceProp" v-if="!is_new">
            <div class="deviceProp__row">
              <div v-if="isDeviceActive(currentDeviceToEdit.last_heartbeat_at)">
                <div
                  class="isOn icon is-small"
                  title="The last heartbeat from the device was in the last 10 minutes"
                >
                  <i></i>
                </div>
                Online
              </div>
              <div
                v-else
                class="isOff"
                title="There has been no heartbeat from the device for the last 10 minutes
"
              >
                <span class="icon is-small">
                  <i class="fa fa-exclamation-circle color-red"></i>
                </span>
                Offline
              </div>
              <div class="color-grey">
                <span v-if="currentDeviceToEdit.last_heartbeat_at"
                  >last connection was
                  {{ geti18nDate(currentDeviceToEdit.last_heartbeat_at) }}</span
                >
                {{ getCommercialName(currentDeviceToEdit.last_heartbeat_commercial_id) }}
              </div>
            </div>
          </div>

          <div class="deviceProp" v-if="!is_new">
            <div class="deviceProp__row">
              <div
                v-if="
                  !(
                    parseInt(currentDeviceToEdit.commercial.id) ===
                      parseInt(currentDeviceToEdit.last_heartbeat_commercial_id) &&
                    parseInt(currentDeviceToEdit.commercial.version_nr) ===
                      parseInt(currentDeviceToEdit.last_heartbeat_version_nr)
                  )
                "
              >
                <span class="icon is-small">
                  <i class="fa fa-exclamation-circle color-red"></i>
                </span>
                Outdated
                <span
                  v-if="!updateDeviceIntervals[currentDeviceToEdit.commercial.id]"
                  class="button is-primary is-small"
                  @click="deviceRequestUpdate()"
                  >Update</span
                >
                <span v-else class="button is-primary is-small disabled">Update</span>
              </div>
              <div v-else>
                <div class="isOn icon is-small">
                  <i></i>
                </div>
                Actual
              </div>
              <div class="color-grey">
                <span v-if="currentDeviceToEdit.last_heartbeat_at"
                  >Last content {{ geti18nDate(currentDeviceToEdit.last_heartbeat_at) }}</span
                >
                {{ getCommercialName(currentDeviceToEdit.last_heartbeat_commercial_id) }}
              </div>
            </div>
          </div>

          <div class="deviceProp" v-if="userRole === 'NORMAL' && !is_new">
            <div
              class="deviceProp__row"
              v-if="
                currentDeviceToEdit.subscriptions && currentDeviceToEdit.subscriptions.length === 0
              "
            >
              <div class="isOff icon is-small">
                <i></i>
              </div>
              No subscription
              <router-link class="button is-primary is-small" :to="'/devices'">Buy one</router-link>
            </div>
            <div
              class="deviceProp__row"
              v-if="
                currentDeviceToEdit.subscriptions &&
                currentDeviceToEdit.subscriptions[0] &&
                currentDeviceToEdit.subscriptions[0].status === 'FREE'
              "
            >
              <div class="isOn icon is-small">
                <i></i>
              </div>
              Free subscription
            </div>

            <div
              class="deviceProp__row"
              v-if="
                currentDeviceToEdit.subscriptions &&
                getValidSubscription(currentDeviceToEdit.subscriptions) !== false
              "
            >
              <div>
                <div class="isOn icon is-small">
                  <i></i>
                </div>
                Subscription
              </div>
              <div></div>
              Device active to
              {{
                i18ndate(
                  currentDeviceToEdit.subscriptions[
                    getValidSubscription(currentDeviceToEdit.subscriptions)
                  ].valid_to
                )
              }}
            </div>

            <div
              class="deviceProp__row"
              v-if="
                !currentDeviceToEdit.subscriptions ||
                (currentDeviceToEdit.subscriptions &&
                  currentDeviceToEdit.subscriptions.length > 0 &&
                  !(getValidSubscription(currentDeviceToEdit.subscriptions) !== false))
              "
            >
              <div class="isOff icon is-small">
                <i></i>
              </div>
              Device unactive
              <router-link class="button is-primary is-small" :to="'/devices'"
                >Buy subscription</router-link
              >
            </div>
          </div>

          <div class="p-l-9" v-if="!is_new">
            <div class="deviceProp">
              <div>
                <b-switch v-model="currentDeviceToEdit.is_standby">Set up working hours</b-switch>
              </div>
              <div>
                <b-switch v-model="currentDeviceToEdit.is_flipped">Rotate 180&deg;</b-switch>
              </div>
            </div>

            <div class="standByOptions" v-if="currentDeviceToEdit.is_standby !== false">
              <div>
                <span class="grey fz13">MON.-FRI.</span>
                <timeselector
                  :interval="timeselectorInterval"
                  class="thisTimeselector"
                  @input="updateTime($event, currentDeviceToEdit, 'weekday_live_from')"
                  :value="createTime(currentDeviceToEdit.weekday_live_from)"
                  :h24="true"
                  :displayFormat="'HH:mm'"
                  :returnFormat="'HH:mm'"
                ></timeselector>
                <input
                  type="hidden"
                  class="time-picker"
                  v-model="currentDeviceToEdit.weekday_live_from"
                />
                <span class="grey fz13 ml10">-</span>
                <timeselector
                  :interval="timeselectorInterval"
                  class="thisTimeselector"
                  @input="updateTime($event, currentDeviceToEdit, 'weekday_live_to')"
                  :value="createTime(currentDeviceToEdit.weekday_live_to)"
                  :h24="true"
                  :displaySeconds="false"
                  :displayFormat="'HH:mm'"
                  :returnFormat="'HH:mm'"
                ></timeselector>
                <input
                  type="hidden"
                  class="time-picker"
                  v-model="currentDeviceToEdit.weekday_live_to"
                />
              </div>
              <div>
                <span class="grey fz13 ml5">SAT.</span>
                <timeselector
                  :interval="timeselectorInterval"
                  class="thisTimeselector"
                  @input="updateTime($event, currentDeviceToEdit, 'saturday_live_from')"
                  :value="createTime(currentDeviceToEdit.saturday_live_from)"
                  :h24="true"
                  :displaySeconds="false"
                  :displayFormat="'HH:mm'"
                  :returnFormat="'HH:mm'"
                ></timeselector>
                <input
                  type="hidden"
                  class="time-picker"
                  v-model="currentDeviceToEdit.saturday_live_from"
                />
                <span class="grey fz13 ml10">-</span>
                <timeselector
                  :interval="timeselectorInterval"
                  class="thisTimeselector"
                  @input="updateTime($event, currentDeviceToEdit, 'saturday_live_to')"
                  :value="createTime(currentDeviceToEdit.saturday_live_to)"
                  :h24="true"
                  :displaySeconds="false"
                  :displayFormat="'HH:mm'"
                  :returnFormat="'HH:mm'"
                ></timeselector>
                <input
                  type="hidden"
                  class="time-picker"
                  v-model="currentDeviceToEdit.saturday_live_to"
                />
              </div>
              <div>
                <span class="grey fz13 ml5">SUN.</span>
                <timeselector
                  :interval="timeselectorInterval"
                  class="thisTimeselector"
                  @input="updateTime($event, currentDeviceToEdit, 'sunday_live_from')"
                  :value="createTime(currentDeviceToEdit.sunday_live_from)"
                  :h24="true"
                  :displaySeconds="false"
                  :displayFormat="'HH:mm'"
                  :returnFormat="'HH:mm'"
                ></timeselector>
                <input
                  type="hidden"
                  class="time-picker"
                  v-model="currentDeviceToEdit.sunday_live_from"
                />
                <span class="grey fz13 ml10">-</span>
                <timeselector
                  :interval="timeselectorInterval"
                  class="thisTimeselector"
                  @input="updateTime($event, currentDeviceToEdit, 'sunday_live_to')"
                  :value="createTime(currentDeviceToEdit.sunday_live_to)"
                  :h24="true"
                  :displaySeconds="false"
                  :displayFormat="'HH:mm'"
                  :returnFormat="'HH:mm'"
                ></timeselector>
                <input
                  type="hidden"
                  class="time-picker"
                  v-model="currentDeviceToEdit.sunday_live_to"
                />
              </div>
            </div>

            <div v-if="currentDeviceToEdit.is_standby" class="m-b-1">
              <p>
                <span class="has-text-danger">Warning:</span> Time zone on the player must be set
                properly.
              </p>
            </div>
            <b-field
              label="Player assigned to:"
              class="horizontal-select horizontal-select--black"
              v-if="!is_new"
            >
              <b-select placeholder="-" v-model="currentDeviceToEdit.commercial.id">
                <option v-for="option in admediAdsOptions" :value="option.id" :key="option.id">
                  {{ option.label }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="d-flex space-between deviceSelect">
            <span class="mw100"></span>
            <button class="button is-primary" @click="saveDevice(currentDeviceToEdit, false)">
              save
            </button>
            <button
              class="button is-primary"
              @click="saveDevice(currentDeviceToEdit, true)"
              v-if="!is_new"
            >
              save and update
            </button>

            <div
              @click="deleteDevice(currentDeviceToEdit.display_name, currentDeviceToEdit.id)"
              class="mw100 cp"
              v-if="!is_new"
            >
              DELETE PLAYER
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import swal from "sweetalert2";
import { device, dates, helper, advertisements } from "@/mixins";
import obj2arg from "graphql-obj2arg";
import Timeselector from "vue-timeselector";

export default {
  name: "editDeviceModal",
  props: ["deviceX", "ads", "updateDeviceIntervals", "admediAdsOptions", "userRole"],
  mixins: [device, dates, helper, advertisements],
  components: {
    Timeselector,
  },
  data() {
    return {
      showModal: false,
      currentDeviceToEdit: null,
      is_new: false,
      timeselectorInterval: {
        h: 1,
        m: 1,
        s: 1,
      },
      //   newDeviceInvalid: false,
      //   newDeviceId: null,
    };
  },
  watch: {
    deviceX: function (newVal, oldVal) {
      // watch it

      if (newVal.is_new) {
        this.is_new = true;
      } else {
        this.is_new = false;
      }
      this.getDevice();
    },
  },

  //   mounted() {
  //   },
  methods: {
    getDevice() {
      var self = this;
      const query = {
        query: `  {
        device(device_id: "${this.deviceX.device_id}"){
          id
          display_name
          properties{
            screen_width
            screen_height
            screen_orientation
            uuid
          }
          rotation
          status
          weekday_live_from
          weekday_live_to
          saturday_live_from
          saturday_live_to
          sunday_live_from
          sunday_live_to
          device_id
          is_flipped
          is_vertical
          is_standby
          last_heartbeat_at
          last_heartbeat_commercial_id
          last_heartbeat_version_nr
          subscriptions{
            status
            valid_to
          }
          commercial{
            id
            url
            display_name
            modified_at
            version_nr
            versions{
              display_name
              modified_at
            }
          }
        }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else {
            if (response.data.data.device) {
              self.editDeviceModal = true;
              self.currentDeviceToEdit = response.data.data.device;
              if (self.is_new) {
                self.currentDeviceToEdit.display_name = "";
              }
            } else {
              self.$toastr.e("Device not found");
            }
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    saveDevice(currentDeviceToEdit, updateDevice) {
      var self = this;
      let device = Object.assign({}, currentDeviceToEdit);
      if (!device.display_name) {
        self.$toastr.e("Set new player name.");
        return;
      }
      if (device.commercial) {
        device.commercial_id = device.commercial.id;
      }
      device.rotation = parseInt(device.rotation);
      delete device.commercial;
      delete device.subscriptions;
      delete device.properties;
      delete device.last_heartbeat_at;
      delete device.last_heartbeat_version_nr;
      delete device.last_heartbeat_commercial_id;
      const isNewDevice = device.is_new;
      delete device.is_new;
      device.user_id = self.user_id;
      const query = {
        mutation: `  {
        device( ${obj2arg(device, { noOuterBraces: true })} ){
          id
        }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          // self.addDeviceStepModal2 = false;
          // self.editDeviceModal = false;
          if (response.data.data.device) {
            self.$toastr.s("Device saved.");
            if (updateDevice) {
              self.deviceRequestUpdate(currentDeviceToEdit, isNewDevice);
            }
            Vue.nextTick(function () {
              self.$emit("getAds");
            });
            self.showModal = false;
            // self.getAds();
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    deleteDevice(entityName, entityId) {
      var self = this;
      swal
        .fire({
          title: "Do you want to delete " + entityName + "?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            self.$toastr.s("Deleting...");
            // self.editDeviceModal = false;
            const query = {
              mutation: `  {
            remove_device(id: ${entityId}){id} }
          `,
            };
            axios
              .post(global.backendUrl, query)
              .then(function (response) {
                if (response.data.data.remove_device) {
                  // self.getDevices()
                  self.showModal = false;
                  self.$toastr.s("Deleted");
                  self.$emit("getAds");
                }
              })
              .catch(function (error) {
                self.$toastr.e("Contact the administrator " + error.message);
              });
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },
    show() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = true;
      });
    },
    hide() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = false;
      });
    },
    getCommercialName(commercialId) {
      let getCommercialName = this.ads.filter((obj) => {
        return parseInt(obj.id) === parseInt(commercialId);
      });
      return getCommercialName[0] ? "(" + getCommercialName[0].display_name + ")" : "";
    },
    // updateDevice(device, isNewDevice) {
    //   var self = this;
    //   // self.editDeviceModal = false;
    //   let pushArgs = {};
    //   pushArgs.url = device.commercial.url;
    //   pushArgs.commercialId = device.commercial.id;
    //   pushArgs.commercialVersionNr = device.commercial.version_nr;
    //   if (device.properties) {
    //     pushArgs.uuid = device.properties.uuid;
    //   } else {
    //     pushArgs.uuid = false;
    //   }
    //   pushArgs.rotation = device.rotation;
    //   pushArgs.isStandby = device.is_standby;
    //   pushArgs.if_flipped = device.is_flipped;
    //   pushArgs.deviceWeekdayStandbyFrom = device.weekday_live_from;
    //   pushArgs.deviceWeekdaystandbyTo = device.weekday_live_to;
    //   pushArgs.deviceSaturdayStandbyFrom = device.saturday_live_from;
    //   pushArgs.deviceSaturdayStandbyTo = device.saturday_live_to;
    //   pushArgs.deviceSundayStandbyFrom = device.sunday_live_from;
    //   pushArgs.deviceSundayStandbyTo = device.sunday_live_to;
    //   if (isNewDevice) {
    //     console.info("new device");

    //     // self.sendPush(pushArgs, true, true);

    //     self.$emit("sendPush", pushArgs, true, true);
    //   } else {
    //     console.info("old device");
    //     // self.sendPush(pushArgs);

    //     self.$emit("sendPush", pushArgs, true, false);
    //   }
    //   self.$toastr.s(
    //     "Information has been sent to the device, information about the outdated advertisement will disappear when the device returns information that it displays the current advertisement."
    //   );
    //   self.showModal = false;
    // },
    deviceRequestUpdate() {
      // deviceRequestUpdate(currentDeviceToEdit.device_id, currentDeviceToEdit)
      this.$emit(
        "deviceRequestUpdate",
        this.currentDeviceToEdit.device_id,
        this.currentDeviceToEdit
      );
      this.$emit("deviceRequestUpdateInfo");
    },
  },
};
</script>