<template>
  <div>
    <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        animation
        <select
          v-model="activeElement.widgetAnimationClass"
          class="panel__select"
          @change="changeAnimation"
        >
          <option value>none</option>
          <option v-for="(val, index) in animationNames" :key="index" :value="val">
            {{ val }}
          </option>
        </select>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="activeElement.widgetAnimationClass">
        <div class="panel-row" flex>
          <div class="field">
            <b-checkbox v-model="activeElement.widgetAnimationIterationCount">Loop</b-checkbox>
          </div>
        </div>
        <div class="panel-row" flex v-if="!activeElement.widgetAnimationIterationCount">
          <div class="panel-label panel-label--input">
            <span>delay</span>
            <div><input class="panel-input" v-model="activeElement.widgetAnimationDelay" /> s</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="0.1" :max="10" :min="0" v-model="activeElement.widgetAnimationDelay" />
          </div>
        </div>
        <div class="panel-row" flex v-if="!activeElement.widgetAnimationIterationCount">
          <div class="panel-label panel-label--input">
            <span>duration</span>
            <div>
              <input class="panel-input" v-model="activeElement.widgetAnimationDuration" /> s
            </div>
          </div>

          <div class="panel-slider-wrap">
            <slider
              :step="0.1"
              :max="10"
              :min="0"
              v-model="activeElement.widgetAnimationDuration"
            />
          </div>
        </div>
      </div>
    </transition>

    <div class="panel-row" flex title="background color">
      <div class="panel-value">
        <div class="panel__color">
          <input type="color" v-model="activeElement.bgColor" />
        </div>
        <input class="panel-input" v-model="activeElement.bgColor" />
      </div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-label panel-label--input">
        <span>round corner</span>
        <div>
          <input class="panel-input" v-model="activeElement.radius" />
        </div>
      </div>

      <div class="panel-slider-wrap">
        <slider :step="1" :max="10000" :min="0" v-model="activeElement.radius" />
      </div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        border:
        <select v-model="activeElement.borderType" class="panel__select">
          <option value="dotted">dotted</option>
          <option value="dashed">dashed</option>
          <option value="solid">solid</option>
          <option value="double">double</option>
          <option value="groove">groove</option>
          <option value="ridge">ridge</option>
          <option value="inset">inset</option>
          <option value="outset">outset</option>
          <option value="none">none</option>
          <option value="hidden">hidden</option>
        </select>
      </div>
    </div>

    <div class="panel-row" flex title="border color">
      <div class="panel-value">
        <div class="panel__color">
          <input type="color" v-model="activeElement.borderColor" />
        </div>

        <input class="panel-input" v-model="activeElement.borderColor" />
      </div>
    </div>

    <div class="panel-row" flex title="border width">
      <div class="panel-label panel-label--input">
        <span>size</span>
        <div>
          <input class="panel-input" v-model="activeElement.borderWidth" />
        </div>
      </div>

      <div class="panel-slider-wrap">
        <slider :step="1" :max="100" :min="0" v-model="activeElement.borderWidth" />
      </div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-label">transparency</div>
      <div class="panel-value">{{ Math.round(activeElement.opacity * 100) }} %</div>
      <div class="panel-slider-wrap">
        <slider :step="0.01" :max="1" :min="0" v-model="activeElement.opacity" />
      </div>
    </div>
    <!-- <div class="panel-row" flex>
      <div class="panel-label panel-label--input">
        <span>rotation</span>
        <div>
          <input class="panel-input" v-model="activeElement.rotate" />
        </div>
      </div>

      <div class="panel-slider-wrap">
        <slider :step="1" :max="360" :min="0" v-model="activeElement.rotate" />
      </div>
    </div>-->

    <br />
    <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        drop shadow:
        <select v-model="activeElement.enableShadow" class="panel__select">
          <option value>none</option>
          <option value="true">enable</option>
        </select>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="activeElement.enableShadow">
        <div class="panel-row" flex title="background color">
          <div class="panel-value">
            <div class="panel__color">
              <input type="color" v-model="activeElement.widgetShadowColor" rgba />
            </div>
            <input class="panel-input" v-model="activeElement.widgetShadowColor" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>blur</span>
            <div><input class="panel-input" v-model="activeElement.widgetShadowBlur" /> px</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetShadowBlur" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>x</span>
            <div><input class="panel-input" v-model="activeElement.widgetShadowX" /> px</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetShadowX" />
          </div>
        </div>

        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>y</span>
            <div><input class="panel-input" v-model="activeElement.widgetShadowY" /> px</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetShadowY" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label">opacity</div>
          <div class="panel-value">{{ Math.round(activeElement.widgetShadowOpacity * 100) }} %</div>
          <div class="panel-slider-wrap">
            <slider :step="0.01" :max="1" :min="0" v-model="activeElement.widgetShadowOpacity" />
          </div>
        </div>
      </div>
    </transition>
    <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        gradient:
        <select v-model="activeElement.enableGradient" class="panel__select">
          <option value>none</option>
          <option value="true">enable</option>
        </select>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="activeElement.enableGradient">
        <div class="panel-row" flex title="background color">
          <div class="panel-value">
            <div class="panel__color">
              <input type="color" v-model="activeElement.widgetGradientColor" />
            </div>
            <input class="panel-input" v-model="activeElement.widgetGradientColor" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label">opacity</div>
          <div class="panel-value">
            {{ Math.round(activeElement.widgetGradientOpacity * 100) }} %
          </div>
          <div class="panel-slider-wrap">
            <slider :step="0.01" :max="1" :min="0" v-model="activeElement.widgetGradientOpacity" />
          </div>
        </div>
        <div class="panel-row" flex title="background color">
          <div class="panel-value">
            <div class="panel__color">
              <input type="color" v-model="activeElement.widgetGradientColor2" />
            </div>
            <input class="panel-input" v-model="activeElement.widgetGradientColor2" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label">opacity</div>
          <div class="panel-value">
            {{ Math.round(activeElement.widgetGradientOpacity2 * 100) }} %
          </div>
          <div class="panel-slider-wrap">
            <slider :step="0.01" :max="1" :min="0" v-model="activeElement.widgetGradientOpacity2" />
          </div>
        </div>
      </div>
    </transition>
    <div class="panel-row" flex>
      <div class="panel-label panel__menu__buttons">
        <div class="panel__menu__button" @click="levelup()">
          <i class="fa fa-level-up"></i>
        </div>
        <div class="panel__menu__button" @click="leveldown()">
          <i class="fa fa-level-down"></i>
        </div>
        <div class="panel__menu__button" @click="clone()">
          <i class="fa fa-clone"></i>
        </div>
        <div class="panel__menu__button" @click="deleteThis()">
          <b-icon pack=" fa fa-trash" />
        </div>
      </div>
    </div>
    <h3 class="showMore cp" @click="showMore = !showMore">Advanced</h3>
    <div v-if="showMore">
      <div class="panel-row">
        <div class="panel-label">Container</div>
        <div class="panel-value">
          <input
            type="text"
            v-model.trim="activeElement.name"
            placeholder="Kontener"
            class="panel__text"
          />
        </div>
      </div>
      <div class="panel-row" flex>
        <div class="panel-value panel__select__container">
          Display method:
          <select v-model="activeElement.display" class="panel__select">
            <option>flex</option>
            <option>block</option>
          </select>
        </div>
      </div>
      <!-- <div class="panel-row">
        <icon name="grid" />
        <div class="panel-label">Display method</div>
        <div class="panel-value">
          <select v-model="activeElement.display">
            <option>flex</option>
            <option>block</option>
          </select>
        </div>
      </div>-->
      <div class="panel-row" flex v-show="activeElement.display === 'flex'">
        <div class="panel-value panel__select__container">
          Column/row:
          <select v-model="activeElement.dir" class="panel__select">
            <option>row</option>
            <option>row-reverse</option>
            <option>column</option>
            <option>column-reverse</option>
          </select>
        </div>
      </div>
      <!-- <div class="panel-row" v-show="activeElement.display === 'flex'">
        <icon name="shuffle" />
        <div class="panel-label">Column / row</div>
        <div class="panel-value">
          <select v-model="activeElement.dir">
            <option>row</option>
            <option>row-reverse</option>
            <option>column</option>
            <option>column-reverse</option>
          </select>
        </div>
      </div>-->
      <div class="panel-row" flex v-show="activeElement.display === 'flex'">
        <div class="panel-value panel__select__container">
          Horizontal:
          <select v-model="activeElement.justify" class="panel__select">
            <option>flex-start</option>
            <option>space-between</option>
            <option>center</option>
            <option>space-around</option>
            <option>flex-end</option>
          </select>
        </div>
      </div>
      <!-- <div class="panel-row" v-show="activeElement.display === 'flex'">
        <icon name="align-justify" />
        <div class="panel-label">Horizontal</div>
        <div class="panel-value">
          <select v-model="activeElement.justify">
            <option>flex-start</option>
            <option>space-between</option>
            <option>center</option>
            <option>space-around</option>
            <option>flex-end</option>
          </select>
        </div>
      </div>-->
      <div class="panel-row" flex v-show="activeElement.display === 'flex'">
        <div class="panel-value panel__select__container">
          Vertical:
          <select v-model="activeElement.align" class="panel__select">
            <option>flex-start</option>
            <option>center</option>
            <option>flex-end</option>
          </select>
        </div>
      </div>
      <!-- <div class="panel-row" v-show="activeElement.display === 'flex'">
        <icon name="align-center" />
        <div class="panel-label">Vertical</div>
        <div class="panel-value">
          <select v-model="activeElement.align">
            <option>flex-start</option>
            <option>center</option>
            <option>flex-end</option>
          </select>
        </div>
      </div>-->

      <div class="panel-row" flex>
        <div class="panel-label panel-label--input">
          <span>Width</span>
          <div>
            <input class="panel-input" v-model="activeElement.width" />
          </div>
        </div>

        <div class="panel-slider-wrap">
          <slider :step="1" :max="750" :min="0" v-model="activeElement.width" />
        </div>
      </div>
      <div class="panel-row" flex>
        <div class="panel-label panel-label--input">
          <span>Height</span>
          <div>
            <input class="panel-input" v-model="activeElement.height" />
          </div>
        </div>

        <div class="panel-slider-wrap">
          <slider :step="1" :max="height" :min="0" v-model="activeElement.height" />
        </div>
      </div>

      <div class="panel-row" flex>
        <div class="panel-label panel-label--input">
          <span>Offset x</span>
          <div>
            <input class="panel-input" v-model="activeElement.left" />
          </div>
        </div>

        <div class="panel-slider-wrap">
          <slider :step="1" :max="750" :min="0" v-model="activeElement.left" />
        </div>
      </div>

      <div class="panel-row" flex>
        <div class="panel-label panel-label--input">
          <span>Offset y</span>
          <div>
            <input class="panel-input" v-model="activeElement.top" />
          </div>
        </div>

        <div class="panel-slider-wrap">
          <slider :step="1" :max="height" :min="0" v-model="activeElement.top" />
        </div>
      </div>

      <div class="panel-row">
        <div class="panel-label">HTML</div>
        <div class="panel-value">
          <textarea
            v-model="activeElement.additionalHTML"
            placeholder="<div>czerwone</div>"
            class="panel__textarea"
          ></textarea>
        </div>
      </div>

      <div class="panel-row">
        <div class="panel-label">CSS</div>
        <div class="panel-value">
          <textarea
            v-model="activeElement.additionalCSS"
            :placeholder="'#container' + activeElement.uuid + '{color:red}'"
            class="panel__textarea"
          ></textarea>
        </div>
      </div>

      <div class="panel-row">
        <div class="panel-label">JS</div>
        <div class="panel-value">
          <textarea
            v-model="activeElement.additionalJS"
            placeholder="console.log('test')"
            class="panel__textarea"
          ></textarea>
        </div>
      </div>
      <div class="panel-row">
        <div class="panel-label">
          <a @click="saveAsFigure()" class="saveAs">Save as figure</a>
        </div>
      </div>
    </div>

    <!-- 
    <div class="panel-row">
      <icon name="image" />
      <div class="panel-label">Obraz tła</div>
      <div class="panel-value">
        <div class="panel-preview"
          @click="addPic"
          :style="{ backgroundImage: 'url(' + activeElement.backPic + ')' }">
          <icon name="plus" v-show="!activeElement.backPic" />
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
var html2canvas = require("html2canvas");
import swal from "sweetalert2";
export default {
  name: "admedi-container-style",
  props: ["activeElement"],
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    animationNames() {
      var arr = [];
      this.$store.state.animation.map((val) => arr.push(val.name));

      return arr;
    },
    height() {
      return this.$store.state.page.height;
    },
    width() {
      return this.$store.state.page.width;
    },
  },
  methods: {
    changeAnimation() {
      if (this.activeElement.widgetAnimationClass.indexOf("fadeIn") !== -1) {
        this.activeElement.opacity = 0;
      } else {
        this.activeElement.opacity = 1;
      }
    },
    saveAsFigure() {
      var self = this;
      self.$toastr.s("Saving...");
      html2canvas(document.querySelector("#container" + self.activeElement.uuid), {
        logging: true,
        letterRendering: 1,
        allowTaint: false,
        useCORS: true,
      }).then((canvas) => {
        self.$store.commit("saveAsFigure", canvas.toDataURL());
      });
    },
    addPic() {
      this.$store.$emit("upload", (payload) => {
        this.$store.commit("addContainerBackPic", payload);
      });
    },
    deleteThis() {
      var self = this;
      swal
        .fire({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            self.$store.commit("delete");
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },
    clone() {
      this.$store.commit("copy");
    },
    levelup() {
      this.$store.commit("levelup");
    },
    leveldown() {
      this.$store.commit("leveldown");
    },
  },
};
</script>
