<template>
  <section class="hero">
    <vue-toastr ref="toastr"></vue-toastr>
    <div class="hero-body">
      <div class="container has-text-centered max-width-400">
        <img src="/static/img/logo.png" alt="Image" class="inCointainerLogo" />

        <h2 class="title">Account activation</h2>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import Toastr from "vue-toastr";

export default {
  components: {
    "vue-toastr": Toastr
  },
  props: ["code"],

  data() {
    return {
      backendUrl: window.backendUrl,
      backendLoginUrl: window.backendLoginUrl
    };
  },
  mounted() {
    this.submit();
    // var self = this;
  },

  methods: {
    submit() {
      var self = this;

      const query = {
        query: `
            mutation {user_activate (code: "${self.code}")}  `
      };

      axios
        .post(global.backendLoginUrl, query)
        .then(Response => {
          if (Response.data && Response.data.data && Response.data.data.user_activate) {
            self.$toastr.s("Account activated.");
            self.$router.push({ name: "admLogin" });
          }
        })
        .catch(err => {
          self.$toastr.e("Wystąpił błąd.");
        });
    }
  }
};
</script>
