var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lz-image",class:[_vm.playState ? 'anm-' + _vm.val.animationName : '', _vm.val.widgetAnimationClass],style:({
    position: _vm.val.belong === 'page' ? 'absolute' : 'relative',
    width: (_vm.val.width / _vm.w) * 100 + '%',
    height: (_vm.val.height / _vm.h) * 100 + '%',
    left: _vm.val.belong === 'page' ? (_vm.val.left / _vm.w) * 100 + '%' : '0',
    top: _vm.val.belong === 'page' ? (_vm.val.top / _vm.h) * 100 + '%' : '0',
    zIndex: _vm.val.z,
    opacity: _vm.val.opacity,
    backgroundColor: _vm.val.bgColor,
    borderRadius: _vm.val.radius + 'px',
    animationDelay: _vm.val.widgetAnimationDelay + 's',
    animationDuration: _vm.val.widgetAnimationDuration + 's',
    animationIterationCount: _vm.val.widgetAnimationIterationCount ? 'infinite' : 'initial',
    transform: 'rotate(' + _vm.val.rotate + 'deg) ' + (_vm.val.widgetScale ? ' scale(-1, 1)' : ''),
    boxShadow: _vm.val.enableShadow
      ? _vm.val.widgetShadowX +
        'px ' +
        _vm.val.widgetShadowY +
        'px ' +
        _vm.val.widgetShadowBlur +
        'px ' +
        'rgba(' +
        _vm.hexToRgb(_vm.val.widgetShadowColor).r +
        ',' +
        _vm.hexToRgb(_vm.val.widgetShadowColor).g +
        ',' +
        _vm.hexToRgb(_vm.val.widgetShadowColor).b +
        ',' +
        _vm.val.widgetShadowOpacity +
        ')'
      : 'none',
  }),attrs:{"id":'pic-' + _vm.val.uuid,"data-animation":_vm.val.widgetAnimationClass}},[(_vm.val.mediaType === 'thisIsVid')?_c('video',{key:_vm.val.url,style:({ backgroundImage: 'url(' + _vm.videoImage + ')', backgroundSize: 'cover' }),on:{"click":_vm.clickVideo}},[_c('source',{attrs:{"src":_vm.returnUrl,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]):_vm._e(),(_vm.val.mediaType === 'thisIsImg')?_c('img',{key:_vm.val.url,attrs:{"src":_vm.returnUrl}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }