<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Pay with paypal
          </h1>
          <h2 class="subtitle">
            After submitting button you will be processed to Paypal payment site.
          </h2>
        </div>
      </div>
    </section>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
        <h2 class="subtitle">Months: {{ months }}</h2>
        <h2 class="subtitle">
          Devices:
          <div v-for="device in devices" :key="device.name">
            {{ device.value.display_name ? device.value.display_name : "no name" }}
          </div>
        </h2>
        <br />
        <br />
        <h3 class="title">SUM: {{ calcSum }} $</h3>

        <br />
        <br />
        </div>
        <div class="container">
          <button class="clear-button" id="paypal-button"></button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import obj2arg from "graphql-obj2arg";

export default {
  name: "payment-summary",
  created() {
    if (!this.months || !this.devices) {
      console.log("no props, redirect.");
      this.$toastr.e("No devices selected. Redirect to /devices.");
      this.$router.push("/devices");
    }
  },

  computed: {
    calcSum() {
      return this.devices ? this.devices.length * 5 * this.months : 0; //device monthly cost
    }
  },
  props: ["months", "devices"],
  data() {
    return {};
  },

  mounted() {
    var self = this;
    paypal.Button.render(
      {
        env: "sandbox", // Optional: specify 'sandbox'  environment
        client: {
          sandbox:
            "AUslArceaIEgsFD7SM3DhbYtN7b1OoH9uaP3quUaal80lF8Zm95mMelhWsfu6anuvMUik5DDoV6vHKr8",
          production:
            "AUslArceaIEgsFD7SM3DhbYtN7b1OoH9uaP3quUaal80lF8Zm95mMelhWsfu6anuvMUik5DDoV6vHKr8"
        },
        locale: "en_US",
        style: {
          size: "large",
          color: "gold",
          shape: "pill",
          label: "checkout",
          tagline: "true"
        },
        commit: true, // Optional: show a 'Pay Now' button in      the checkout flow

        payment: function(resolve, reject) {
          let devices_ids = [];
          self.devices.forEach(element => devices_ids.push(parseInt(element.name)));

          return new Promise((resolve, reject) => {
            const query = {
              query: `  {
              paypal_payment (months: ${
                self.months
              }, devices: [${devices_ids}], return_url: "http://eposter.cloud/app/payment-succesfull", cancel_url: "http://eposter.cloud/app/payment-cancelled")
            }
            `
            };
            axios
              .post(global.backendUrl, query)
              .then(function(response) {
                if (response.data && response.data.data && response.data.data.paypal_payment) {
                  const answer = JSON.parse(response.data.data.paypal_payment);
                  resolve(answer.id);
                }
              })
              .catch(function(error) {
                self.$toastr.e("Payment error.");
                reject(error);
              });
          });
        },
        onAuthorize: function(data) {
          return new Promise((resolve, reject) => {
            const query = {
              query: `  {
              execute_paypal(payer_id: "${data.payerID}", payment_id: "${data.paymentID}")
            }
            `
            };
            axios
              .post(global.backendUrl, query)
              .then(function(response) {
                if (response.data && response.data.data && response.data.data.execute_paypal) {
                  if (response.data.data.execute_paypal === "success") {
                    self.$toastr.s("Payment succesfull.");
                    self.$router.push("/payment-succesfull");
                  }
                  resolve(true);
                }
              })
              .catch(function(error) {
                self.$toastr.e("Payment error.");
                reject(error);
              });
          });
          // Execute the payment here, when the buyer approves    the transaction

          // return new Promise((resolve, reject) => {
          //   axios
          //     .post(
          //       "/execute-paypal",
          //       {
          //         payer_id: data.payerID,
          //         payment_id: data.paymentID
          //       },
          //       {
          //         headers: { Authorization: "Bearer " + state.token }
          //       }
          //     )
          //     .then(res => {
          //       resolve(res);
          //     })
          //     .catch(error => {
          //       reject(error);
          //     });
          // });
        }
      },
      "#paypal-button"
    );
  },
  methods: {
    // dummyPayment() {
    //   let devices_ids = [];
    //   this.devices.forEach(element => devices_ids.push(parseInt(element.name)));
    //   const query = {
    //     query: `  {
    //           paypal_payment (months: ${
    //             this.months
    //           }, devices: [${devices_ids}], return_url: "http://eposter.cloud/api/payment_result")
    //         }
    //         `
    //   };
    //   axios
    //     .post(global.backendUrl, query)
    //     .then(function(response) {
    //       if (response) {
    //         if (response.data && response.data.data && response.data.data.paypal_payment)
    //           console.log(response.data.data.paypal_payment);
    //         console.log(JSON.parse(response.data.data.paypal_payment));
    //         // resolve(res.data.id);
    //         // self.entities = response.data.data.devices.items;
    //         // self.pagination.total = response.data.data.devices.paging.total;
    //       }
    //     })
    //     .catch(function(error) {
    //       self.$toastr.e("Payment error.");
    //       reject(error);
    //     });
    // }
  }
};
</script>