<template>
  <div>
    <appearance :activeElement="activeElement" :tab="activeTab"></appearance>
  </div>
</template>

<script>
import style from "./style.vue";

export default {
  components: {
    appearance: style
  },

  data() {
    return {
      activeTab: 1
    };
  },

  computed: {
    activeElement() {
      return this.$store.state.activeElement;
    }
  }
};
</script>

<style lang="scss">
.panel-tab {
  padding: 0;
}

.panel-row {
  display: flex;
  font-size: 12px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  line-height: 18px;
  // min-height: 70px;
  display: flex;
  align-items: center;
}

.panel-slider-wrap {
  flex: 1 1 100%;
  padding: 0 0;
  opacity: 1;
  transition: opacity 0.3s;
}
.panel-row:hover .panel-slider-wrap {
  opacity: 1;
}
.panel-cell {
  flex-grow: 1;
}

.panel-preview {
  width: 50px;
  height: 32px;
  background: no-repeat center/100%;
  cursor: pointer;
}
</style>
