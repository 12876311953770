import Vue from 'vue'
import App from './App.vue'
import Toastr from 'vue-toastr'
import '.././assets/css/app.scss'
import slider from './components/slider.vue'

import router from './router'
import axios from 'axios'
import Buefy from 'buefy'
import store from './store'

import obj2arg from "./utils/obj2arg.js";

Vue.use(obj2arg)
Vue.use(Buefy, {
  defaultIconPack: 'fa'
})
Vue.component('vue-toastr', Toastr)
Vue.component('slider', slider)
Vue.use(Toastr, {
  defaultTimeout: 5000,
  defaultPosition: "toast-top-left",
});

axios.defaults.headers['Accept'] = 'application/json'
axios.defaults.headers['Content-Type'] = 'application/json'

// AXIOS LOADER
axios.interceptors.request.use(function (config) {
  store.commit('axiosLoading', true) // vuex mutation set loading state to true
  return config
}, function (error) {
  return Promise.reject(error)
})
axios.interceptors.response.use(function (config) {
  store.commit('axiosLoading', false) // vuex mutation set loading state to false
  return config
}, function (error) {
  console.log(error)
  return Promise.reject(error)
})
// AXIOS LOADER

axios.interceptors.response.use(
  response => {
    if (typeof response.data.errors !== 'undefined') {
      for (
        var index = 0;
        index < response.data.errors.length;
        ++index
      ) {
        if (response.data.errors[index].message) {
          if (response.data.errors[index].message.includes('Record not found in table')) {
            store.commit('errorsCatch', "Error. " + "Record not found in table.")
            // redirect('/404')
          } else if (response.data.errors[index].message.includes('Invalid input')) {
            var obj = response.data.errors[index].fields;
            var key = Object.keys(obj)[0]
            if (typeof Object.values(obj[key])[0] === 'object') {
              store.commit('errorsCatch', "Error. " + Object.values(Object.values(obj[key])[0])[0])
            } else {
              store.commit('errorsCatch', "Error. " + (Object.values(obj[key])[0]))

            }
          } else {
            store.commit('errorsCatch', "Error. " + response.data.errors[index].message)

          }
        }
      }
    }
    return response
  },
  error => {
    store.commit('axiosLoading', false)
    if (error.response.status === 401) {
      store.commit('errorsCatch', 'Not authorized')
      console.log('401')
      router.push('/')
      return error.response.data
    }
    if (error.response.status === 500) {
      console.log('500')
      if (error.response.data.message === 'Expired token') {
        store.commit('errorsCatch', '401')
        router.push('/')
        return error.response.data
      }
    }
    if (error.response.status === 404) {
      console.log('404')
      window.location.href = '/404'
      return error.response.data
    }
    return Promise.reject(error.response.data) // => gives me the server resonse
  }
)

Vue.config.productionTip = false
if (process.env.NODE_ENV === 'production') {
  window.imagesPlainFolder = ''
  window.imagesThumbnailsFolder = ''
  window.backendUrl = '/api/admin/'
  window.backendLoginUrl = '/api/'
  global.pageUrl = '/'
} else {
  window.axios = axios
  global.backendUrl = 'http://admedi/api/admin/'
  global.backendLoginUrl = 'http://admedi/api/'
  axios.defaults.baseURL = 'http://admedi'
  global.pageUrl = 'http://admedi/'
}

global.admediSets = [
  {
    id: '0',
    label: '0'
  },
  {
    id: '90',
    label: '+90'
  },
  {
    id: '180',
    label: '+180'
  },
  {
    id: '270',
    label: '+270'
  }
]

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App }
})
