import admediBg from './bg/index.vue'
import admediPic from './pic/index.vue'
import admediTxt from './txt/index.vue'
import admediContainer from './container/index.vue'
import admediFigure from './figure/index.vue'

var arr = {
  [admediBg.name]: admediBg,
  [admediPic.name]: admediPic,
  [admediTxt.name]: admediTxt,
  [admediContainer.name]: admediContainer,
  [admediFigure.name]: admediFigure
}

export default {
  arr
}

// exports.arr = arr
//  default {
//   [admediBg.name]: admediBg,
//   [admediPic.name]: admediPic,
//   [admediTxt.name]: admediTxt,
//   [admediContainer.name]: admediContainer
// }
