<template>
  <section class="my-account">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title">Eposter.cloud account</h1>
          <h2 class="subtitle">Account details</h2>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p class="title">
            {{ me.email }} <a @click="showModal = true" class="change-pass">change password</a>
          </p>
          <p class="subtitle">email</p>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p class="title">
            {{ formatBytes(me.used_space, 0) }} / {{ formatBytes(me.max_storage, 0) }}
          </p>
          <p class="subtitle">Used space</p>
        </div>
      </div>
      <!-- <div class="columns">
        <div class="column">
          <p class="title">12 12 2020</p>
          <p class="subtitle">Premium active till</p>
        </div>
      </div> -->
      <div class="columns">
        <div class="column">
          <p class="title" v-if="me && me.devices && me.devices.paging">
            {{ me.devices.paging.total }} / {{ me.max_devices }}
          </p>
          <p class="subtitle">Devices count</p>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p class="title" v-if="me && me.commercials && me.commercials.paging">
            {{ me.commercials.paging.total }}
          </p>
          <p class="subtitle">Commercials count</p>
        </div>
      </div>
    </div>
    <b-modal :active.sync="showModal" scroll="keep" :width="400">
      <div class="card tac">
        <div class="card-content">
          <div class="content">
            <h1>Change Password</h1>
            <b-field>
              <b-input v-model="newPass" placeholder="New password" type="password"></b-input>
            </b-field>
            <b-field>
              <b-input v-model="newPass2" placeholder="Repeat password" type="password"></b-input>
            </b-field>
            <button class="button is-primary is-medium m0a db" @click="editPass()">Save</button>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import Toastr from "vue-toastr";

export default {
  components: {
    "vue-toastr": Toastr
  },
  data() {
    return {
      showModal: false,
      newPass: null,
      newPass2: null,
      me: {},
      backendUrl: window.backendUrl
    };
  },
  mounted() {
    this.getMe();
  },
  methods: {
    getMe() {
      var self = this;
      let query = {
        query: `{
        logged_user{
            email
            used_space
            max_devices
            max_storage
            commercials{
              paging{
                total
              }
            }
            devices{
              paging{
                total
              }
            }
        }
      }`
      };

      axios
        .post(global.backendUrl, query)
        .then(function(response) {
          if (response.data && response.data.data && response.data.data.logged_user) {
            self.me = response.data.data.logged_user;
          } else if (response.data.errors) {
            for (var property in response.data.errors) {
              console.log(response.data.errors[property].message);
            }
          }
        })
        .catch(function(error) {
          self.$toastr.e("Contact the administrator " + error);
        });
    },
    editPassModal(userId) {
      this.userToEdit = userId;
      this.showModal = true;
    },

    editPass() {
      var self = this;
      if (self.newPass === "") {
        self.$toastr.e("Fill password.");
        return;
      }
      if (self.newPass.length < 6) {
        self.$toastr.e("Password should be at least 6 characters.");
        return;
      }
      if (self.newPass !== self.newPass2) {
        self.$toastr.e("Passwords does not match.");
        return;
      }

      const query = {
        query: `mutation {user_change_own_password (password: "${this.newPass}" )}`
      };
      axios
        .post(global.backendUrl, query)
        .then(function(response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              console.log(response.data.errors[property].message);
            }
          } else {
            self.$toastr.s("Changed.");
            self.showModal = false;
            self.newPass = null;
            self.newPass2 = null;
          }
        })
        .catch(function(error) {});
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0 || bytes === "0") return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
  }
};
</script>

<style lang="scss" scoped>
.mw400 {
  max-width: 400px;
}
.table__users {
  max-width: 600px;
  margin: 0 auto;
}
</style>
