<template>
  <section class="hero">
    <vue-toastr ref="toastr"></vue-toastr>
    <div class="hero-body">
      <div class="container has-text-centered max-width-400">
        <img src="/static/img/logo.png" alt="Image" class="inCointainerLogo" />

        <h2 class="title">Register</h2>
        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input class="input" type="email" placeholder="Email" v-model="credentials.email" />
            <span class="icon is-small is-left">
              <i class="fa fa-envelope"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input
              class="input"
              type="password"
              placeholder="Password"
              v-model="credentials.password"
            />
            <span class="icon is-small is-left">
              <i class="fa fa-lock"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input
              class="input"
              type="password"
              placeholder="Repeat password"
              v-model="password2"
            />
            <span class="icon is-small is-left">
              <i class="fa fa-lock"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <vue-recaptcha
            ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"
            :sitekey="recaptchaKey"
            :load-recaptcha-script="true"
          ></vue-recaptcha>
        </div>
        <div class="field">
          <p class="control">
            <button class="button is-success" @click="submit()">Register</button>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import Toastr from "vue-toastr";
import VueRecaptcha from "vue-recaptcha";
import swal from "sweetalert2";

export default {
  components: {
    "vue-toastr": Toastr,
    VueRecaptcha,
  },
  data() {
    return {
      backendUrl: window.backendUrl,
      backendLoginUrl: window.backendLoginUrl,
      credentials: {
        email: "",
        password: "",
      },
      password2: "",
      error: "",
      recaptchaKey: "6LdqIcQSAAAAAJOI90HJPTvB2ppsA5ysMYkpmw3n",
      recaptcha: false,
    };
  },
  methods: {
    onVerify(response) {
      this.recaptcha = response;
    },
    onExpired() {
      this.recaptcha = false;
    },

    asdfff() {
      var self = this;
    },
    submit() {
      var self = this;

      if (self.credentials.email === "") {
        self.$toastr.e("Fill email.");
        return;
      }
      var regex = /.+@.+\..+/;
      if (!regex.test(self.credentials.email)) {
        self.$toastr.e("Enter valid email");
        return;
      }
      if (self.credentials.password === "") {
        self.$toastr.e("Fill password.");
        return;
      }
      if (self.credentials.password.length < 6) {
        self.$toastr.e("Password should be at least 6 characters.");
        return;
      }
      if (self.credentials.password !== self.password2) {
        self.$toastr.e("Passwords does not match.");
        return;
      }
      if (!self.recaptcha) {
        self.$toastr.e("Accept recaptcha.");
        return;
      }
      const query = {
        query: `
            mutation {user_register (${self.$utils.myobj2arg(self.credentials)}, recaptcha: "${
          self.recaptcha
        }"){id}} `,
      };
      axios
        .post(global.backendLoginUrl, query)
        .then(function (response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else {
            swal
              .fire({
                title:
                  "Registration successful. To activate, please click link in the received e-mail.",
                type: "success",
                showCancelButton: false,
                confirmButtonText: "OK",
                showCloseButton: true,
                buttonsStyling: false,
                customClass: {
                  confirmButton: "button is-primary",
                  cancelButton: "button is-outlined m-l-2",
                },
              })
              .then((result) => {
                self.$refs.recaptcha.reset(); // resetowanie captchy
                self.recaptcha = false; // zrobienie zeby captcha miala byc wadlidowalna spowrotem
                self.$router.push({ name: "admLogin" });
              });
          }
          self.$refs.recaptcha.reset(); // resetowanie captchy
          self.recaptcha = false; // zrobienie zeby captcha miala byc wadlidowalna spowrotem
        })
        .catch(function (error) {
          self.$refs.recaptcha.reset(); // resetowanie captchy
          self.recaptcha = false; // zrobienie zeby captcha miala byc wadlidowalna spowrotem
          self.$toastr.e("Contact the administrator " + error);
        });
    },
  },
};
</script>
