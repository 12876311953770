<template>
  <div>
    <!-- <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        <font-picker
          class="panel__select"
          :api-key="'AIzaSyByxPtxk2Oc3uWsZibmnvsJ2IIfHQhtdyM'"
          :active-font="activeElement.fontFamily"
          @change="changeFont($event)"
        ></font-picker>
      </div>
    </div>-->

    <div class="panel-row" flex>
      <div @click="openUploader()" class="panel__button">font</div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        animation
        <select
          v-model="activeElement.widgetAnimationClass"
          class="panel__select"
          @change="changeAnimation"
        >
          <option value>none</option>
          <option v-for="(val, index) in animationNames" :key="index" :value="val">
            {{ val }}
          </option>
        </select>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="activeElement.widgetAnimationClass">
        <div class="panel-row" flex>
          <div class="field">
            <b-checkbox v-model="activeElement.widgetAnimationIterationCount">loop</b-checkbox>
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>delay</span>
            <div><input class="panel-input" v-model="activeElement.widgetAnimationDelay" /> s</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="0.1" :max="10" :min="0" v-model="activeElement.widgetAnimationDelay" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>duration</span>
            <div>
              <input class="panel-input" v-model="activeElement.widgetAnimationDuration" /> s
            </div>
          </div>

          <div class="panel-slider-wrap">
            <slider
              :step="0.1"
              :max="10"
              :min="0"
              v-model="activeElement.widgetAnimationDuration"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="panel-row" flex title="font color">
      <div class="panel-value">
        <div class="panel__color">
          <input type="color" v-model="activeElement.color" />
        </div>
        <input class="panel-input" v-model="activeElement.color" />
      </div>
    </div>

    <div class="panel-row" flex>
      <div
        class="cp panel__custom-input"
        v-bind:class="{ activeButton: activeElement.fontWeight === true }"
        @click="activeElement.fontWeight = !activeElement.fontWeight"
      >
        <span class="panel--bold">B</span>
      </div>
      <div
        class="cp panel__custom-input"
        v-bind:class="{ activeButton: activeElement.fontStyleItalic === true }"
        @click="activeElement.fontStyleItalic = !activeElement.fontStyleItalic"
      >
        <span class="panel--italic">I</span>
      </div>
      <div
        class="cp panel__custom-input"
        v-bind:class="{ activeButton: activeElement.textDecorationUnderline === true }"
        @click="activeElement.textDecorationUnderline = !activeElement.textDecorationUnderline"
      >
        <span class="panel--underline">U</span>
      </div>
    </div>

    <div class="panel-row panel-row__radios" flex>
      <label class="form-radio tooltip" data-tooltip="Left">
        <input
          type="radio"
          name="align"
          value="left"
          class="panel__radio"
          v-model="activeElement.textAlign"
        />

        <b-icon pack=" fa fa-align-left" />
      </label>
      <label class="form-radio tooltip" data-tooltip="Center">
        <input
          type="radio"
          name="align"
          value="center"
          class="panel__radio"
          v-model="activeElement.textAlign"
        />
        <b-icon pack=" fa fa-align-center" />
      </label>
      <label class="form-radio tooltip" data-tooltip="Right">
        <input
          type="radio"
          name="align"
          value="right"
          class="panel__radio"
          v-model="activeElement.textAlign"
        />
        <b-icon pack=" fa fa-align-right" />
      </label>
      <div
        class="cp panel__custom-input"
        v-bind:class="{ activeButton: activeElement.textTransformUppercase === true }"
        @click="activeElement.textTransformUppercase = !activeElement.textTransformUppercase"
      >
        <span>aA</span>
      </div>
    </div>

    <div class="panel-row" flex>
      <div class="panel-label panel-label--input">
        <span>size</span>
        <div>
          <input class="panel-input" v-model="activeElement.fontSize" />
        </div>
      </div>

      <div class="panel-slider-wrap">
        <slider :step="1" :max="360" :min="0" v-model="activeElement.fontSize" />
      </div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-label panel-label--input">
        <span>line height</span>
        <div>
          <input class="panel-input" v-model="activeElement.lineHeight" />
        </div>
      </div>

      <div class="panel-slider-wrap">
        <slider :step="0.1" :max="5" :min="0" v-model="activeElement.lineHeight" />
      </div>
    </div>

    <div class="panel-row" flex>
      <div class="panel-label panel-label--input">
        <span>letter spacing</span>
        <div>
          <input class="panel-input" v-model="activeElement.letterSpacing" />
        </div>
      </div>

      <div class="panel-slider-wrap">
        <slider :step="0.1" :max="100" :min="0" v-model="activeElement.letterSpacing" />
      </div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-label">transparency</div>
      <div class="panel-value">{{ Math.round(activeElement.opacity * 100) }} %</div>
      <div class="panel-slider-wrap">
        <slider :step="0.01" :max="1" :min="0" v-model="activeElement.opacity" />
      </div>
    </div>
    <!-- <div class="panel-row" flex>
      <div class="panel-label panel-label--input">
        <span>rotation</span>
        <div>
          <input class="panel-input" v-model="activeElement.rotate" />
        </div>
      </div>

      <div class="panel-slider-wrap">
        <slider v-model="activeElement.rotate" :step="1" :max="360" />
      </div>
    </div>-->
    <br />
    <div class="panel-row" flex>
      <div class="panel-value panel__select__container">
        text shadow:
        <select v-model="activeElement.enableShadow" class="panel__select">
          <option value>none</option>
          <option value="true">enable</option>
        </select>
      </div>
    </div>

    <transition name="slide-fade">
      <div v-if="activeElement.enableShadow">
        <div class="panel-row" flex title="background color">
          <div class="panel-value">
            <div class="panel__color">
              <input type="color" v-model="activeElement.widgetShadowColor" rgba />
            </div>
            <input class="panel-input" v-model="activeElement.widgetShadowColor" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>blur</span>
            <div><input class="panel-input" v-model="activeElement.widgetShadowBlur" /> px</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetShadowBlur" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>x</span>
            <div><input class="panel-input" v-model="activeElement.widgetShadowX" /> px</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetShadowX" />
          </div>
        </div>

        <div class="panel-row" flex>
          <div class="panel-label panel-label--input">
            <span>y</span>
            <div><input class="panel-input" v-model="activeElement.widgetShadowY" /> px</div>
          </div>

          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetShadowY" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label">opacity</div>
          <div class="panel-value">{{ Math.round(activeElement.widgetShadowOpacity * 100) }} %</div>
          <div class="panel-slider-wrap">
            <slider :step="0.01" :max="1" :min="0" v-model="activeElement.widgetShadowOpacity" />
          </div>
        </div>
      </div>
    </transition>

    <div class="panel-row" flex>
      <div class="panel-label panel__menu__buttons">
        <div class="panel__menu__button" @click="levelup()">
          <i class="fa fa-level-up"></i>
        </div>
        <div class="panel__menu__button" @click="leveldown()">
          <i class="fa fa-level-down"></i>
        </div>
        <div class="panel__menu__button" @click="clone()">
          <i class="fa fa-clone"></i>
        </div>
        <div class="panel__menu__button" @click="deleteThis()">
          <b-icon pack=" fa fa-trash" />
        </div>
      </div>
    </div>
    <!-- <h3 class="showMore cp" @click="showMore =! showMore">Advanced</h3>
    <div v-if="showMore">
      <div class="panel-row">
        <div class="panel-label">Component container</div>
        <div class="panel-value">
          <select v-model="activeElement.belong">
            <option>page</option>
            <option v-for="(val, index) in containerName" :key="index">{{ val }}</option>
          </select>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import swal from "sweetalert2";
// import FontPicker from "font-picker-vue";
export default {
  name: "admedi-txt-style",
  props: ["activeElement"],
  data() {
    return {
      showMore: false,
      // fontFamily: "Open Sans",
      options: {
        name: "",
      },
    };
  },
  components: {
    // FontPicker
  },
  mounted() {},
  computed: {
    animationNames() {
      var arr = [];
      this.$store.state.animation.map((val) => arr.push(val.name));

      return arr;
    },
    containerName() {
      var arr = [];
      this.$store.state.widgets.map((val) => val.isContainer && val.name && arr.push(val.name));

      return arr;
    },
  },

  methods: {
    openUploader() {
      this.$store.commit("setUploaderType", ["Enum::FONT"]);
      this.$store.commit("doUploader", true);
    },
    changeAnimation() {
      if (this.activeElement.widgetAnimationClass.indexOf("fadeIn") !== -1) {
        this.activeElement.opacity = 0;
      } else {
        this.activeElement.opacity = 1;
      }
    },
    changeFont(a) {
      // this.activeElement.fontFamily = a.family;
    },
    deleteThis() {
      var self = this;
      swal
        .fire({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            self.$store.commit("delete");
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },
    clone() {
      this.$store.commit("copy");
    },
    levelup() {
      this.$store.commit("levelup");
    },
    leveldown() {
      this.$store.commit("leveldown");
    },
  },
};
</script>