<template>
  <div class="thisIsDesigner">
    <div class="designerContent" id="designerContent__scroll-container">
      <div class="container fluid">
        <div class="columns col-gapless designerContent__col-gapless">
          <div class="viewport column" v-if="currentSlider">
            <viewport :zoom="zoom" :currentSliderIsLogo="currentSliderIsLogo"></viewport>
            <div class="zoom-wrap">
              <i class="fa fa-search-plus" @click="dozoom(10)"></i>
              <div class="zoom-value">{{ zoom }}%</div>
              <i class="fa fa-search-minus" @click="dozoom(-10)"></i>
            </div>
          </div>
          <div @click="hidePanel = !hidePanel" v-if="hidePanel" class="showPanel">
            <i class="fa fa-play"></i>
          </div>
          <div class="column pr" v-if="!hidePanel" id="column-panel">
            <div v-if="currentSlider" id="the-panel">
              <div class="menu-panel">
                <b-dropdown aria-role="list" class="cp">
                  <div slot="trigger">
                    <i class="fa fa-plus"></i>
                  </div>

                  <b-dropdown-item
                    aria-role="listitem"
                    class="designer-dropdown"
                    v-for="item in widgets"
                    :key="item.name"
                    v-bind:class="{ hideFromMenu: item.name === 'admedi-container' }"
                  >
                    <div @click="openFilesModal(item, item.title)" v-if="item.setting.isBackground">
                      + {{ item.title }}
                    </div>
                    <div @click="openFilesModal(item, item.title)" v-if="item.setting.isUpload">
                      + {{ item.title }}
                    </div>
                    <div @click="openFigureModal(item, item.title)" v-if="item.setting.isFigure">
                      + {{ item.title }}
                    </div>
                    <div
                      :id="item.name === 'admedi-container' ? 'add-container-href' : ''"
                      @click="
                        (e) => {
                          addWidget(e, item);
                        }
                      "
                      v-if="
                        !item.setting.isUpload &&
                        !item.setting.isFigure &&
                        item.name !== 'admedi-bg'
                      "
                    >
                      + {{ item.title }}
                    </div>
                  </b-dropdown-item>

                  <hr class="menu-panel-hr" />
                  <b-dropdown-item
                    id="saveAsTemplate"
                    class="menu-panel__bottom"
                    aria-role="listitem"
                    @click="createImgFromHtml(true)"
                    >save as template</b-dropdown-item
                  >
                </b-dropdown>
                <div @click="restartAnimation()" class="cp">
                  <i class="fa fa-play"></i>
                </div>

                <div
                  class="cp"
                  @click="createImgFromHtml()"
                  v-bind:class="{ designerEdited: currentIndex > 0 }"
                >
                  <i class="fa fa-save"></i>
                </div>
                <div class="cp" @click="closeDesignerCancel()">
                  <i class="fa fa-times"></i>
                </div>
                <!-- <div @click="hidePanel = !hidePanel" v-if="!hidePanel"><i class="fa fa-play"></i></div> -->
              </div>

              <div id="myProgress">
                <div id="myBar"></div>
              </div>
            </div>
            <panel class="control-panel" v-if="currentSlider"></panel>
          </div>
        </div>
      </div>

      <div id="playground"></div>
    </div>
    <div class="shade"></div>
    <transition name="slide-fade">
      <admediuploader
        v-bind:imageCategories="imageCategories"
        v-if="openUploader"
        :thisIsBg="true"
        @changeSlideDuration="changeSlideDuration = $event"
      ></admediuploader>
    </transition>
    <transition name="slide-fade">
      <admedifigures
        v-on:closeFigure="isFigureUploaderActive = false"
        v-if="isFigureUploaderActive"
      ></admedifigures>
    </transition>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import swal from "sweetalert2";
import widget from "./../plugins/widget";
import viewport from "./../components/viewport/index.vue";
import panel from "./../components/panel/index.vue";
import admediuploader from "./admUploader.vue";
import admedifigures from "./admFigures.vue";

import moment from "moment";
var html2canvas = require("html2canvas");

import obj2arg from "graphql-obj2arg";

export default {
  name: "admedi-designer",
  props: ["commercial", "slider", "sliderKey"],
  data() {
    return {
      showWidgets: false,
      hidePanel: false,
      history: [],
      currentIndex: -1,
      disableHistory: false,
      imageCategories: [],
      backendUrl: global.pageUrl,
      currentItem: {},
      currentSliderIsTemplate: false,
      currentSliderIsLogo: false,
      changeSlideDuration: false,
      isFigureUploaderActive: false,
    };
  },
  components: {
    panel,
    viewport,
    admediuploader,
    admedifigures,
  },
  created() {
    Vue.use(widget, {
      widgets: this.widgets,
    });
    if (this.value) {
      this.$store.replaceState(this.value);
    }
    this.$store.$on("save", () => {
      this.$emit("save", this.$store.state);
    });
  },
  mounted() {
    this.setSlider(this.slider);
    this.currentSliderIsTemplate = this.slider.is_template;
    this.currentSliderIsLogo = this.slider.is_logo;
    this.getImageCategories();

    if (this.commercial.is_portrait) {
      this.$store.commit("setPageHeight", "1920");
      this.$store.commit("setPageWidth", "1080");
      if (window.innerHeight > 1920) {
        this.$store.commit("zoom", 100);
      } else {
        let ratio = Math.round(((window.innerHeight - 53) / 1920) * 100);
        ratio = ratio > 100 ? 100 : ratio;
        this.$store.commit("zoom", ratio);
      }
    } else {
      if (window.innerWidth - 320 > 1920) {
        this.$store.commit("zoom", 100);
      } else if (window.innerWidth > 320) {
        let ratio = Math.round(((window.innerWidth - 320) / 1920) * 100);
        ratio = ratio > 100 ? 100 : ratio;
        this.$store.commit("zoom", ratio);

        setTimeout(function () {
          document.getElementById("designerContent__scroll-container").scrollLeft = 0;
          document.getElementById("designerContent__scroll-container").scrollLeft =
            document.getElementById("screen").getBoundingClientRect().left -
            (document.getElementById("designerContent__scroll-container").getBoundingClientRect()
              .width -
              200 -
              document.getElementById("screen").getBoundingClientRect().width) /
              2;
        }, 100);
      }

      this.$store.commit("setPageHeight", "1080");
      this.$store.commit("setPageWidth", "1920");
    }
    let container_scroll = document.getElementById("designerContent__scroll-container");
    let width_offet = container_scroll.offsetWidth;
    container_scroll.scrollLeft = document.getElementById("viewport").offsetWidth - width_offet;

    // initActive creates background, disable it for logo
    if (!this.slider.is_logo) {
      this.$store.commit("initActive");
    }
    document.getElementById("designerContent__scroll-container").scrollTop = 0;
    // document.getElementById("viewport").scrollTop = 0;

    document.documentElement.style.overflow = "hidden";

    document.addEventListener("keyup", this.keyupHandler);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.keyupHandler);
    document.documentElement.style.overflow = "auto";
  },
  methods: {
    getImageCategories() {
      var self = this;
      const query = {
        query: `  {
        categories{
            id
            name
          }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else {
            self.imageCategories = response.data.data.categories;
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    openFilesModal(item) {
      console.log("designerOpenFilesModal");

      this.$store.commit("setUploaderType", ["Enum::IMAGE", "Enum::VIDEO"]);

      this.$store.commit("uploadItem", item);
      this.$store.commit("doUploader", true);
    },
    openFigureModal() {
      self.showWidgets = false;
      this.isFigureUploaderActive = true;
    },
    closeFigureModal() {
      this.isFigureUploaderActive = false;
    },
    // getResizedCanvas(canvas, newWidth, newHeight) {
    //   var tmpCanvas = document.createElement("canvas");
    //   tmpCanvas.width = newWidth;
    //   tmpCanvas.height = newHeight;

    //   var ctx = tmpCanvas.getContext("2d");
    //   ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

    //   return tmpCanvas;
    // },
    setSlider(slider) {
      var self = this;

      if (typeof slider.id !== "undefined") {
        self.$store.commit("currentSlider", slider.id);
      } else {
        self.$store.commit("currentSlider", slider);
      }
      let contentJson = slider.content_json;
      contentJson = JSON.parse(contentJson);

      self.$store.commit("setCurrentSliderState", contentJson);
      let fontsArray = [];

      for (var widget of contentJson.widgets) {
        if (widget.fontFamily) {
          if (!document.getElementById(widget.fontFamily)) {
            //append font only once
            let fontName = widget.fontFamily;
            var head = document.getElementsByTagName("head")[0];
            var style = document.createElement("style");
            style.id = widget.fontFamily;
            var fontDeclaration =
              "@font-face {font-family: '" +
              fontName +
              "'; src: url('" +
              widget.fontFamilyPath +
              "');}";
            head.appendChild(style);
            style.type = "text/css";
            if (style.styleSheet) {
              // This is required for IE8 and below.
              style.styleSheet.cssText = fontDeclaration;
            } else {
              style.appendChild(document.createTextNode(fontDeclaration));
            }
          }
          // fontsArray.push(widget.fontFamily);
        }
      }
    },
    dozoom(val) {
      const newVal = this.zoom + val;
      if (newVal >= 10 && newVal <= 100) {
        this.$store.commit("zoom", newVal);
      } else if (this.zoom >= 90 && this.zoom <= 100) {
        this.$store.commit("zoom", 100);
      }
    },
    addWidget(e, item, imgUrl, mediaType, mediaId) {
      console.log("addWidget - from designer.vue");
      this.showWidgets = false;
      // this.isComponentModalActive = false;
      if (imgUrl) {
        item.imgUrl = imgUrl;
      }
      if (mediaType) {
        item.mediaType = mediaType;
      }
      if (mediaId) {
        console.log(mediaId);
        item.mediaId = mediaId;
      }
      if (item.mediaType === "thisIsVid" && item.name === "admedi-bg") {
        // if (item.mediaType === "thisIsVid") {
        this.$toastr.s("Adjusts the slide duration to the duration of the video");
        this.changeSlideDuration = document.querySelector(
          "source[src='" + imgUrl + "']"
        ).parentNode.duration;
      }

      if (item.mediaType === "thisIsVid" && item.name === "admedi-pic") {
        var vid = document.getElementById(e.target.id + "vid");
        item.height = vid.videoHeight; // returns the intrinsic height of the video
        item.width = vid.videoWidth; // returns the intrinsic width of the video
      }

      if (item.name === "admedi-txt") {
        // center text element
        if (this.pageHeight === "1080") {
          item.setting.left = 700;
          item.setting.top = 500;
        } else {
          item.setting.left = 200;
          item.setting.top = 600;
        }
      }
      this.$store.dispatch("addWidget", item);
    },
    closeDesignerCancel() {
      var self = this;
      if (self.currentIndex > 0) {
        swal
          .fire({
            title: "Your slide has not been saved.",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Save and close",
            cancelButtonText: "Close, without saving",
            showCloseButton: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: "button is-primary",
              cancelButton: "button is-outlined m-l-2",
            },
          })
          .then((result) => {
            if (result.value) {
              self.createImgFromHtml();
            } else {
              self.$store.commit("initActivePage");
              self.$emit("closeDesignerCancel", this.commercial.id);
            }
          });
      } else {
        self.$store.commit("initActivePage");
        self.$emit("closeDesignerCancel", this.commercial.id);
      }
    },
    closeDesigner(blob, toDataURLImage) {
      var self = this;

      this.$store.commit("initActivePage");
      // let slide = self.commercial.sliders.filter((obj) => {
      //   return obj.id === self.entitySlideId;
      // });
      // if (!slide) {
      //   slide = self.commercial.sliders.filter((obj) => {
      //     return obj.id === String(self.entitySlideId);
      //   });
      // }
      let slide = this.slider;
      let contentHtml = document.getElementById("screen");
      contentHtml = contentHtml.innerHTML;
      const contentJson = {
        page: self.page,
        widgets: this.$store.state.widgets,
        // logo:
      };
      let contentJsonString = JSON.stringify(contentJson);

      const closeDesignerEmit = {
        commercialId: self.commercial.id,
        // slideId: self.entitySlideId,
        slide: {
          name: slide.name,
          commercial_id: self.commercial.id,
          content_html: contentHtml,
          content_json: contentJsonString,
          date_from: slide.date_from !== null ? slide.date_from : "00.00.0000",
          date_to: slide.date_to !== null ? slide.date_to : "00.00.0000",
          duration: self.changeSlideDuration
            ? moment.utc(self.changeSlideDuration * 1000).format("HH:mm:ss")
            : slide.duration,
          filename: slide.filename,
          is_period: slide.is_period,
          transition: slide.transition,
          is_template: self.currentSliderIsTemplate,
          is_logo: slide.is_logo,
          is_under: slide.is_under,
          is_dates: slide.is_dates,
          path: slide.path,
          period_json: slide.period_json,
          was_opened: true,
          blob: blob,
          toDataURLImage: toDataURLImage,
          order_number: slide.order_number,
        },
      };
      if (slide.id) {
        closeDesignerEmit.slide.id = slide.id;
      }
      this.$store.commit("axiosLoading", false);
      this.$emit("closeDesigner", closeDesignerEmit, this.sliderKey);
    },
    keyupHandler(event) {
      if (event.ctrlKey && event.code === "KeyZ") {
        this.undoWidgets();
      } else if (event.ctrlKey && event.code === "KeyY") {
        this.redoWidgets();
      } else if (event.ctrlKey && event.code === "KeyC") {
        this.doCopy();
      } else if (event.ctrlKey && event.code === "KeyV") {
        this.doPaste();
      }
    },
    undoWidgets() {
      var self = this;
      if (typeof self.history[self.currentIndex - 1] !== "undefined") {
        console.log("going to" + (this.currentIndex - 1));
        self.disableHistory = true;
        let newState = this.history[this.currentIndex - 1];
        this.$store.dispatch("doUndoRedoWidgets", { newState }).then(
          (response) => {
            this.currentIndex--;
          },
          (error) => {}
        );
      }
    },
    redoWidgets() {
      var self = this;
      if (typeof self.history[self.currentIndex + 1] !== "undefined") {
        self.disableHistory = true;
        let newState = this.history[this.currentIndex + 1];
        this.$store.dispatch("doUndoRedoWidgets", { newState }).then(
          (response) => {
            this.currentIndex++;
          },
          (error) => {}
        );
      }
    },
    doCopy() {
      this.$store.commit("ctrlC");
      console.log("doCopy");
    },
    doPaste() {
      this.$store.commit("ctrlV");
    },
    restartAnimation() {
      var self = this;
      let slide = this.slider;

      let foreground = self.commercial.sliders.find((obj) => {
        return obj.is_logo;
      });
      let background = self.commercial.sliders.find((obj) => {
        return obj.is_under;
      });

      var p = document.getElementById("screen");
      if (foreground) {
        const newElement = document.createElement("div");
        newElement.setAttribute("id", "foreground");
        newElement.innerHTML = foreground.content_html;
        p.appendChild(newElement);
      }
      if (background) {
        const newElement = document.createElement("div");
        newElement.setAttribute("id", "background");
        newElement.innerHTML = background.content_html;
        p.appendChild(newElement);
      }
      const duration = this.getDurationInSeconds(slide.duration);
      var elem = document.getElementById("myBar");
      var end = Date.now() + duration;
      var frame = () => {
        var timeleft = Math.max(0, end - Date.now());
        elem.classList.add("activeBar");
        elem.style.width = 100 - (100 * timeleft) / duration + "%";
        // elem.innerHTML = (timeleft / 1000).toFixed(1) + "s";
        if (timeleft === 0) {
          elem.classList.remove("activeBar");
          elem.style.width = "100%";
          return;
        }
        requestAnimationFrame(frame);
      };
      requestAnimationFrame(frame);

      self.restartAnimations("screen");
      self.restartVideos("screen");
      setTimeout(function () {
        var element = document.getElementById("foreground");
        if (element) {
          element.parentNode.removeChild(element);
        }
        var element2 = document.getElementById("background");
        if (element2) {
          element2.parentNode.removeChild(element2);
        }
      }, duration);

      let a = document.querySelectorAll("[data-animation]");
      for (let i = 0; i < a.length; i++) {
        let animatedElement = a[i];
        let className = animatedElement.getAttribute("data-animation");
        if (className) {
          animatedElement.classList.remove(className);
          setTimeout(function () {
            animatedElement.classList.add(className);
          }, 1);
        }
      }
    },
    restartAnimations(elementId) {
      var currentSlide = document.getElementById(elementId);
      if (currentSlide) {
        let a = currentSlide.querySelectorAll("[data-animation]");
        for (let i = 0; i < a.length; i++) {
          let animatedElement = a[i];
          let className = animatedElement.getAttribute("data-animation");
          if (className) {
            animatedElement.classList.remove(className);
            setTimeout(function () {
              animatedElement.classList.add(className);
            }, 100);
          }
        }
      }
    },
    restartVideos(elementId) {
      var currentSlide = document.getElementById(elementId);
      if (currentSlide) {
        var vids = currentSlide.getElementsByTagName("video");
        for (var i = 0; i < vids.length; i++) {
          var theVideo = vids.item(i);
          theVideo.currentTime = 0;
          theVideo.muted = true;
          theVideo.play();
          if (elementId === "thisIsUnder" || elementId === "thisIsLogo") {
            theVideo.loop = false;
            theVideo.addEventListener(
              "ended",
              function () {
                theVideo.currentTime = 0.1;
                theVideo.play();
              },
              false
            );
          }
        }
      }
    },

    createImgFromHtml(makeSlideTemplate) {
      var self = this;
      self.$store.commit("zoom", 100);
      self.$store.commit("select", {
        uuid: -1,
      });
      self.$store.commit("axiosLoading", true);
      // in case of playing state of slide, delete logo and background before creating image
      var element = document.getElementById("foreground");
      if (element) {
        element.parentNode.removeChild(element);
      }
      var element2 = document.getElementById("background");
      if (element2) {
        element2.parentNode.removeChild(element2);
      }

      setTimeout(function () {
        var d = document.getElementById("screen");
        var e_width = d.offsetWidth;
        var e_height = d.offsetHeight;
        var e_x_offset = window.scrollX + d.getBoundingClientRect().left;
        // e_x_offset = 0;
        var e_y_offset = window.scrollY + d.getBoundingClientRect().top;
        // e_y_offset = 0;
        console.log(e_width);
        console.log(e_height);
        html2canvas(document.querySelector("#screen"), {
          logging: false,
          allowTaint: false,
          useCORS: true,
          scrollX: 0,
          // backgroundColor: "rgba(0,0,0,0)",
          scrollY: 0,
          // scale: 1,
          width: e_width,
          height: e_height,
          // windowWidth: self.commercial.is_portrait ? 1080 : 1920,
          // windowHeight: self.commercial.is_portrait ? 1920 : 1080,
          // x: e_x_offset,
          // y: e_y_offset,
        }).then((canvas) => {
          canvas.toBlob(function (blob) {
            if (makeSlideTemplate) {
              self.saveSlide(blob);
            } else {
              self.closeDesigner(blob, canvas.toDataURL());
            }
          });
        });
      }, 500);
    },
    saveSlide(blob) {
      var self = this;
      let slide = self.slider;
      // let slide = self.commercial.sliders.filter((obj) => {
      //   return obj.id === self.entitySlideId;
      // });
      // if (!slide) {
      //   slide = self.commercial.sliders.filter((obj) => {
      //     return obj.id === String(self.entitySlideId);
      //   });
      // }
      // slide = slide[0];
      let contentHtml = document.getElementById("screen");
      contentHtml = contentHtml.innerHTML;
      const contentJson = {
        page: self.page,
        widgets: this.$store.state.widgets,
        // logo:
      };
      let contentJsonString = JSON.stringify(contentJson);

      let slideToSave = {
        name: "szablon",
        content_html: contentHtml,
        content_json: contentJsonString,
        date_from: slide.date_from !== null ? slide.date_from : "00.00.0000",
        date_to: slide.date_to !== null ? slide.date_to : "00.00.0000",
        duration: self.changeSlideDuration
          ? moment.utc(self.changeSlideDuration * 1000).format("HH:mm:ss")
          : slide.duration,
        is_period: slide.is_period,
        transition: slide.transition,
        is_template: true,
        is_logo: slide.is_logo,
        is_under: slide.is_under,
        is_dates: slide.is_dates,
        is_portrait: self.commercial.is_portrait,
        period_json: slide.period_json,
        blob: blob,
        // toDataURLImage: toDataURLImage,
        order_number: 0,
      };

      self.$store.commit("axiosLoading", false);

      slideToSave.name = Math.round(+new Date() / 1000) + "slide";
      let a = new FormData();
      if (slideToSave.blob) {
        console.log("if (slideToSave.blob) ");
        //cloning slider which has no file saved
        var file = new File([slideToSave.blob], "name.png");
        delete slideToSave.blob;
        a.append("file", file);
        a.append(
          "mutation",
          `{
           slider(${obj2arg(slideToSave, { noOuterBraces: true })}, file: "file"){
           id
           }
         }`
        );
        axios
          .post(global.backendUrl, a)
          .then(function (response) {
            if (response.data.errors) {
              for (var property in response.data.errors) {
                self.$toastr.e(response.data.errors[property].message);
              }
            } else {
              self.$toastr.s("Template saved.");
            }
          })
          .catch(function (error) {
            self.$toastr.e("Contact the administrator " + error.message);
          });
      }
      //  else {
      //   //   //doesnt work on localhost because cors
      //   //   console.log("create img before save");
      //   var img = document.getElementById("slideImg");
      //   blobUtil
      //     .imgSrcToBlob(img.src)
      //     .then(function (blob) {
      //       var file = new File([blob], "name.png");
      //       a.append("file", file);
      //       a.append(
      //         "mutation",
      //         `{
      //           slider(${obj2arg(slideToSave, { noOuterBraces: true })}, file: "file"){
      //                 id
      //             }
      //           }`
      //       );
      //       axios
      //         .post(global.backendUrl, a)
      //         .then(function (response) {
      //           if (response.data.errors) {
      //             for (var property in response.data.errors) {
      //               self.$toastr.e(response.data.errors[property].message);
      //             }
      //           } else {
      //             self.$toastr.s("Template saved.");
      //           }
      //         })
      //         .catch(function (error) {
      //           self.$toastr.e("Contact the administrator " + error.message);
      //         });
      //     })
      //     .catch(function (err) {
      //       self.$toastr.e("Contact the administrator " + err.message);
      //       console.log(err.message);
      //     });
      // }
    },
    getDurationInSeconds(duration) {
      var pieces = duration.split(":"),
        hour,
        minute,
        second;
      if (pieces.length === 3) {
        hour = parseInt(pieces[0], 10);
        minute = parseInt(pieces[1], 10);
        second = parseInt(pieces[2], 10);
      } else {
        //in case of (00:00)
        console.info("no duration");
        hour = 0;
        minute = 0;
        second = 5;
      }
      return (second + minute * 60 + hour * 3600) * 1000;
    },
  },
  computed: {
    is_admin() {
      return this.$store.state.userIsAdmin;
    },
    zoom() {
      return this.$store.state.zoom;
    },
    userId() {
      return this.$store.state.userId;
    },
    currentSlider() {
      return this.$store.state.currentSlider;
    },
    widgets() {
      return widget.getWidgetsAdmedi();
    },
    layers() {
      return this.$store.state.widgets;
    },
    page() {
      return this.$store.state.page;
    },
    openUploader() {
      return this.$store.state.openUploader;
    },
    moving() {
      return this.$store.state.moving;
    },
    pageHeight() {
      return this.$store.state.pageHeight;
    },
    pageWidth() {
      return this.$store.state.pageWidth;
    },
  },
  watch: {
    layers: {
      handler: function (newWidgets) {
        if (!this.disableHistory && !this.moving) {
          if (this.currentIndex + 1 < this.history.length) {
            this.history.splice(this.currentIndex + 1);
          }
          let copy = JSON.parse(JSON.stringify(newWidgets));
          this.history.push(copy);
          this.currentIndex++;
        } else {
          this.disableHistory = false;
        }
      },
      deep: true,
    },
    moving: {
      handler: function (newMoving) {
        if (!this.disableHistory && !this.moving) {
          if (this.currentIndex + 1 < this.history.length) {
            this.history.splice(this.currentIndex + 1);
          }
          let copy = JSON.parse(JSON.stringify(this.layers));
          this.history.push(copy);
          this.currentIndex++;
        } else {
          this.disableHistory = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.body {
  width: 100%;
  height: calc(100% - 54px);
  overflow: hidden;
  flex-direction: row;
  &.container {
    padding: 0;
  }
}
.columns {
  height: 100%;
}
.toolbar,
.viewport {
  height: 100%;
}
.toolbar {
  background: #fff;
  user-select: none;
  box-sizing: content-box;
  &.column {
    flex: none;
    width: 120px;
  }
}
.viewport {
  height: auto;
  position: relative;
}

.zoom-wrap {
  color: #fff;
  border-radius: 50px;
  padding: 15px 10px;
  width: 43px;
  bottom: 43px;
  right: 293px;
  opacity: 1;
  background: #343434;
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  i {
    color: #fff;
    cursor: pointer;
  }
  .zoom-value {
    font-size: 12px;
  }
}
.viewport:hover .zoom-wrap {
  opacity: 1;
}
#svgspriteit {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.container.fluid {
  max-width: 100%;
}
.widgetsList {
  display: flex;
  flex-direction: column;
  li {
    color: #fff;
    cursor: pointer;
    span {
      margin-right: 20px;
    }
  }
}
.header {
  align-items: center;
  background: #282828;
  min-height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logoImg {
  height: 20px;
  width: 20px;
}

.littleThumb {
  width: 20px;
  height: 20px;
  display: block;
}
.slidersCol {
  padding-top: 25px;
  background: #eeeeee;
  width: 300px;
  padding: 5px;
}

.saveSlide {
  color: #fff;
  margin: 0 15px;
}
.closeSlide:visited,
.closeSlide {
  color: #fff;
  margin: 0 15px;
}
#playground {
  position: absolute;
  left: -10000px;
}
.commercialAddress {
  color: #fff;
}
.commercialAddress:visited {
  color: #fff;
}
.thisIsDesigner {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 985;
}
.zoom-value {
  margin: 10px;
}
.thisIsDesigner > div.designerContent {
  .designerContent__col-gapless {
    margin-left: 0;
    margin-right: 0;
    .viewport {
      padding: 0;
    }
  }
  background: #e6e6e6;
  position: relative;
  z-index: 991;

  width: 100%;
  height: 100%;
  overflow: auto;
  // overflow-x: hidden;
  > div {
    padding: 0;
  }
}
.thisIsDesigner > div.shade {
  background: rgba(0, 0, 0, 0.6);
  content: "";
  z-index: 990;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.disabled {
  opacity: 0.5;
}
.widgetsList {
  li {
    padding-top: 10px;
  }
}
.showPanel {
  cursor: pointer;
  i {
    -moz-transform: scaleX(-1); /* Gecko */
    -o-transform: scaleX(-1); /* Opera */
    -webkit-transform: scaleX(-1); /* Webkit */
    transform: scaleX(-1); /* Standard */

    filter: FlipH; /* IE 6/7/8 */
  }
}
</style>
