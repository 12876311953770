<template>
  <div v-show="elm">
    <div
      class="deleteThis"
      @mousedown="handlemousedown($event, 'delete')"
      :style="{
        top: elm.top + elm.height - 10 + 'px',
        left: elm.left + 80 + 'px',
      }"
    >
      <i class="fa fa-trash"></i>
    </div>
    <div
      class="cloneThis"
      @mousedown="handlemousedown($event, 'clone')"
      :style="{
        top: elm.top + elm.height - 10 + 'px',
        left: elm.left + 45 + 'px',
      }"
    >
      <i class="fa fa-clone"></i>
    </div>
    <div
      class="cloneThis"
      @mousedown="handlemousedown($event, 'leveldown')"
      :style="{
        top: elm.top + elm.height - 10 + 'px',
        left: elm.left + 10 + 'px',
      }"
    >
      <i class="fa fa-level-down"></i>
    </div>
    <div
      class="cloneThis"
      @mousedown="handlemousedown($event, 'levelup')"
      :style="{
        top: elm.top + elm.height - 10 + 'px',
        left: elm.left - 25 + 'px',
      }"
    >
      <i class="fa fa-level-up"></i>
    </div>

    <div
      class="verti"
      @mousedown="handlemousedown($event, 'left', 'left', 'width')"
      :style="{
        height: elm.height + 'px',
        top: elm.top + 'px',
        left: elm.left + 'px',
      }"
    >
      <div class="square first"></div>
    </div>

    <div
      class="verti"
      @mousedown="handlemousedown($event, 'right', 'width')"
      :style="{
        height: elm.height + 'px',
        top: elm.top + 'px',
        left: elm.left + elm.width + 'px',
      }"
    >
      <div class="square second"></div>
    </div>

    <div
      class="horiz"
      @mousedown="handlemousedown($event, 'up', 'top', 'height')"
      :style="{
        width: elm.width + 'px',
        top: elm.top + 'px',
        left: elm.left + 'px',
      }"
    >
      <div class="square"></div>
    </div>

    <div
      class="horiz"
      @mousedown="handlemousedown($event, 'down', 'height')"
      :style="{
        width: elm.width + 'px',
        top: elm.top + elm.height + 'px',
        left: elm.left + 'px',
      }"
    >
      <div class="square"></div>
    </div>

    <!-- corners -->
    <div
      class="verti cornerRight"
      @mousedown="handlemousedown($event, 'cornerBottom', 'width', 'height')"
      :style="{
        width: elm.width + 'px',
        top: elm.top + elm.height + 'px',
        left: elm.left + 'px',
      }"
    >
      <div class="square"></div>
    </div>
    <div
      class="verti cornerLeft"
      @mousedown="handlemousedown($event, 'cornerLeft', 'left', 'width', 'top', 'height')"
      :style="{
        width: elm.width + 'px',
        top: elm.top + 'px',
        left: elm.left + 'px',
      }"
    >
      <div class="square"></div>
    </div>
    <div
      class="verti cornerTop"
      @mousedown="handlemousedown($event, 'cornerTop', 'width', 'top', 'height')"
      :style="{
        width: elm.width + 'px',
        top: elm.top + 'px',
        left: elm.left + 'px',
      }"
    >
      <div class="square"></div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
export default {
  computed: {
    elm() {
      var target = this.$store.state.activeElement;

      if (!target.resizable || target.belong !== "page") return "";

      return target;
    },
  },

  data() {
    return {
      type: "", // kierunek left | right | up | down
    };
  },

  methods: {
    deleteThis() {
      var self = this;
      swal
        .fire({
          title: "Are you sure?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            self.$store.commit("delete");
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },
    handlemousedown(e, type, originX, originY, originAdditional, originAdditional2) {
      e.stopPropagation();
      if (type === "delete") {
        this.deleteThis();
        return;
      }
      if (type === "clone") {
        this.$store.commit("copy");
        return;
      }
      if (type === "levelup") {
        this.$store.commit("levelup");
        return;
      }
      if (type === "leveldown") {
        this.$store.commit("leveldown");
        return;
      }
      //resize stuff
      this.type = type;
      this.$store.commit("initmove", {
        startX: e.pageX,
        startY: e.pageY,
        originX: this.elm[originX],
        originY: this.elm[originY],
        originAdditional: this.elm[originAdditional],
        originAdditional2: this.elm[originAdditional2],
      });

      document.addEventListener("mousemove", this.handlemousemove, true);
      document.addEventListener("mouseup", this.handlemouseup, true);
    },

    //resize stuff
    handlemousemove(e) {
      // resize font
      // if (this.elm.type === "admedi-txt") {
      //   this.elm.fontSize = parseInt(this.elm.height * 0.8);
      // }
      e.stopPropagation();
      e.preventDefault();
      this.$store.commit("resize", {
        x: e.pageX,
        y: e.pageY,
        type: this.type,
      });
    },

    //resize stuff
    handlemouseup() {
      document.removeEventListener("mousemove", this.handlemousemove, true);
      document.removeEventListener("mouseup", this.handlemouseup, true);
      this.$store.commit("stopmove");
    },
  },
};
</script>

<style scoped>
.verti,
.horiz {
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.verti {
  width: 0;
  cursor: ew-resize;
}
.horiz {
  height: 0;
  cursor: ns-resize;
}
.square {
  width: 10px;
  height: 10px;
  border: 1px solid #2196f3;
  background-color: #fff;
  flex-shrink: 0;
}
.cloneThis,
.deleteThis {
  cursor: pointer;
  background: #fff;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 20px;
  position: absolute;
  z-index: 1000;
}
.cornerRight {
  justify-content: flex-end;
  height: 0;
}
.cornerRight .square {
  width: 20px;
  height: 20px;

  position: relative;
  right: -10px;
  top: -2px;
  cursor: se-resize;
}

.cornerLeft {
  height: 0;
  justify-content: flex-start;
}
.cornerLeft .square {
  width: 20px;
  height: 20px;

  position: relative;
  right: 10px;
  top: -3px;
  cursor: se-resize;
}

.cornerTop {
  height: 0;
  justify-content: flex-end;
}
.cornerTop .square {
  width: 20px;
  height: 20px;

  position: relative;
  right: -10px;
  top: -3px;
  cursor: sw-resize;
}
/* .square.first {
/* .square.first {
    position: relative;
    left: -4px;}
.square.second {
    position: relative;
    left: -20px;} */
</style>
