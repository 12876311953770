<template>
  <b-modal :active.sync="showModal" :width="640" scroll="keep">
    <button
      type="button"
      class="modal-close is-large modal-content__close"
      @click="showModal = false"
    ></button>
    <div class="card tac">
      <div class="card-content">
        <div class="content">
          <h1 class="title is-size-4">ADD NEW PLAYER</h1>
          <p class="has-text-weight-normal">
            Enter ID from AdmediPlayer APP installed on android tvbox device. You can download app

            <a href="https://eposter.cloud/" target="_blank">here</a>
          </p>
          <b-field class="deviceID-input">
            <b-input
              v-model="newDeviceId"
              placeholder="device ID"
              class="tac deviceID-input--capitalize"
            ></b-input>
          </b-field>
          <div v-if="newDeviceInvalid" class="modal-content__error">Invalid code</div>

          <div class="modal-content__buttons">
            <button class="button is-primary is-medium" @click="addDevice">add player</button>
            <button class="button is-outlined is-medium" @click="showPlayers()">
              show available players
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import Vue from "vue";

export default {
  name: "addDeviceModal",
  props: ["commercialId", "admediAdsOptions", "userId"],
  data() {
    return {
      showModal: false,
      newDeviceInvalid: false,
      newDeviceId: null,
    };
  },
  mounted() {},
  methods: {
    show() {
      this.newDeviceId = null;
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = true;
      });
    },
    hide() {
      var self = this;
      Vue.nextTick(function () {
        // needed to double click to show, without next tick
        self.showModal = false;
      });
    },
    showPlayers() {
      this.$emit("showAvailablePlayers");
    },
    addDevice() {
      var self = this;
      if (self.newDeviceId === "") {
        self.$toastr.e("Fill ID");
        self.newDeviceInvalid = true;
        return;
      }
      self.newDeviceType = "android";
      let cc = ` os: "${self.newDeviceType}"`;
      cc = cc.replace('"android"', "ANDROID");
      cc = cc.replace('"windows"', "WINDOWS");
      const query = {
        mutation: `  {
        device( display_name: "New device", ${cc}, status: 0, rotation: 0, weekday_live_to: "18:00:00", weekday_live_from: "6:00:00", sunday_live_to: "18:00:00", sunday_live_from: "6:00:00", saturday_live_to: "18:00:00", saturday_live_from: "6:00:00", user_id: ${self.userId}, device_id: "${self.newDeviceId}", commercial_id: "${self.commercialId}" ){
          id
          device_id
          display_name
          weekday_live_from
          weekday_live_to
          saturday_live_from
          saturday_live_to
          sunday_live_from
          sunday_live_to
          is_standby
          rotation
          commercial{
            id
            display_name
          }
        }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.data.device) {
            self.newDeviceInvalid = false;
            let newDevice = response.data.data.device;

            newDevice.display_name = ""; // walkaround for database

            newDevice.is_new = true; // walkaround for database

            // var pushArgs = {};
            // pushArgs.id = response.data.data.device.device_id;
            // for (let admediAsd in self.admediAdsOptions) {
            //   if (self.admediAdsOptions[admediAsd].id === self.commercialId) {
            //     pushArgs.url = self.admediAdsOptions[admediAsd].url;
            //     break;
            //   }
            // }
            self.$emit("closeAddDevice", newDevice);
            self.showModal = false;
            //
          } else {
            self.newDeviceInvalid = true;
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
  },
};
</script>