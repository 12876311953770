<template>
  <div class="mediaLibrary">
    <div>
      <button class="mediaButton pull-right closeButton" @click="closeFigureModal()">X</button>
      <div class="imgsContainerF">
        <div class="imgContainerF" v-for="figure in figures">
          <div v-html="figure.content" class="figureClass"></div>
          <div
            class="fa fa-plus addThisImage"
            @click="
              (e) => {
                chooseFigure(figure);
              }
            "
          ></div>
          <div>
            <!-- <i class="fa fa-times" @click="deleteImage(file.id)" v-if="file.is_added_by_admin === is_admin || !file.is_added_by_admin"></i> -->
          </div>
        </div>
        <div
          class="imgContainerF"
          v-for="figure in userContentJson.widgets"
          v-if="userContentJson.widgets"
        >
          <div class="figureClass">
            <img :src="figure.blob" />
          </div>
          <div
            class="fa fa-plus addThisImage"
            @click="
              (e) => {
                chooseContainer(figure);
              }
            "
          ></div>
          <i class="fa fa-times" @click="deleteFigure(figure)"></i>

          <div>
            <!-- <i class="fa fa-times" @click="deleteImage(file.id)" v-if="file.is_added_by_admin === is_admin || !file.is_added_by_admin"></i> -->
          </div>
        </div>
        <!-- <div class="imgContainerF">
          container
          <div
            class="fa fa-plus addThisImage"
            @click="
              (e) => {
                addNewContainer();
              }
            "
          ></div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import widget from "./../plugins/widget";
export default {
  name: "admedi-uploader-figures",
  data() {
    return {
      hidePanel: false,
      history: [],
      currentIndex: -1,
      disableHistory: false,
      backendUrl: global.pageUrl,
      currentItem: {},
      commercial: {},
      isComponentModalActive: false,
      files: [],
      fileName: "",
      filesPagination: {
        total: 0,
        limit: 30,
        page: 1,
      },
      currentSliderIsTemplate: false,
      dropFiles: [],
      changeSlideDuration: false,
      figures: [
        { content: '<div class="figure__square"></div>', name: "square" },
        { content: '<div class="figure__circle"></div>', name: "circle" },
        { content: '<div class="figure__line"></div>', name: "line" },
      ],
      userContentJson: {
        widgets: [],
      },
    };
  },
  computed: {
    is_admin() {
      return this.$store.state.userIsAdmin;
    },
    user_id() {
      return this.$store.state.userId;
    },
    widgets() {
      return widget.getWidgetsAdmedi();
    },
  },
  mounted() {
    this.startUploader();
    this.getUserWidegts();
    // this.openFilesModal();
  },
  methods: {
    checkFileType(filename) {
      var parts = filename.split(".");
      var ext = parts[parts.length - 1];
      switch (ext.toLowerCase()) {
        case "jpg":
        case "jpeg":
        case "gif":
        case "bmp":
        case "png":
          return "thisIsImg";
        case "m4v":
        case "avi":
        case "mpg":
        case "mp4":
          return "thisIsVid";
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    getUserWidegts() {
      var self = this;
      const query = {
        query: `  {
        logged_user{
            content_json
          }
      }
      `,
      };
      axios
        .post(global.backendUrl, query)
        .then(function (response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else {
            let content_json = response.data.data.logged_user.content_json;
            if (self.isJson(content_json)) {
              // content_json = decodeURIComponent(content_json);
              content_json = JSON.parse(content_json);
            } else {
              content_json = {};
              content_json.widgets = [];
            }
            self.userContentJson = content_json;
          }
        })
        .catch(function (error) {
          console.log(error);
          self.$toastr.e("Contact the administrator " + error.message);
        });
    },
    chooseFigure(figure) {
      let item = this.widgets["admedi-figure"];
      item.setting.theFigure = figure.name;
      this.$store.dispatch("addWidget", item);
      this.$emit("closeFigure");
    },
    chooseContainer(figure) {
      this.$store.commit("copyContainer", figure);
      this.$emit("closeFigure");
    },

    addNewContainer() {
      document.getElementById("add-container-href").click();
      this.$emit("closeFigure");
    },
    startUploader() {
      var self = this;
      self.filesPagination.page = 1;
      self.filesPagination.total = 0;
      self.isComponentModalActive = true;
    },
    closeFigureModal() {
      this.$emit("closeFigure");
    },
  },
};
</script>

<style lang="scss" scoped>
.logoImg {
  height: 20px;
  width: 20px;
}

.figureClass {
  width: 100%;
  height: 100%;
}
.imgsContainerF {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 20px;
  flex-wrap: wrap;
  overflow: scroll;
  align-items: flex-start;
  align-content: flex-start;
  & > div {
    flex: 0 0 120px;
    width: 120px;
    margin: 0 10px 20px 0;
    padding: 0;
    height: 120px;
  }
}
.imgContainerF {
  position: relative;
  border: 1px solid transparent;
  i.fa-times {
    display: none;

    cursor: pointer;
    padding: 3px;
    border-radius: 15px;
    background: #eeeeee;
    border: 1px solid #ccc;
    margin-left: 5px;
    position: absolute;
    right: -3px;
    top: -10px;
  }
  div.addThisImage {
    display: none;
    width: 20px;
    height: 23px;
    position: absolute;
    right: -23px;
    top: -10px;
    z-index: 1011;
    i {
      cursor: pointer;
      padding: 3px;
      border-radius: 15px;
      background: #eeeeee;
      border: 1px solid #ccc;
      margin-left: 5px;
    }
  }
  div.addThisImage {
    z-index: 1010;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    font-size: 20px;
    background: #ccc;
    color: #fff;
    right: 19px;
    top: 45px;

    bottom: 19px;
    display: none;
    left: 45px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;
  }
  &:hover {
    i.fa-times {
      display: block;
    }
    border: 1px solid #11ff11;
    div {
      display: flex;
    }
  }
}
.littleThumb {
  width: 20px;
  height: 20px;
  display: block;
}
.slidersCol {
  padding-top: 25px;
  background: #eeeeee;
  width: 300px;
  padding: 5px;
}

.saveSlide {
  color: #fff;
  margin: 0 15px;
}
.closeSlide:visited,
.closeSlide {
  color: #fff;
  margin: 0 15px;
}
#playground {
  position: absolute;
  left: -10000px;
}
.commercialAddress {
  color: #fff;
}
.commercialAddress:visited {
  color: #fff;
}

.drophere {
  flex: 1;
}

.disabled {
  opacity: 0.5;
}
.isThistempl {
  margin-top: 10px;
  color: #fff;
  line-height: 150%;
  display: flex;
  label {
    margin-left: 10px;
  }
}
.closeButton {
  align-self: flex-end;
  margin: 10px;
}
</style>
