<template>
  <div
    class="lz-image lz-figure"
    :class="[playState ? 'anm-' + val.animationName : '', val.widgetAnimationClass]"
    :data-animation="val.widgetAnimationClass"
    :style="{
      position: val.belong === 'page' ? 'absolute' : 'relative',
      width: (val.width / w) * 100 + '%',
      height: (val.height / h) * 100 + '%',
      left: val.belong === 'page' ? (val.left / w) * 100 + '%' : '0',
      top: val.belong === 'page' ? (val.top / h) * 100 + '%' : '0',
      zIndex: val.z,
      opacity: val.opacity,
      fill: val.bgColor,
      stroke: val.bgColor,
      borderRadius: val.radius + 'px',
      animationDelay: val.widgetAnimationDelay + 's',
      animationDuration: val.widgetAnimationDuration + 's',
      animationIterationCount: val.widgetAnimationIterationCount ? 'infinite' : 'initial',
      transform: 'rotate(' + val.rotate + 'deg)',
    }"
    v-on:click="clickFigure"
  >
    <div
      :class="'figure__' + val.theFigure"
      :style="{
        borderStyle: val.borderType,
        borderColor: val.borderColor,
        borderWidth: val.borderWidth + 'px',
        boxShadow: val.enableShadow
          ? val.widgetShadowX +
            'px ' +
            val.widgetShadowY +
            'px ' +
            val.widgetShadowBlur +
            'px ' +
            'rgba(' +
            hexToRgb(val.widgetShadowColor).r +
            ',' +
            hexToRgb(val.widgetShadowColor).g +
            ',' +
            hexToRgb(val.widgetShadowColor).b +
            ',' +
            val.widgetShadowOpacity +
            ')'
          : 'none',
        background: val.enableGradient
          ? 'linear-gradient(rgba(' +
            hexToRgb(val.widgetGradientColor).r +
            ',' +
            hexToRgb(val.widgetGradientColor).g +
            ',' +
            hexToRgb(val.widgetGradientColor).b +
            ',' +
            val.widgetGradientOpacity +
            '), rgba(' +
            hexToRgb(val.widgetGradientColor2).r +
            ',' +
            hexToRgb(val.widgetGradientColor2).g +
            ',' +
            hexToRgb(val.widgetGradientColor2).b +
            ',' +
            val.widgetGradientOpacity2 +
            ') )'
          : val.bgColor,
      }"
    ></div>
  </div>
</template>

<script>
//
import admediFigureStyle from "./style.vue";
const WIDGET_NAME = "admedi-figure";
import { widgetsMethods } from "@/mixins";

export default {
  name: WIDGET_NAME,
  mixins: [widgetsMethods],
  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>',
  title: "SHAPE",
  panel: admediFigureStyle,
  setting: {
    type: WIDGET_NAME,
    isContainer: false,
    isUpload: false,
    isFigure: true,
    theFigure: "",
    hasGuide: true,
    isChild: true,
    dragable: true,
    color: "#000000",
    resizable: true,
    left: 0,
    z: 0,
    href: "",
    belong: "page",
    widgetAnimationClass: "",
    bgColor: "#ffffff",
    radius: 0,
    animationName: "",
    widgetAnimationDelay: "0",
    widgetAnimationDuration: "1",
    widgetAnimationIterationCount: false,
    widgetClass: "",
    opacity: 1,
    additionalHTML: "",
    additionalCSS: "",
    additionalJS: "",
    rotate: 0,
    enableShadow: false,
    widgetShadowColor: "#cccccc",
    widgetShadowOpacity: 1,
    widgetShadowBlur: 5,
    widgetShadowX: 5,
    widgetShadowY: 5,
    borderType: "solid",
    enableGradient: false,
    widgetGradientColor: "#cccccc",
    widgetGradientOpacity: 1,
    widgetGradientColor2: "#000000",
    widgetGradientOpacity2: 1,
  },
  props: ["val", "w", "h", "playState"],
  methods: {
    clickFigure(event) {
      this.$store.commit("select", {
        uuid: this.val.uuid || -1,
      });
    },
  },
};
</script>

<style scoped>
.lz-figure svg {
  box-shadow: inherit;
}
</style>
