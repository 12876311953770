<template>
  <section class="hero">
    <vue-toastr ref="toastr"></vue-toastr>
    <div class="hero-body" v-if="$store.state.userIsAdmin">
      <div class="container has-text-centered mw400">
        <p class="headerParagraph">Register</p>
        <div class="field">
          <p class="control has-icons-left">
            <input class="input" placeholder="Username" v-model="credentials.display_name" />
            <span class="icon is-small is-left">
              <i class="fa fa-user"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input class="input" type="email" placeholder="Email" v-model="credentials.email" />
            <span class="icon is-small is-left">
              <i class="fa fa-envelope"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input
              class="input"
              type="password"
              placeholder="Password"
              v-model="credentials.password"
            />
            <span class="icon is-small is-left">
              <i class="fa fa-lock"></i>
            </span>
          </p>
        </div>

        <div class="field">
          <p class="control">
            <button class="button is-success" @click="submit()">Register</button>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import Toastr from "vue-toastr";

export default {
  beforeCreate() {
    if (!this.$store.state.userIsAdmin) {
      this.$router.push("/404");
    }
  },
  components: {
    "vue-toastr": Toastr,
  },
  data() {
    return {
      backendUrl: window.backendUrl,
      backendLoginUrl: window.backendLoginUrl,
      credentials: {
        display_name: "",
        email: "",
        password: "",
      },
      error: "",
    };
  },
  methods: {
    submit: function () {
      var self = this;
      if (self.credentials.display_name === "") {
        self.$toastr.e("Fill name.");
        return;
      }
      if (self.credentials.email === "") {
        self.$toastr.e("Fill email.");
        return;
      }
      if (self.credentials.password === "") {
        self.$toastr.e("Fill password.");
        return;
      }
      const query = {
        query: `
            mutation {user_register (${self.$utils.myobj2arg(self.credentials)}){id}}
  `,
      };
      axios
        .post(global.backendLoginUrl, query)
        .then(function (response) {
          if (response.data.errors) {
            for (var property in response.data.errors) {
              self.$toastr.e(response.data.errors[property].message);
            }
          } else {
            self.$toastr.s("Email with confirmation linke sent to user.");
            self.credentials = {
              display_name: "",
              email: "",
              password: "",
            };
          }
        })
        .catch(function (error) {
          self.$toastr.e("Contact the administrator " + error);
        });
    },
  },
};
</script>
