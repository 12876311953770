var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"txt",class:[_vm.playState ? 'anm-' + _vm.val.animationName : '', _vm.val.widgetAnimationClass],style:({
    position: _vm.val.belong === 'page' ? 'absolute' : 'relative',
    left: _vm.val.belong === 'page' ? (_vm.val.left / _vm.w) * 100 + '%' : '0',
    top: _vm.val.belong === 'page' ? (_vm.val.top / _vm.h) * 100 + '%' : '0',
    width: (_vm.val.width / _vm.w) * 100 + '%',
    height: (_vm.val.height / _vm.h) * 100 + '%',
    zIndex: _vm.val.z,
    lineHeight: _vm.val.lineHeight,
    fontSize: _vm.val.fontSize + 'px',
    color: _vm.val.color,
    textAlign: _vm.val.textAlign,
    fontWeight: _vm.val.fontWeight ? 'bold' : 'normal',
    fontFamily: _vm.val.fontFamily,
    opacity: _vm.val.opacity,
    backgroundColor: _vm.val.bgColor,
    borderRadius: _vm.val.radius + 'px',
    animationDelay: _vm.val.widgetAnimationDelay + 's',
    animationDuration: _vm.val.widgetAnimationDuration + 's',
    animationIterationCount: _vm.val.widgetAnimationIterationCount ? 'infinite' : 'initial',
    transform: 'rotate(' + _vm.val.rotate + 'deg)',
    overflow: 'hidden',
    letterSpacing: _vm.val.letterSpacing + 'px',
    textTransform: _vm.val.textTransformUppercase ? 'uppercase' : 'none',
    fontStyle: _vm.val.fontStyleItalic ? 'italic' : 'normal',
    textDecoration: _vm.val.textDecorationUnderline ? 'underline' : 'initial',
    textShadow: _vm.val.enableShadow
      ? _vm.val.widgetShadowX +
        'px ' +
        _vm.val.widgetShadowY +
        'px ' +
        _vm.val.widgetShadowBlur +
        'px ' +
        'rgba(' +
        _vm.hexToRgb(_vm.val.widgetShadowColor).r +
        ',' +
        _vm.hexToRgb(_vm.val.widgetShadowColor).g +
        ',' +
        _vm.hexToRgb(_vm.val.widgetShadowColor).b +
        ',' +
        _vm.val.widgetShadowOpacity +
        ')'
      : 'none',
  }),attrs:{"id":'text-' + _vm.val.uuid,"data-animation":_vm.val.widgetAnimationClass,"contenteditable":_vm.editableTxt},domProps:{"innerHTML":_vm._s(_vm.val.text)},on:{"blur":function (e) { return _vm.updateText(e, _vm.val.uuid); },"input":function (e) { return _vm.updateHeight(e); }}})}
var staticRenderFns = []

export { render, staticRenderFns }