<template>
  <div class="background-widget">
    <div class="panel-row" flex>
      <div @click="openFilesModal()" class="panel__button">background</div>
    </div>

    <!-- <div class="panel-row" flex>
      <div class="panel-label">Długość animacji [ms]</div>
      <div class="panel-value">
        <input type="number" v-model="activeElement.widgetAnimationDuration" />
      </div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-label">Ilość animacji</div>
      <div class="panel-value">
        <label class="form-radio tooltip" data-tooltip="raz">
          <input
            type="radio"
            name="IterationCount"
            value="initial"
            v-model="activeElement.widgetAnimationIterationCount"
          />
          <i class="form-icon"></i>raz
        </label>
        <label class="form-radio tooltip" data-tooltip="nieskończona">
          <input
            type="radio"
            name="IterationCount"
            value="infinite"
            v-model="activeElement.widgetAnimationIterationCount"
          />
          <i class="form-icon"></i> nieskończona
        </label>
      </div>
    </div>
    <div class="panel-row" flex v-if="activeElement.widgetAnimationIterationCount === 'initial'">
      <div class="panel-label">Opóźnienie animacji [ms]</div>
      <div class="panel-value">
        <input type="number" v-model="activeElement.widgetAnimationDelay" />
      </div>
    </div>-->
    <transition name="slide-fade">
      <div v-if="activeElement.url">
        <div class="panel-row" flex>
          <div class="panel-value panel__select__container">
            animation
            <select
              v-model="activeElement.widgetAnimationClass"
              class="panel__select"
              @change="changeAnimation"
            >
              <option value>none</option>

              <option value="zoomBG">zoom</option>
            </select>
          </div>
        </div>
        <transition name="slide-fade">
          <div v-if="activeElement.widgetAnimationClass">
            <div class="panel-row" flex>
              <div class="field">
                <b-checkbox v-model="activeElement.widgetAnimationIterationCount">loop</b-checkbox>
              </div>
            </div>
            <div class="panel-row" flex v-if="!activeElement.widgetAnimationIterationCount">
              <div class="panel-label panel-label--input">
                <span>delay</span>
                <div>
                  <input class="panel-input" v-model="activeElement.widgetAnimationDelay" /> s
                </div>
              </div>

              <div class="panel-slider-wrap">
                <slider
                  :step="0.1"
                  :max="10"
                  :min="0"
                  v-model="activeElement.widgetAnimationDelay"
                />
              </div>
            </div>
            <div class="panel-row" flex v-if="!activeElement.widgetAnimationIterationCount">
              <div class="panel-label panel-label--input">
                <span>duration</span>
                <div>
                  <input class="panel-input" v-model="activeElement.widgetAnimationDuration" /> s
                </div>
              </div>

              <div class="panel-slider-wrap">
                <slider
                  :step="0.1"
                  :max="10"
                  :min="0"
                  v-model="activeElement.widgetAnimationDuration"
                />
              </div>
            </div>
          </div>
        </transition>
        <div class="panel-row" flex>
          <div class="panel-value">
            <div class="panel__background-size">
              <b-field>
                <b-radio-button v-model="activeElement.widgetBgSize" native-value="contain"
                  >contain</b-radio-button
                >

                <b-radio-button v-model="activeElement.widgetBgSize" native-value="cover"
                  >cover</b-radio-button
                >

                <b-radio-button v-model="activeElement.widgetBgSize" native-value="auto"
                  >repeat</b-radio-button
                >
              </b-field>
            </div>
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label">position x</div>
          <div class="panel-value">{{ Math.round(activeElement.widgetBgPositionX) }} %</div>
          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetBgPositionX" />
          </div>
        </div>
        <div class="panel-row" flex>
          <div class="panel-label">position y</div>
          <div class="panel-value">{{ Math.round(activeElement.widgetBgPositionY) }} %</div>
          <div class="panel-slider-wrap">
            <slider :step="1" :max="100" :min="0" v-model="activeElement.widgetBgPositionY" />
          </div>
        </div>

        <div class="panel-row" flex>
          <div class="panel-label">opacity</div>
          <div class="panel-value">{{ Math.round(activeElement.opacity * 100) }} %</div>
          <div class="panel-slider-wrap">
            <slider :step="0.01" :max="1" :min="0" v-model="activeElement.opacity" />
          </div>
        </div>
      </div>
    </transition>
    <!-- <div class="panel-row" flex>
      <div class="panel__background-position">
        <b-field>
          <b-radio-button v-model="activeElement.widgetBgPosition" native-value=" top left">
            <b-icon pack=" fa fa-caret-up" class="transform-rotate-45-r" />
          </b-radio-button>
          <b-radio-button v-model="activeElement.widgetBgPosition" native-value="top center">
            <b-icon pack=" fa fa-caret-up" />
          </b-radio-button>
          <b-radio-button v-model="activeElement.widgetBgPosition" native-value="top right">
            <b-icon pack=" fa fa-caret-up" class="transform-rotate-45" />
          </b-radio-button>
          <b-radio-button v-model="activeElement.widgetBgPosition" native-value=" center left">
            <b-icon pack=" fa fa-caret-left" />
          </b-radio-button>
          <b-radio-button v-model="activeElement.widgetBgPosition" native-value="center center">
            <b-icon pack=" fa fa-circle" />
          </b-radio-button>
          <b-radio-button v-model="activeElement.widgetBgPosition" native-value="center right">
            <b-icon pack=" fa fa-caret-right" />
          </b-radio-button>
          <b-radio-button v-model="activeElement.widgetBgPosition" native-value=" bottom left">
            <b-icon pack=" fa fa-caret-down" class="transform-rotate-45" />
          </b-radio-button>
          <b-radio-button v-model="activeElement.widgetBgPosition" native-value="bottom center">
            <b-icon pack=" fa fa-caret-down" />
          </b-radio-button>
          <b-radio-button v-model="activeElement.widgetBgPosition" native-value="bottom right">
            <b-icon pack=" fa fa-caret-down" class="transform-rotate-45-r" />
          </b-radio-button>
        </b-field>
      </div>
    </div>-->
    <!-- <div class="panel-row" flex>
      <div class="panel-label">Wypełnij tło video</div>
      <div class="panel-value">
        <select v-model="activeElement.objectFit">
          <option value>No</option>
          <option v-for="(val, index) in objectFits" :key="index" :value="val.value">{{ val.name }}</option>
        </select>
      </div>
    </div>
    <div class="panel-row" flex>
      <div class="panel-label">Pozycja tło video</div>
      <div class="panel-value">
        <select v-model="activeElement.objectPosition">
          <option value>No</option>
          <option
            v-for="(val, index) in objectPositions"
            :key="index"
            :value="val.value"
          >{{ val.name }}</option>
        </select>
      </div>
    </div>-->
    <div class="panel-row" flex title="background color">
      <div class="panel-value">
        <div class="panel__color">
          <input type="color" v-model="activeElement.backgroundColor" />
        </div>
        <input class="panel-input" v-model="activeElement.backgroundColor" />
      </div>
    </div>

    <div class="panel-row" flex>
      <div class="panel-label panel__menu__buttons">
        <div class="panel__menu__button" @click="deleteBackground()">
          <b-icon pack=" fa fa-trash" />
        </div>
      </div>
    </div>
    <div @click="saveAsTemplate()" class="save-as-template">save as template</div>
  </div>
</template>

<script>
import swal from "sweetalert2";
export default {
  name: "admedi-bg-style",
  props: ["activeElement"],
  data() {
    return {
      showWidgets: false,
      hidePanel: false,
      history: [],
      currentIndex: -1,
      disableHistory: false,
      imageCategories: [],
      currentImageCategory: "",
      backendUrl: global.pageUrl,
      currentItem: {},
      commercial: {},
      files: [],
      fileName: "",
      filesPagination: {
        total: 0,
        limit: 30,
        page: 1,
      },
      currentSliderIsTemplate: false,
      dropFiles: [],
      changeSlideDuration: false,
      isFigureUploaderActive: false,
    };
  },
  computed: {
    animationNames() {
      var arr = [];
      this.$store.state.animation.map((val) => arr.push(val.name));

      return arr;
    },
    openUploader() {
      return this.$store.state.openUploader;
    },
  },
  methods: {
    saveAsTemplate() {
      document.getElementById("saveAsTemplate").click();
    },
    changeAnimation() {
      if (this.activeElement.widgetAnimationClass.indexOf("fadeIn") !== -1) {
        this.activeElement.opacity = 0;
      } else {
        this.activeElement.opacity = 1;
      }
    },
    openFilesModal() {
      var self = this;
      self.showWidgets = false;

      self.$store.commit("setUploaderType", ["Enum::IMAGE", "Enum::VIDEO"]);
      self.$store.commit("doUploader", true);
    },

    deleteBackground() {
      var self = this;
      swal
        .fire({
          title: "Napewno usunąć ten element ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCloseButton: true,
          buttonsStyling: false,
          customClass: {
            confirmButton: "button is-primary",
            cancelButton: "button is-outlined m-l-2",
          },
        })
        .then((result) => {
          if (result.value) {
            this.$delete(this.activeElement, "url");
            // activeElement.url
            self.$store.commit("delete");
          } else {
            self.$toastr.s("Cancelled");
          }
        });
    },
  },
};
</script>
