var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lz-container",class:[_vm.playState ? 'anm-' + _vm.val.animationName : '', _vm.val.widgetAnimationClass],style:({
    display: _vm.val.display,
    position: 'absolute',
    width: _vm.val.width / _vm.w * 100 + '%',
    height: _vm.val.height / _vm.h * 100 + '%',
    left: _vm.val.left / _vm.w * 100 + '%',
    top: _vm.val.top / _vm.h * 100 + '%',
    zIndex: _vm.val.z,
    backgroundColor: _vm.val.bgColor,
    backgroundImage: 'url(' + _vm.val.backPic + ')',
    borderStyle: _vm.val.borderType,
    borderRadius: _vm.val.radius + 'px',
    borderColor: _vm.val.borderColor,
    borderWidth: _vm.val.borderWidth + 'px',
    flexDirection: _vm.val.dir,
    justifyContent: _vm.val.justify,
    alignItems: _vm.val.align,
    opacity: _vm.val.opacity,
    animationDelay: _vm.val.widgetAnimationDelay + 's',
    animationDuration: _vm.val.widgetAnimationDuration + 's',
    animationIterationCount: _vm.val.widgetAnimationIterationCount ? 'infinite' : 'initial',
    transform: 'rotate('+ _vm.val.rotate +'deg)',
    boxShadow: _vm.val.enableShadow ? (_vm.val.widgetShadowX + 'px ' + _vm.val.widgetShadowY+ 'px ' + _vm.val.widgetShadowBlur + 'px ' + 'rgba('+_vm.hexToRgb(_vm.val.widgetShadowColor).r+','+_vm.hexToRgb(_vm.val.widgetShadowColor).g+','+_vm.hexToRgb(_vm.val.widgetShadowColor).b + ','+(_vm.val.widgetShadowOpacity)+')') : 'none',
    background: _vm.val.enableGradient ? ('linear-gradient(rgba('+_vm.hexToRgb(_vm.val.widgetGradientColor).r+','+_vm.hexToRgb(_vm.val.widgetGradientColor).g+','+_vm.hexToRgb(_vm.val.widgetGradientColor).b + ','+(_vm.val.widgetGradientOpacity)+'), rgba('+_vm.hexToRgb(_vm.val.widgetGradientColor2).r+','+_vm.hexToRgb(_vm.val.widgetGradientColor2).g+','+_vm.hexToRgb(_vm.val.widgetGradientColor2).b + ','+(_vm.val.widgetGradientOpacity2)+') )') : '',
  }),attrs:{"id":'container' + _vm.val.uuid,"data-animation":_vm.val.widgetAnimationClass}},[_c('div',{staticClass:"additionalHTML",domProps:{"innerHTML":_vm._s(_vm.val.additionalHTML)}}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }