export default {
    userId: '',
    userEmail: '',
    userRole: 0,
    userIsAdmin: false,
    axiosLoading: false,
    errorsCatch: null, // zmienna do wyświetlania błędów AXIOS, watch wyowłuje toastr
    successCatch: null, // zmienna do wyświetlania błędów AXIOS, watch wyowłuje toastr
    // admedi end

    animation: [
        {
            name: 'bounce'
        }, {
            name: 'flash'
        }, {
            name: 'pulse'
        }, {
            name: 'rubberBand'
        }, {
            name: 'shake'
        }, {
            name: 'headShake'
        }, {
            name: 'swing'
        }, {
            name: 'tada'
        }, {
            name: 'wobble'
        }, {
            name: 'jello'
        }, {
            name: 'bounceIn'
        }, {
            name: 'bounceInDown'
        }, {
            name: 'bounceInLeft'
        }, {
            name: 'bounceInRight'
        }, {
            name: 'bounceInUp'
        }, {
            name: 'bounceOut'
        }, {
            name: 'bounceOutDown'
        }, {
            name: 'bounceOutLeft'
        }, {
            name: 'bounceOutRight'
        }, {
            name: 'bounceOutUp'
        }, {
            name: 'fadeIn'
        }, {
            name: 'fadeInDown'
        }, {
            name: 'fadeInDownBig'
        }, {
            name: 'fadeInLeft'
        }, {
            name: 'fadeInLeftBig'
        }, {
            name: 'fadeInRight'
        }, {
            name: 'fadeInRightBig'
        }, {
            name: 'fadeInUp'
        }, {
            name: 'fadeInUpBig'
        }, {
            name: 'fadeOut'
        }, {
            name: 'fadeOutDown'
        }, {
            name: 'fadeOutDownBig'
        }, {
            name: 'fadeOutLeft'
        }, {
            name: 'fadeOutLeftBig'
        }, {
            name: 'fadeOutRight'
        }, {
            name: 'fadeOutRightBig'
        }, {
            name: 'fadeOutUp'
        }, {
            name: 'fadeOutUpBig'
        }, {
            name: 'flipInX'
        }, {
            name: 'flipInY'
        }, {
            name: 'flipOutX'
        }, {
            name: 'flipOutY'
        }, {
            name: 'lightSpeedIn'
        }, {
            name: 'lightSpeedOut'
        }, {
            name: 'rotateIn'
        }, {
            name: 'rotateInDownLeft'
        }, {
            name: 'rotateInDownRight'
        }, {
            name: 'rotateInUpLeft'
        }, {
            name: 'rotateInUpRight'
        }, {
            name: 'rotateOut'
        }, {
            name: 'rotateOutDownLeft'
        }, {
            name: 'rotateOutDownRight'
        }, {
            name: 'rotateOutUpLeft'
        }, {
            name: 'rotateOutUpRight'
        }, {
            name: 'hinge'
        }, {
            name: 'jackInTheBox'
        }, {
            name: 'rollIn'
        }, {
            name: 'rollOut'
        }, {
            name: 'zoomIn'
        }, {
            name: 'zoomInDown'
        }, {
            name: 'zoomInLeft'
        }, {
            name: 'zoomInRight'
        }, {
            name: 'zoomInUp'
        }, {
            name: 'zoomOut'
        }, {
            name: 'zoomOutDown'
        }, {
            name: 'zoomOutLeft'
        }, {
            name: 'zoomOutRight'
        }, {
            name: 'zoomOutUp'
        }, {
            name: 'slideInDown'
        }, {
            name: 'slideInLeft'
        }, {
            name: 'slideInRight'
        }, {
            name: 'slideInUp'
        }, {
            name: 'slideOutDown'
        }, {
            name: 'slideOutLeft'
        }, {
            name: 'slideOutRight'
        }, {
            name: 'slideOutUp'
        }, {
            name: 'heartBeat'
        }
    ],
    playState: false,

    activeElement: {},
    page: {
        page: true,
        title: 'Slide',
        height: 1920,
        width: 1080,
        endTime: new Date(),
        backgroundColor: '#fff'
    },
    defaultPage: {
        page: true,
        title: 'Slide',
        height: 1920,
        width: 1080,
        endTime: new Date(),
        backgroundColor: '#fff'
    },

    top: 0,
    zoom: 60,
    type: 'page',
    index: -1,
    uuid: null,
    counter: 0,

    originX: 0,
    originY: 0,
    originAdditional: 0,
    originAdditional2: 0,
    startX: 0,
    startY: 0,
    moving: false,

    widgets: [],

    currentSlider: null,
    activeAdsSlidesId: null,
    activeAdsSlidesRange: {},
    currentImageCategory: null,
    currentUploaderType: ["Enum::IMAGE", "Enum::VIDEO"], //IMAGE VIDEO FONT

    openUploader: false,
    uploadItem: null,

    pageWidth: '1920',
    pageHeight: '1080',
    ctrlCopy: null
}