<template>
  <div
    class="lz-image"
    :id="'pic-' + val.uuid"
    :class="[playState ? 'anm-' + val.animationName : '', val.widgetAnimationClass]"
    :data-animation="val.widgetAnimationClass"
    :style="{
      position: val.belong === 'page' ? 'absolute' : 'relative',
      width: (val.width / w) * 100 + '%',
      height: (val.height / h) * 100 + '%',
      left: val.belong === 'page' ? (val.left / w) * 100 + '%' : '0',
      top: val.belong === 'page' ? (val.top / h) * 100 + '%' : '0',
      zIndex: val.z,
      opacity: val.opacity,
      backgroundColor: val.bgColor,
      borderRadius: val.radius + 'px',
      animationDelay: val.widgetAnimationDelay + 's',
      animationDuration: val.widgetAnimationDuration + 's',
      animationIterationCount: val.widgetAnimationIterationCount ? 'infinite' : 'initial',
      transform: 'rotate(' + val.rotate + 'deg) ' + (val.widgetScale ? ' scale(-1, 1)' : ''),
      boxShadow: val.enableShadow
        ? val.widgetShadowX +
          'px ' +
          val.widgetShadowY +
          'px ' +
          val.widgetShadowBlur +
          'px ' +
          'rgba(' +
          hexToRgb(val.widgetShadowColor).r +
          ',' +
          hexToRgb(val.widgetShadowColor).g +
          ',' +
          hexToRgb(val.widgetShadowColor).b +
          ',' +
          val.widgetShadowOpacity +
          ')'
        : 'none',
    }"
  >
    <video
      :key="val.url"
      v-if="val.mediaType === 'thisIsVid'"
      v-on:click="clickVideo"
      :style="{ backgroundImage: 'url(' + videoImage + ')', backgroundSize: 'cover' }"
    >
      <source :src="returnUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <img v-if="val.mediaType === 'thisIsImg'" :src="returnUrl" :key="val.url" />
  </div>
</template>

<script>
import admediPicStyle from "./style.vue";

import { widgetsMethods } from "@/mixins";
const WIDGET_NAME = "admedi-pic";

export default {
  name: WIDGET_NAME,
  mixins: [widgetsMethods],
  icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>',
  title: "MEDIA",
  panel: admediPicStyle,
  setting: {
    type: WIDGET_NAME,
    isContainer: false,
    isUpload: true,
    mediaType: "thisIsImg",
    hasGuide: true,
    isChild: true,
    dragable: true,
    resizable: true,
    left: 0,
    z: 0,
    href: "",
    belong: "page",
    animationName: "",
    widgetAnimationClass: "",
    widgetAnimationDelay: "0",
    widgetAnimationDuration: "1",
    widgetAnimationIterationCount: false,
    widgetScale: false,
    opacity: 1,
    bgColor: "transparent",
    radius: 0,
    rotate: 0,
    url: null,
    mediaId: null,
    enableShadow: false,
    widgetShadowColor: "#cccccc",
    widgetShadowOpacity: 1,
    widgetShadowBlur: 5,
    widgetShadowX: 5,
    widgetShadowY: 5,
  },
  props: ["val", "w", "h", "playState"],
  computed: {
    videoImage() {
      let imageUrl = "";
      if (this.val && this.val.url) {
        let path = this.val.url.substring(0, this.val.url.lastIndexOf("/")) + "/videos";
        let filename = this.val.url.substring(
          this.val.url.lastIndexOf("/"),
          this.val.url.length - 3
        );
        imageUrl = path + filename + "jpg";
      }
      return imageUrl;
    },
    returnUrl: function () {
      return process.env.NODE_ENV === "development" ? "http://admedi" + this.val.url : this.val.url;
    },
  },
  methods: {
    clickVideo(event) {
      this.$store.commit("select", {
        uuid: this.val.uuid || -1,
      });
    },
  },
};
</script>

<style scoped>
.lz-image img {
  transform: rotate(0deg);
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: none;
  z-index: 100;
}
</style>
